import React, { useEffect, useState, useContext } from "react";

import Resizer from "react-image-file-resizer";
import { useNavigate, useParams } from 'react-router-dom';
import { useFlashMessage } from '../context/FlashMessageContext';
import ProductEditForm from '../forms/ProductEditForm';
import { get, post } from "../services/apiService";
import { ToastContainer, toast } from 'react-toastify';

import {
  uploadFileMultipleInput
} from './Common';
import { update } from 'final-form-arrays';

import { AppContext } from '../context/context';
import { FlashMessage } from "./FlashMessage";

const ProductEditModal = props => {
  const {
    loadingCallback,
    setProduct,
    setWarningMessage,
    setShowModal,
    formId,
    getTopSaleProductlist,
    showNestedModal,
    setShowNestedModal,
    saleType
  } = props;
    const {
        state,
        isLoading,
        updateCategoryList,
        updateSingleCategoryList,
        updateSubCategoryList,
        updateBrandList,
        updateUnitList,
        updateInvoiceSetting
    } = useContext(AppContext);
  //const history = useHistory();
  const { id } = useParams(); // Get the id parameter from the URL
  const isEditMode = !!id; // Check if in edit mode
  const navigate = useNavigate();
  const { getFlashMessageStyle, setSuccessFlashMessage,flashMessage,setErrorFlashMessage } = useFlashMessage(); // Retrieve the dispatch function from your state management library

  const [category, setCategory] = useState(state.categoryList);
  const [subCategory, setSubCategory] = useState([]);
  const [filteredSubCategory, setFilteredSubCategory] = useState([]);

  const [Units, setUnits] = useState([]);
  const [brands, setBrands] = useState(state.brandList);
  const [currency, setCurrency] = useState([]);

  const token = localStorage.getItem("token");
  const [loader, setLoader] = useState(true);
  const [isValid, setIsValid] = useState(true);

  const discountType = ["Fixed", "Percentage"];

  const [formData, setFormData] = useState({
    name: "",
    category_id: 0,
    regular_price: 0,
    sub_category_id: 0,
    brand_id: 0,
    unit_id: 0,
    currency_id: 27,
    image_path: null, // ?
    image_path1: null, // ?
    image_path2: null, // ?
    purchase_price: 0.0,
    wholesale_price: 0.0,
    retails_price: 0.0,
    discount_type: 0,
    discount: 0.0,
    description: "",
    warrenty: "",
    quantity: "",
    others: "",
  });

  useEffect(() => {
    getCategory();
    //getSubCategory();
    //console.log(state);
    //getCurrency();
    //getUnits();
  }, []);
  useEffect(() => {
    getCategory();
    //getSubCategory();
    //console.log(state);
    //getCurrency();
    //getUnits();
  }, [showNestedModal]);


  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
  });


  const getCategory = () => {
    loadingCallback(true);
    get("get-all-category", token)
      .then((response) => {
        loadingCallback(false);
        if (response.success) {
          setCategory(response.data);
          updateCategoryList(response.data);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        console.log(error);
      });
  };

  const getSubCategory = () => {
    get("sub-category?page=1&limit=200", token)
      .then((response) => {
        // console.log(response.data.data.data);

        if (response.success) {
          setLoader(false);
          setSubCategory(response.data.data);
          setFilteredSubCategory(response.data.data)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getBrand = () => {
    get("brands?page=1&limit=200", token)
      .then((response) => {
        // console.log(response.data.data.data);

        if (response.success) {
          setLoader(false);
          setBrands(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUnits = () => {
    get("units?page=1&limit=200", token)
      .then((response) => {
        console.log(response.data.data);

        if (response.success) {
          setLoader(false);
          setUnits(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCurrency = () => {
    get("currency", token)
      .then((response) => {
        console.log(response.data);

        if (response.success) {
          setLoader(false);
          setCurrency(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmitProducts = (e) => {
    //e.preventDefault();
    if(isValid){
      loadingCallback(true);
      uploadFileMultipleInput(
        uploadDetails,
        (brandLogo) => {
          const updatedFormData = { ...formData };
          brandLogo.forEach((item) => {
            updatedFormData[item.input_name] = item.path;
          });

          if (
            !Number(updatedFormData.category_id)
          ) {
            updatedFormData.category_id = 0;
          }
          if (
            !Number(updatedFormData.brand_id)
          ) {
            updatedFormData.brand_id = 0;
          }
          if (
            !Number(updatedFormData.quantity)
          ) {
            updatedFormData.quantity = 0;
          }
          if (
            !Number(updatedFormData.discount)
          ) {
            updatedFormData.discount = 0;
          }
          if (
            !Number(updatedFormData.wholesale_price)
          ) {
            updatedFormData.wholesale_price = 0;
          }
          if (
            !Number(updatedFormData.purchase_price)
          ) {
            updatedFormData.purchase_price = 0;
          }

          if (updatedFormData.discount_type == 'Select Discount Type') {
            updatedFormData.discount_type = '';
          }
          if (updatedFormData.wholesale_price) {
            updatedFormData.regular_price = updatedFormData.wholesale_price;
            updatedFormData.retails_price = updatedFormData.wholesale_price;
          }
          createProduct(updatedFormData);

          //productDataSave(updatedFormData);
        },
        (error) => {
          // Error callback
          console.log(error);
          loadingCallback(false);
        },
        (progressDetails) => {
          // Progress callback, update uploadDetails here
          setUploadDetails(progressDetails);
        }
      );
    } else {
      setWarningMessage(true);
      //setErrorFlashMessage('Please fill all the required fields');
      const timer = setTimeout(() => {
        setWarningMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    }



  };

  const createProduct = (formData) => {
      // setFlashMessage('Product saved successfully');
      // navigate('/products');
      // Use your API service functions to make API requests here
      // console.log("============");
      // console.log(formData);
      if(isValid){

        post("save-product", formData, token)
          .then((response) => {
            loadingCallback(false);
            if (response.success) {
              // setSuccessFlashMessage('Product saved successfully');
              FlashMessage('Product saved successfully', 'success');
              getTopSaleProductlist(1);
              setShowModal(false);
            } else {
              console.log("Product insertion failed");

                setWarningMessage(true);
                const timer = setTimeout(() => {
                  setWarningMessage(false);
                }, 3000);
                return () => clearTimeout(timer);
            }
          })
          .catch((error) => {
            loadingCallback(false);
            if (error?.response?.status === 401) {
              localStorage.clear();
              localStorage.setItem('token', false);
              // Clear session and local storage
              localStorage.setItem('isPinVerify', false);

              navigate('/login');
            }
            console.log(error);
          });
      }
    }

  // Function to handle changes in the input fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === 'category_id') {
      // If the selected category changes, filter and update the subcategories
      if (value != 'Select Category' && formData.category_id != value) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          sub_category_id: '', // Reset subcategory when category changes
        }));
        const filteredSubcategories = subCategory.filter(
          (subCat) => subCat.category_id === Number(value)
        );
        setFilteredSubCategory(filteredSubcategories);
      }
      if (value == 'Select Category') {
        setFormData((prevFormData) => ({
          ...prevFormData,
          sub_category_id: '', // Reset subcategory when category changes
        }));

      }

    }
  };
  // Function to handle changes in the input fields
  const handleChangeSelect = (name, option) => {

    const { value, label } = option;

    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === 'category_id') {
      // If the selected category changes, filter and update the subcategories
      if (value != 'Select Category' && formData.category_id != value) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          sub_category_id: '', // Reset subcategory when category changes
        }));
        const filteredSubcategories = subCategory.filter(
          (subCat) => subCat.category_id === Number(value)
        );
        setFilteredSubCategory(filteredSubcategories);
      }
      if (value == 'Select Category') {
        setFormData((prevFormData) => ({
          ...prevFormData,
          sub_category_id: '', // Reset subcategory when category changes
        }));

      }

    }
  };
  const [uploadDetails, setUploadDetails] = useState([]);




  const handleImageChange = async (files, name) => {
    let tempUploadDetails = [...uploadDetails];
    const allowedImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    for (let i = 0; i < files.length; i++) {

      // Validate file type
      if (!allowedImageTypes.includes(files[i].type)) {
        // setErrorFlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        FlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`, 'error');
        console.error(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        // Handle invalid file type (display a message, set an error state, etc.)
        setIsValid(false);
        continue; // Skip the current iteration for invalid file types
      }

      let size = files[i].size / 1024;
      let sizeName = '';

      if (size > 1024) {
        size = (size / 1024).toFixed(2);
        sizeName = size + 'MB';
      } else {
        sizeName = size.toFixed(2) + 'KB';
      }
      const resizedImage = await resizeFile(files[i]);
      // Extract base64 data
      const base64Data = resizedImage.split(',')[1];

      // Convert base64 to Blob
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" }); // Adjust the MIME type accordingly

      // Create a File from Blob
      const file = new File([blob],files[i].name, { type: "image/png" }); // Adjust the file name and MIME type accordingly

      tempUploadDetails.push({
        name: files[i].name,
        size: sizeName,
        percentage: 0,
        file: file,
        input_name: name,
      });
    }
    //console.log("============");
    //console.log(tempUploadDetails);

    setUploadDetails(tempUploadDetails);
  };
  return (
    <div>
      {flashMessage && (
        <div className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(flashMessage.type)}`}>
          {flashMessage.text}
        </div>
      )}
      <ToastContainer />

      <ProductEditForm
        formData={formData}
        handleChange={handleChange}
        handleChangeSelect={handleChangeSelect}
        handleSubmitProducts={handleSubmitProducts}
        category={state.categoryList ? state.categoryList : category}
        subCategory={subCategory}
        filteredSubCategory={filteredSubCategory}
        Units={Units}
        brands={
          state?.brandList?.data
            ? state?.brandList?.data
            : state?.brandList
            ? state?.brandList
            : brands
        }
        setBrands={setBrands}
        setCategory={setCategory}
        currency={currency}
        discountType={discountType}
        handleImageChange={handleImageChange}
        loadingCallback={loadingCallback}
        loader={loader}
        isEditMode={isEditMode}
        setErrorFlashMessage={setErrorFlashMessage}
        setIsValid={setIsValid}
        isValid={isValid}
        uploadDetails={uploadDetails}
        isQuick={true}
        setShowNestedModal ={setShowNestedModal}
        showNestedModal={showNestedModal}
        setNestedModalData={props.setNestedModalData}
        saleType={saleType}
      />
    </div>
  );
};

export default ProductEditModal;
