// // import React from 'react';

// // const ExporterProductDetailPage = () => {
// //   return (
// //     <div>
// //       <h1>hello</h1>
// //     </div>
// //   );
// // };

// // export default ExporterProductDetailPage;
// import React, { useContext, useEffect, useState } from "react";
// import { useNavigate, useParams } from "react-router-dom";
// import imageIcon from "./Icon.svg";
// import { formatPhoneNumber,formatPrice,formatDate, formatNumber } from "../../../utils/Common";
// import { AppContext } from "../../../context/context";
// import ContentLayout from "../../../layout/ContentLayout";
// import { get } from "../../../services/apiService";

// const ExporterProductDetailPage = () => {
//   const { id } = useParams(); // Get the id parameter from the URL
//   const { state } = useContext(AppContext);
//   const { currentUser, invoiceSetting } = state;
//   const token = localStorage.getItem("token");
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const loadingCallback = (loading) => {
//     setLoading(loading);
//   };

//   const [productData, setProductData] = useState(null);

//   useEffect(() => {
//     // Fetch product data based on the ID and populate
//     fetchProductData(id);
//   }, [id]);

//   const fetchProductData = (productId) => {
//     loadingCallback(true);
//     // Make an API request to fetch product data by ID
//     // Once data is retrieved, update the form fields with the data
//     get("purchase-product-details/" + productId, token)
//     //get("product-details/" + productId, token)
//       .then((response) => {
//         const productData = response.data; // Replace with your API response data
//         console.log("============");
//         console.log(productData);

//         loadingCallback(false);
//         setProductData(productData);
//       })
//       .catch((error) => {
//         console.log(error);
//         loadingCallback(false);
//         if (error?.response?.status === 401) {
//           localStorage.clear();
//           localStorage.setItem('token', false);
//           // Clear session and local storage
//           localStorage.setItem('isPinVerify', false);

//           navigate('/login');
//           // Navigate to the '/login' route
//         }
//       });
//   };

//   let discount = 0;
//   if (
//     productData?.discount_type == "Percentage" ||
//     productData?.discount_type == "2"
//   ) {
//     discount = formatPrice(
//       (productData.retails_price * productData.discount) / 100
//     );
//   } else {
//     discount = productData?.discount;
//   }

//   const [selectedFoodImg, setSelectedFoodImg] = useState(null);
//   const handleSelectedFoodImage = (imgPath = "") => {
//     setSelectedFoodImg(imgPath);
//   };

//   return (
//     <ContentLayout>
//       {productData && (
//         <div className="main-content font-nunito text-product-black">
//           <div className="flex flex-col md:flex-row gap-4 w-full">
//             <div className="product-image">
//               <div className="md:w-[140px] md:h-[140px] lg:w-[220px] lg:h-[220px] xl:w-[350px] xl:h-[350px] bg-[#E7E7E7] rounded-lg overflow-hidden">
//                 <img
//                   className="w-full h-full object-cover mx-auto"
//                   src={
//                     selectedFoodImg
//                       ? selectedFoodImg
//                       : productData?.image_path
//                       ? productData.image_path
//                       : imageIcon
//                   }
//                   alt="image-icon"
//                 />
//               </div>

//               <div className="flex gap-3 mt-[20px]">
//                 <div className="w-[60px] h-[60px] md:w-[121px] md:h-[121px] bg-[#E7E7E7] rounded-lg overflow-hidden">
//                   <img
//                     className="w-full h-full object-cover"
//                     src={productData?.image_path}
//                     onClick={() => {
//                       handleSelectedFoodImage(productData?.image_path);
//                     }}
//                     alt=""
//                   />
//                 </div>
//                 {productData?.image_path1 || productData?.image_path1 ? (
//                   <div className="w-[60px] h-[60px] md:w-[121px] md:h-[121px] bg-[#E7E7E7] rounded-lg overflow-hidden ">
//                     <img
//                       className="w-full h-full object-cover "
//                       src={productData.image_path1}
//                       onClick={() => {
//                         handleSelectedFoodImage(productData.image_path1);
//                       }}
//                       alt=""
//                     />
//                   </div>
//                 ) : null}
//                 {productData?.image_path2 || productData?.image_path2 ? (
//                   <div className="w-[60px] h-[60px] md:w-[121px] md:h-[121px] bg-[#E7E7E7] rounded-lg overflow-hidden">
//                     <img
//                       className="w-full h-full object-cover"
//                       src={productData.image_path2}
//                       onClick={() => {
//                         handleSelectedFoodImage(productData.image_path2);
//                       }}
//                       alt=""
//                     />
//                   </div>
//                 ) : null}
//               </div>
//             </div>
//             <div className="product-info ">
//               <div className="product-title ">
//                 <h2 className=" font-bold text-[25px] md:text-[40px]">{productData?.name}</h2>
//                 <p className="text-gray-400 text-[20px] font-semibold">
//                   #{productData.barcode}
//                 </p>
//               </div>
//               <div className="mt-[20px]">
//                 <div className="grid grid-cols-2 gap-5">
//                   <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">Category </p>{" "}
//                   <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito"> : {productData?.category?.name}</p>
//                 </div>
//                 <div className="grid grid-cols-2 gap-5">
//                   <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">Sub Category </p>{" "}
//                   <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito"> : {productData?.sub_category?.name} </p>
//                 </div>
//                 <div className="grid grid-cols-2 gap-5">
//                   <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">Brand </p>{" "}
//                   <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito"> : {productData?.brands?.name} </p>
//                 </div>
//                 <div className="grid grid-cols-2 gap-5">
//                   <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">Quantity </p>{" "}
//                   <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito">
//                     {" "}
//                     : {formatPhoneNumber(productData?.current_stock)}{" "}
//                     {productData?.unit?.name}s{" "}
//                   </p>
//                 </div>
//                 <div className="grid grid-cols-2 gap-5">
//                   <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">Unit </p>{" "}
//                   <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito"> : {productData?.unit?.name} </p>
//                 </div>
//                 <div className="grid grid-cols-2 gap-5">
//                   <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">Purchase Price </p>{" "}
//                   <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito">
//                     : {formatNumber(productData.purchase_price)}{" "}
//                     {invoiceSetting?.currency_info?.code
//                       ? invoiceSetting?.currency_info?.code
//                       : "BDT"}
//                   </p>
//                 </div>

//                 <div className="border-t border-solid border-[#718096] mt-6 mb-6"></div>
//                 {productData?.purchase[0] && productData?.purchase[0].purchase?.vendor && (
//                 <>

//                   <div className="mb-2 "><p className="text-[18px] text-[#2D3748] font-bold font-nunito">Vendor Info</p></div>

//                   <div className="grid grid-cols-2 gap-5">
//                     <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">
//                       Proprietor name
//                     </p>
//                     <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito"> : </p>
//                   </div>

//                   <div className="grid grid-cols-2 gap-5">
//                     <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">
//                       Vendor Name{" "}
//                     </p>
//                     <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito"> : {productData?.purchase[0].purchase?.vendor?.name}</p>
//                   </div>

//                   <div className="grid grid-cols-2 gap-5">
//                     <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">
//                       Phone Number
//                     </p>
//                     <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito"> : {productData?.purchase[0].purchase?.vendor?.country_code}{productData?.purchase[0].purchase?.vendor?.mobile_number}</p>
//                   </div>

//                   <div className="grid grid-cols-2 gap-5">
//                     <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">
//                       Address
//                     </p>
//                     <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito"> : {productData?.purchase[0].purchase?.vendor?.address}</p>
//                   </div>

//                   <div className="grid grid-cols-2 gap-5">
//                     <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">
//                       Purchase Date{" "}
//                     </p>
//                     <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito"> : {formatDate(productData?.purchase[0].purchase?.created_at)}</p>
//                   </div>

//                   <div className="grid grid-cols-2 gap-5">
//                     <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">
//                       Receiver name{" "}
//                     </p>
//                     <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito"> : </p>
//                   </div>
//                 </>
//               )}
//               </div>
//             </div>
//           </div>
//           <div className="mt-[30px] mb-2">
//             <h2 className="text-[20px] txt-[#2D3748] font-nunito font-semibold">Feature & Details </h2>
//             <p className="mt-2 text-[17px] text-[#2D3748] font-bold font-nunito">{productData.description}</p>
//           </div>
//         </div>
//       )}
//       {loading && (

//         <div className="custom-loader-logo">
//           {/* <BeatLoader color={"#123abc"} size={20} /> */}
//           <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
//         </div>
//       )}
//     </ContentLayout>
//   );
// };

// export default ExporterProductDetailPage;





import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { AppContext } from "../../../context/context";
import ContentLayout from "../../../layout/ContentLayout";
import { get } from "../../../services/apiService";
import imageIcon from "./Icon.svg";
// import RecentlyProductDetailHistory from "./RecentlyProductDetailHistory";
import Pagination from "../../../utils/pagination";
import axios from "axios";
import RecentlyProductDetailHistory from "../RecentlyProductDetailHistory/RecentlyProductDetailHistory";
import { formatPrice, formatNumber,formatDate } from "../../../utils/Common";

const RecentlyProductDetailPage = () => {
  const { id } = useParams(); // Get the id parameter from the URL
  const { state } = useContext(AppContext);
  const { currentUser, invoiceSetting } = state;
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };

  const perPage = 10;
  const [allFetchedData, setAllFetchedData] = useState([]);
  const shouldRenderPagination = 15 > perPage;

  const [productData, setProductData] = useState(null);

  useEffect(() => {
    // Fetch product data based on the ID and populate
    fetchProductData(id);
    // getPurchaseHistoryList(id)
  }, [id]);

  const fetchProductData = (productId) => {
    loadingCallback(true);
    get("exporter-product-details/" + productId, token)
      .then((response) => {
        const productData = response.data;
        console.log("============");
        console.log("Data", productData);

        loadingCallback(false);
        setProductData(productData);
        getPurchaseHistoryList(productData?.product_id)
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
      });
  };

  const getPurchaseHistoryList = (id) => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}exporter-product-purchase-history/${id}`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      loadingCallback(false)
      console.log("response", response.data.data);
      if(response.data.success){
        setAllFetchedData(response.data.data);
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };

  let discount = 0;
  if (
    productData?.discount_type == "Percentage" ||
    productData?.discount_type == "2"
  ) {
    discount = formatPrice(
      (productData.retails_price * productData.discount) / 100
    );
  } else {
    discount = productData?.discount;
  }

  const [selectedFoodImg, setSelectedFoodImg] = useState(null);
  const handleSelectedFoodImage = (imgPath = "") => {
    setSelectedFoodImg(imgPath);
  };

  return (
    <ContentLayout>
      {/* {productData && ( */}
        <div className="main-content font-nunito text-product-black">
          <div className="flex flex-col md:flex-row gap-4 w-full">
            <div className="product-image">
              <div className="md:w-[140px] md:h-[140px] lg:w-[220px] lg:h-[220px] xl:w-[350px] xl:h-[350px] bg-[#E7E7E7] rounded-lg overflow-hidden">
                <img
                  className="w-full h-full object-cover mx-auto"
                  src={
                    selectedFoodImg
                      ? selectedFoodImg
                      : productData?.image_path
                      ? productData.image_path
                      : imageIcon
                  }
                  alt="image-icon"
                />
              </div>

              <div className="flex gap-3 mt-[20px]">
                <div className="w-[60px] h-[60px] md:w-[121px] md:h-[121px] bg-[#E7E7E7] rounded-lg overflow-hidden">
                  <img
                    className="w-full h-full object-cover"
                    src={productData?.image_path}
                    onClick={() => {
                      handleSelectedFoodImage(productData?.image_path);
                    }}
                    alt=""
                  />
                </div>
                {productData?.image_path1 || productData?.image_path1 ? (
                  <div className="w-[60px] h-[60px] md:w-[121px] md:h-[121px] bg-[#E7E7E7] rounded-lg overflow-hidden ">
                    <img
                      className="w-full h-full object-cover "
                      src={productData.image_path1}
                      onClick={() => {
                        handleSelectedFoodImage(productData.image_path1);
                      }}
                      alt=""
                    />
                  </div>
                ) : null}
                {productData?.image_path2 || productData?.image_path2 ? (
                  <div className="w-[60px] h-[60px] md:w-[121px] md:h-[121px] bg-[#E7E7E7] rounded-lg overflow-hidden">
                    <img
                      className="w-full h-full object-cover"
                      src={productData.image_path2}
                      onClick={() => {
                        handleSelectedFoodImage(productData.image_path2);
                      }}
                      alt=""
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="product-info ">
              <div className="product-title ">
                <h2 className=" font-bold text-[25px] md:text-[40px]">{productData?.product_name}</h2>
                <p className="text-gray-400 text-[20px] font-semibold">
                  #{productData?.barcode}
                </p>
              </div>
              <div className="mt-[20px]">
                <div className="grid grid-cols-2 gap-5">
                  <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">Category </p>{" "}
                  <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito"> : {productData?.category_name}</p>
                </div>
                <div className="grid grid-cols-2 gap-5">
                  <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">Sub Category </p>{" "}
                  <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito"> : {productData?.sub_category_name} </p>
                </div>
                <div className="grid grid-cols-2 gap-5">
                  <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">Brand </p>{" "}
                  <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito"> : {productData?.brand_name} </p>
                </div>
                <div className="grid grid-cols-2 gap-5">
                  <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">Quantity </p>{" "}
                  <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito">
                    {" "}
                    : {formatNumber(productData?.qty)}{" "}
                    {productData?.unit_name}{" "}
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-5">
                  <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">Unit </p>{" "}
                  <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito"> : {productData?.unit_name} </p>
                </div>
                <div className="grid grid-cols-2 gap-5">
                  <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">Purchase Price </p>{" "}
                  <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito">
                    : {formatNumber(productData?.purchase_price)}{" "}
                    {invoiceSetting?.currency_info?.code
                      ? invoiceSetting?.currency_info?.code
                      : "BDT"}
                  </p>
                </div>

                <div className="border-t border-solid border-[#718096] mt-6 mb-6"></div>
                {/* {productData?.purchase[0] && productData?.purchase[0].purchase?.vendor && ( */}
                  <>
                    <div className="mb-2 "><p className="text-[18px] text-[#2D3748] font-bold font-nunito">Recent Exporter Info</p></div>

                    <div className="grid grid-cols-2 gap-5">
                      <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">
                        Exporter name
                      </p>
                      <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito"> : {productData?.exporter_name} </p>
                    </div>

                    {/* <div className="grid grid-cols-2 gap-5">
                      <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">
                        Carrier Name{" "}
                      </p>
                      <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito"> : {productData?.carrier_name}</p>
                    </div> */}

                    <div className="grid grid-cols-2 gap-5">
                      <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">
                        Exporter Number
                      </p>
                      <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito"> : {productData?.exporter_phone_no}</p>
                    </div>

                    <div className="grid grid-cols-2 gap-5">
                      <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">
                        Exporter Address
                      </p>
                      <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito"> : {productData?.exporter_address}</p>
                    </div>

                    <div className="grid grid-cols-2 gap-5">
                      <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">
                        Purchase Date{" "}
                      </p>
                      <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito"> : {formatDate(productData?.purchase_date)}</p>
                    </div>

                    <div className="grid grid-cols-2 gap-5">
                      <p className="text-[#718096] text-[16px] md:text-[18px] font-semibold font-nunito">
                        Carrier name{" "}
                      </p>
                      <p className="text-[#2D3748] text-[16px] md:text-[18px] font-semibold font-nunito"> : {productData?.carrier_name}</p>
                    </div>
                  </>
                  {/* ) */}
                {/* } */}
              </div>
            </div>
          </div>
          {/* <div className="mt-[30px] mb-2">
            <h2 className="text-[20px] txt-[#2D3748] font-nunito font-semibold">Feature & Details </h2>
            <p className="mt-2 text-[17px] text-[#2D3748] font-bold font-nunito">{productData.description}</p>
          </div> */}

          {/* <RecentlyProductDetailHistory></RecentlyProductDetailHistory> */}
          <div>
            <div className="mt-[30px] mb-2">
              <h2 className="text-[20px] txt-[#2D3748] font-nunito font-semibold">Exporter History </h2>
            </div>

            {shouldRenderPagination && (
              <Pagination
                // currentPage={transactionList?.current_page}
                // lastPage={transactionList?.last_page}
                // // currentPage={currentPage}
                // // lastPage={10}
                // onPageChange={onPageChange}
              />
            )}
            <div>
              <div className=" grid text-left text-[14px] mt-3 py-[8px] font-nunito font-bold text-[#000232] grid-cols-6 border-b-[1px] border-[#E7EBF4]">
                <div className="text-left col-span-1">Name</div>
                <div className="text-center col-span-1">Purchase Unit Price</div>
                <div className="text-center col-span-1">Purchase QTY</div>
                <div className="text-center col-span-1">Date/Time</div>
                <div className="text-center col-span-1">Status</div>
                <div className="text-right col-span-1">Action</div>
              </div>
              {allFetchedData.map((cData, index) => {
                return(
                <RecentlyProductDetailHistory
                  index={index}
                  // // paymentList={paymentList}
                  // // paymentAccountList={paymentAccountList}
                  purchaseData={cData}
                  // activeTab={activeTab}
                  // selectAllChecked={selectAllChecked}
                  // onSelect={handleItemSelect}
                  // setAllFetchedData={setAllFetchedData}
                  // allFetchedData={allFetchedData}
                  // key={index}
                 />
               )})
              }
            </div>
          </div>

        </div>
      {/* )} */}
      {loading && (

        <div className="custom-loader-logo">
          {/* <BeatLoader color={"#123abc"} size={20} /> */}
          <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
        </div>
      )}
    </ContentLayout>
  );
};

export default RecentlyProductDetailPage;
