import React from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, Link, NavLink } from "react-router-dom";
import "./Navbar.scss";
// import "./Navbar.css";
const BASE_URL = process.env.REACT_APP_ROOT_URL;
const Navbar = (props) => {
  const { closeMenu, onLogout } = props;
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState("/dashboard");
  useEffect(() => {
    const pathName = location.pathname;
    setActiveIndex(pathName);
  }, [location.pathname]);
  const settingPaths = [
    "/settings",
    "/settings/category",
    "/settings/subcategory",
    "/settings/brands",
    "/settings/units",
    "/settings/discounts",
    "/settings/settings",
    "/settings/shop-settings",
    "/settings/invoice-settings",
    "/settings/send-feedback",
    "/settings/help",
  ];
  const productPaths = ["/addproducts", "/products"];
  const isEditProductPath = (path) => {
    return path.startsWith("/editproduct/");
  };
  const isEditRecentlyProductPath = (path) => {
    return path.startsWith("/recently-editproduct/");
  };
  const recentlyProductPaths = ["/recently-addproducts", "/recently-added"];
  const advanceBookingsPaths = ["/advance-bookings"];
  const isViewAdvanceBookingPath = (path) => {
    return path.startsWith("/advance-bookings/");
  };
  const wholeSalePaths = [
    '/wholesale-list'
  ];
  const isViewWholesalePath = (path) => {
    return path.startsWith('/wholesale-list/');
  };
  const exporterPaths = [
    '/exporter'
  ];
  const isViewExporterPath = (path) => {
    return path.startsWith('/exporter/');
  };
    // const Menus = [
    //   { title:"Dashboard", icon: "fa-house-chimney", src: "/dashboard", isActive : activeIndex === '/dashboard' ? true : false },
    //   { title: "Products", icon: "fa-chart-simple", src: "/products", isActive : productPaths.includes(activeIndex) || isEditProductPath(activeIndex) ? true : false },
    //   { title: "Recently Purchased", icon: "fa-chart-simple", src: "/recently-added", isActive : recentlyProductPaths.includes(activeIndex) || isEditRecentlyProductPath(activeIndex) ? true : false },
    //   { title: "Billing", icon: "fa-wallet", src: "/billing", isActive : activeIndex === '/billing' ? true : false },
    //   { title: "Purchase Billing", icon: "fa-wallet", src: "/purchase-billing", isActive : activeIndex === '/purchase-billing' ? true : false },
    //   { title: "Advance Booking", icon: "fa-wallet", src: "/advance-booking", isActive : activeIndex === '/advance-booking' ? true : false },
    //   { title: "Booking list", icon: "fa-chart-simple", src: "/advance-bookings", isActive : advanceBookingsPaths.includes(activeIndex) || isViewAdvanceBookingPath(activeIndex) ? true : false },
    //   { title: "Settings ", icon: "fa-gear", src: "/settings", gap: true  , isActive : settingPaths.includes(activeIndex) ? true : false},
    //   { title: "Profile", icon: "fa-user", src: "/profile", isActive : activeIndex === '/profile' ? true : false},
    //   { title: "Sign Out", icon: "fa-right-to-bracket", isActive : activeIndex === 'signout' ? true : false}
    // ];
    // const Menus = [
    //   { title: "Dashboard", icon: "fa-house-chimney", src: "/dashboard", isActive: activeIndex === '/dashboard' ? true : false },
    //   { title: "Products", icon: "fa-chart-simple", src: "/products", isActive: productPaths.includes(activeIndex) || isEditProductPath(activeIndex) ? true : false },
    //   { title: "Recently Purchased", icon: "fa-chart-simple", src: "/recently-added", isActive: recentlyProductPaths.includes(activeIndex) || isEditRecentlyProductPath(activeIndex) ? true : false },
    //   { title: "Billing", icon: "fa-wallet", src: "/billing", isActive: activeIndex === '/billing' ? true : false },
    //   { title: "Purchase Billing", icon: "fa-wallet", src: "/purchase-billing", isActive: activeIndex === '/purchase-billing' ? true : false },
    //   { title: "Sale Return", icon: "fa-boxes-packing", src: "/sales-return", isActive: activeIndex === '/sales-return' ? true : false },
    //   { title: "Purchase Return", icon: "fa-boxes-packing", src: "/purchase-return", isActive: activeIndex === '/purchase-return' ? true : false },
    //   // { title: "Inwards", icon: "fa-boxes-packing", inwards: true,src:"/sales-return", isActive: activeIndex === '/dashboard' ? true : false },
    //   { title: "Advance Booking", icon: "fa-wallet", src: "/advance-booking", isActive: activeIndex === '/advance-booking' ? true : false },
    //   { title: "Booking list", icon: "fa-chart-simple", src: "/advance-bookings", isActive: advanceBookingsPaths.includes(activeIndex) || isViewAdvanceBookingPath(activeIndex) ? true : false },
    //   { title: "Settings ", icon: "fa-gear", src: "/settings", gap: true, isActive: settingPaths.includes(activeIndex) ? true : false },
    //   { title: "Profile", icon: "fa-user", src: "/settings/shop-settings", isActive: activeIndex === '/settings/shop-settings' ? true : false },
    //   { title: "Sign Out", icon: "fa-right-to-bracket", isActive: activeIndex === 'signout' ? true : false }
    // ];
    const Menus = [
      { title: "Dashboard", icon: "fa-house-chimney", src: "/dashboard", isActive: activeIndex === '/dashboard' ? true : false },
      { title: "Products", icon: "fa-chart-simple", src: "/products", isActive: productPaths.includes(activeIndex) || isEditProductPath(activeIndex) ? true : false },
      { title: "Recently Purchased", icon: "fa-chart-simple", src: "/recently-added", isActive: recentlyProductPaths.includes(activeIndex) || isEditRecentlyProductPath(activeIndex) ? true : false },
      { title: "Sale Billing", icon: "fa-wallet", src: "/billing", isActive: activeIndex === '/billing' ? true : false },
      { title: "Purchase Billing", icon: "fa-wallet", src: "/purchase-billing", isActive: activeIndex === '/purchase-billing' ? true : false },
      { title: "Sale Return", icon: "fa-boxes-packing", src: "/sales-return", isActive: activeIndex === '/sales-return' ? true : false },
      { title: "Purchase Return", icon: "fa-boxes-packing", src: "/purchase-return", isActive: activeIndex === '/purchase-return' ? true : false },
      // { title: "Inwards", icon: "fa-boxes-packing", inwards: true,src:"/sales-return", isActive: activeIndex === '/dashboard' ? true : false },
      { title: "Advance Booking", icon: "fa-wallet", src: "/advance-booking", isActive: activeIndex === '/advance-booking' ? true : false },
      { title: "Wholesale Billing", icon: "fa-wallet", src: "/whole-sale-billing", isActive: activeIndex === '/whole-sale-billing' ? true : false },

      { title: "Analytics", icon: "fa-arrows-left-right-to-line", src: "/analytics", isActive: activeIndex === '/analytics' ? true : false },
      { title: "Booking list", icon: "fa-chart-simple", src: "/advance-bookings", isActive: advanceBookingsPaths.includes(activeIndex) || isViewAdvanceBookingPath(activeIndex) ? true : false },
      { title: "Wholesale list", icon: "fa-chart-simple", src: "/wholesale-list", isActive: wholeSalePaths.includes(activeIndex) || isViewWholesalePath(activeIndex) ? true : false },
      { title: "Exporter", icon: "fa-file-export", src: "/exporter", isActive: exporterPaths.includes(activeIndex) || isViewExporterPath(activeIndex) ? true : false },
      { title: "Exporter User", icon: "fa-user", src: "/exporter-list", isActive: activeIndex === '/exporter-list' ? true : false },
      { title: "Carrier", icon: "fa-user", src: "/carrier-list", isActive: activeIndex === '/carrier-list' ? true : false },
      { title: "Exporter Product", icon: "fa-regular fa-file-export", src: "/exporter-product-list", isActive: activeIndex === '/exporter-product-list' ? true : false },

      // { title: "Defect list", icon: "fa-chart-simple", src: "/defect-list", isActive: activeIndex === '/defect-list' ? true : false },
      { title: "Hold Invoice list", icon: "fa-chart-simple", src: "/hold-invoice-list", isActive: activeIndex === '/hold-invoice-list' ? true : false },
      { title: "Hold Wholesale Invoice", icon: "fa-chart-simple", src: "/hold-wholesale-invoice-list", isActive: activeIndex === '/hold-wholesale-invoice-list' ? true : false },
      { title: "Customer list", icon: "fa-boxes-packing", src: "/customer-list", isActive: activeIndex === '/customer-list' ? true : false },
      { title: "Vendor list", icon: "fa-user", src: "/vendor-list", isActive: activeIndex === '/vendor-list' ? true : false },
      { title: "Wholesaler list", icon: "fa-user", src: "/wholesaller-list", isActive: activeIndex === '/wholesaller-list' ? true : false },
      { title: "Chart of Account", icon: "fa-user", src: "/chart-of-account", isActive: activeIndex === '/chart-of-account' ? true : false },
      { title: "Journal list", icon: "fa-user", src: "/journal-list", isActive: activeIndex === '/journal-list' ? true : false },
      { title: "Expense list", icon: "fa-user", src: "/expense-list", isActive: activeIndex === '/expense-list' ? true : false },
      { title: "Warehouse list", icon: "fa-user", src: "/warehouse-list", isActive: activeIndex === '/warehouse-list' ? true : false },

      { title: "Settings ", icon: "fa-gear", src: "/settings/shop-settings", gap: true, isActive: settingPaths.includes(activeIndex) ? true : false },
      { title: "Profile", icon: "fa-user", src: "/settings/shop-settings", isActive: activeIndex === '/settings/shop-settings' ? true : false },
      { title: "Sign Out", icon: "fa-right-to-bracket", isActive: activeIndex === 'signout' ? true : false }
    ];
    const navigate = useNavigate();

  const LogOut = () => {
    localStorage.clear();
    localStorage.setItem("token", false);
    // Clear session and local storage
    localStorage.setItem("isPinVerify", false);

    navigate("/login");
  };

  const SignUps = () => {
    localStorage.removeItem("credentials");
    navigate("/signup");
  };

  return (
    <div
      className={`navbar md:!hidden pt-2 items-start pl-2 flex-nowrap h-[100vh] overflow-y-auto relative text-center w-60 p-10`}
    >
      <div className="top w-20">
        <Link className="logo" to="/dashboard">
          <img src={`${BASE_URL}/logo1.png`} alt="logo" />
        </Link>
        <i
          onClick={closeMenu}
          className="fa-solid fa-times-circle closeIcon"
        ></i>
      </div>

      <div className={`ml-10 center mt-10`}>
        <ul>
          {Menus.map((Menu, index) => (
            <NavLink
            className={({ isActive, isPending }) =>
           isPending ? "pending" : isActive ? "active-mobile-menu" : ""
  }

              to={`${Menu.src}`}
            >
              {" "}
              <li
                key={index}

                className={` ${index === 0 && "bg-light-white"} `}
                onClick={Menu.src ? null : onLogout}
              >
                <i className={`fa-solid ${Menu.icon} icon`}></i>
                <a href={Menu.src ? Menu.src : "#"} className={`duration-200`}>
                  {Menu.title}
                </a>
              </li>
              <span>
                {Menu.gap && (
                  <li className="mt-9 font-semibold text-gray-500 ml-5">
                    ACCOUNT PAGES
                    <br />
                  </li>
                )}
              </span>
            </NavLink>
          ))}
        </ul>
      </div>

      {/* <div className={`middle`}>
          <div className='middle_card'>
            <div className='needicon'>
              <img src={`${BASE_URL}/needicon.svg`} alt='icon' />
            </div>
            <div>
              <h1 className='card_text'>Need help?</h1>
              <p>Please check our docs</p>
              <button className={`card_button `}>DOCUMENTATION</button>
            </div>

          </div>
        </div> */}
    </div>
  );
};
export default Navbar;
