// import {
//     AppstoreOutlined,
//     ShopOutlined,
//     ShoppingCartOutlined,
//     UserOutlined,
//     HomeTwoTone
// } from "@ant-design/icons";
// import { Menu } from "antd";
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import './index.scss';
// import './index.css'
import arrow from './../../assets/images/left-chevron.svg';
import dropDownIcon from './image/fi_1535604.png';
import MyDropdown from './MyDropdown';
import NavItem from './NavItem';
import { AppContext } from '../../context/context';

const BASE_URL = process.env.REACT_APP_ROOT_URL;
// const MAX_SCREEN_WIDTH = 1280;
const MAX_SCREEN_WIDTH = 767;

const Sidebar = props => {
  const {
    onLogout
  } = props;

  const location = useLocation();
  // const [selectedKeys, setSelectedKeys] = useState("/");
  const [activeIndex, setActiveIndex] = useState('/dashboard');
  //const [open, setOpen] = useState(true);
  // const [activeMenu, setActiveMenu] = useState(null)
  const [open, setOpen] = useState(true);

  const handleResize = () => {
    const windowWidth = document.documentElement.clientWidth || window.innerWidth;

    setOpen(windowWidth >= MAX_SCREEN_WIDTH);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial setup
    handleResize();

    return () => {
      // Remove the event listener on component unmount
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array means this effect runs once after initial render

  useEffect(() => {
    const pathName = location.pathname;
    setActiveIndex(pathName);
    if (typeof window !== 'undefined' && window.innerWidth < MAX_SCREEN_WIDTH) {
      setOpen(false);
    }
  }, [location.pathname]);



  const settingPaths = [
    '/settings',
    '/settings/category',
    '/settings/subcategory',
    '/settings/brands',
    '/settings/units',
    '/settings/discounts',
    '/settings/settings',
    '/settings/shop-settings',
    '/settings/invoice-settings',
    '/settings/send-feedback',
    '/settings/help'
  ];
  const productPaths = [
    '/addproducts',
    '/products'
  ];
  const isEditRecentlyProductPath = (path) => {
    return path.startsWith('/recently-editproduct/');
  };
  const recentlyProductPaths = [
    '/recently-addproducts',
    '/recently-added'
  ];
  const isEditProductPath = (path) => {
    return path.startsWith('/editproduct/');
  };

  const advanceBookingsPaths = [
    '/advance-bookings'
  ];
  const isViewAdvanceBookingPath = (path) => {
    return path.startsWith('/advance-bookings/');
  };
  const wholeSalePaths = [
    '/wholesale-list'
  ];
  const isViewWholesalePath = (path) => {
    return path.startsWith('/wholesale-list/');
  };

  const returnListPaths = [
    '/sales-return'
  ];
  const isViewReturnPath = (path) => {
    return path.startsWith('/sales-return/');
  };
  const purchaseReturnPaths = [
    '/purchase-return'
  ];
  const isViewPurchaseReturnPath = (path) => {
    return path.startsWith('/purchase-return/');
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState()

  // const Menus = [
  //   { title: "Dashboard", icon: "fa-house-chimney", src: "/dashboard", isActive: activeIndex === '/dashboard' ? true : false },
  //   { title: "Products", icon: "fa-chart-simple", src: "/products", isActive: productPaths.includes(activeIndex) || isEditProductPath(activeIndex) ? true : false },
  //   { title: "Recently Purchased", icon: "fa-chart-simple", src: "/recently-added", isActive: recentlyProductPaths.includes(activeIndex) || isEditRecentlyProductPath(activeIndex) ? true : false },
  //   { title: "Sale Billing", icon: "fa-wallet", src: "/billing",  isActive: activeIndex === '/billing' ? true : false },
  //   { title: "Purchase Billing", icon: "fa-wallet", src: "/purchase-billing", isActive: activeIndex === '/purchase-billing' ? true : false },
  //   { title: "Sale Return", icon: "fa-boxes-packing", src: "/sales-return", isActive: activeIndex === '/sales-return' ? true : false },
  //   { title: "Purchase Return", icon: "fa-boxes-packing", src: "/purchase-return", isActive: activeIndex === '/purchase-return' ? true : false },
  //   // { title: "dropDown", icon: "fa-boxes-packing", dropDown: true,src:"/sales-return", isActive: activeIndex === '/dashboard' ? true : false },
  //   { title: "Advance Booking", icon: "fa-wallet", src: "/advance-booking", isActive: activeIndex === '/advance-booking' ? true : false },
  //   { title: "Wholesale Billing", icon: "fa-wallet", src: "/whole-sale-billing", isActive: activeIndex === '/whole-sale-billing' ? true : false },

  //   { title: "Analytics", icon: "fa-arrows-left-right-to-line", src: "/analytics", isActive: activeIndex === '/analytics' ? true : false },
  //   { title: "Booking list", icon: "fa-chart-simple", src: "/advance-bookings", isActive: advanceBookingsPaths.includes(activeIndex) || isViewAdvanceBookingPath(activeIndex) ? true : false },
  //   { title: "Defect list", icon: "fa-chart-simple", src: "/defect-list", isActive: activeIndex === '/defect-list' ? true : false },
  //   { title: "Wholesale list", icon: "fa-chart-simple", src: "/wholesale-list", isActive: wholeSalePaths.includes(activeIndex) || isViewWholesalePath(activeIndex) ? true : false },

  //   { title: "Hold Invoice list", icon: "fa-chart-simple", src: "/hold-invoice-list", isActive: activeIndex === '/hold-invoice-list' ? true : false },
  //   { title: "Hold Wholesale Invoice", icon: "fa-chart-simple", src: "/hold-wholesale-invoice-list", isActive: activeIndex === '/hold-wholesale-invoice-list' ? true : false },

  //   { title: "Customer list", icon: "fa-boxes-packing", src: "/customer-list", isActive: activeIndex === '/customer-list' ? true : false },
  //   { title: "Vendor list", icon: "fa-user", src: "/vendor-list", isActive: activeIndex === '/vendor-list' ? true : false },
  //   { title: "Settings ", icon: "fa-gear", src: "/settings/shop-settings", gap: true, isActive: settingPaths.includes(activeIndex) ? true : false },
  //   { title: "Profile", icon: "fa-user", src: "/settings/shop-settings", isActive: activeIndex === '/settings/shop-settings' ? true : false },
  //   { title: "Sign Out", icon: "fa-right-to-bracket", isActive: activeIndex === 'signout' ? true : false }
  // ];

  const Menu = [
    { title: "Dashboard", icon: "fa-house-chimney", src: "/dashboard", isActive: activeIndex === '/dashboard' ? true : false },

    { title: "Products", icon: "fa-chart-simple", src: null, dropDown:[
      { title: "Add Product", icon: "fa-chart-simple", src: "/addproducts", isActive: activeIndex === "/addproducts" ? true : false },
      { title: "Product List", icon: "fa-chart-simple", src: "/products", isActive: activeIndex === "/products" ? true : false },
    ],
  },

  { title: "Sale", icon: "fa-wallet", src: null,  dropDown:[
    { title: "Sale Billing", icon: "fa-wallet", src: "/billing",  isActive: activeIndex === '/billing' ? true : false },
    { title: "Sale Return", icon: "fa-boxes-packing", src: "/sales-return", isActive: activeIndex === '/sales-return' ? true : false },
    { title: "Advance Booking", icon: "fa-wallet", src: "/advance-booking", isActive: activeIndex === '/advance-booking' ? true : false },
    { title: "Customer list", icon: "fa-boxes-packing", src: "/customer-list", isActive: activeIndex === '/customer-list' ? true : false },
    { title: "Booking list", icon: "fa-chart-simple", src: "/advance-bookings", isActive: advanceBookingsPaths.includes(activeIndex) || isViewAdvanceBookingPath(activeIndex) ? true : false },
    { title: "Sale Invoice", icon: "fa-chart-simple", src: "/invoice", isActive: activeIndex === '/invoice' ? true : false },
    { title: "Hold Invoice list", icon: "fa-chart-simple", src: "/hold-invoice-list", isActive: activeIndex === '/hold-invoice-list' ? true : false },
  ],
},

{ title: "Purchase", icon: "fa-wallet", src: null,  dropDown:[
  { title: "Purchase Billing", icon: "fa-wallet", src: "/purchase-billing", isActive: activeIndex === '/purchase-billing' ? true : false },
  { title: "Purchase Return", icon: "fa-boxes-packing", src: "/purchase-return", isActive: activeIndex === '/purchase-return' ? true : false },
  { title: "Vendor list", icon: "fa-user", src: "/vendor-list", isActive: activeIndex === '/vendor-list' ? true : false },
  { title: "Purchase Invoice", icon: "fa-chart-simple", src: "/purchase-invoice", isActive: activeIndex === '/purhcase-invoice' ? true : false },
  { title: "Recently Purchased", icon: "fa-chart-simple", src: "/recently-added", isActive: activeIndex === "/recently-added" ? true : false },
],
  },

  {
    title: "Expense", icon: "fa-regular fa-money-bill-wave",dropDown:[
      // { title: "Add Expense", icon: "fa-regular fa-file-export", src: "/addexpense", isActive: activeIndex === '/addexpense' ? true : false },
      { title: "Expense List", icon: "fa-list", src: "/expenselist", isActive: activeIndex === '/expenselist' ? true : false },
      // { title: "Add Expense Category", icon: "fa-regular fa-user", src: "/addexpensecategory", isActive: activeIndex === '/addexpensecategory' ? true : false },
      { title: "Expense Category List", icon: "fa-regular fa-tags", src: "/expensecategory", isActive: activeIndex === '/expensecategory' ? true : false },
    ],
  },
  {
    title: "Warehouse", icon: "fa-regular fa-warehouse",dropDown:[
      // { title: "Add Expense", icon: "fa-regular fa-file-export", src: "/addexpense", isActive: activeIndex === '/addexpense' ? true : false },
      { title: "Create Warehouse", icon: "fa-plus", src: "/createwarehouse", isActive: activeIndex === '/createwarehouse' ? true : false },
      { title: "Warehouse List", icon: "fa-list", src: "/warehouselist", isActive: activeIndex === '/warehouselist' ? true : false },
      // { title: "Add Expense Category", icon: "fa-regular fa-user", src: "/addexpensecategory", isActive: activeIndex === '/addexpensecategory' ? true : false },
      // { title: "Expense Category List", icon: "fa-regular fa-tags", src: "/expensecategory", isActive: activeIndex === '/expensecategory' ? true : false },
    ],
  },

  {
    title: "HRM", icon: "fa-regular fa-user-tie",dropDown:[
      { title: "Employee List", icon: "fa-users", src: "/employee", isActive: activeIndex === '/expenselist' ? true : false },
      { title: "Designation List", icon: "fa-regular fa-id-badge", src: "/designation", isActive: activeIndex === '/designation' ? true : false },
      { title: "Department List", icon: "fa-regular fa-building", src: "/department", isActive: activeIndex === '/department' ? true : false },
      { title: "Role List", icon: "fa-solid fa-users-cog", src: "/role", isActive: activeIndex === '/role' ? true : false },
    ],
  },

  // { title: "Inwards", icon: "fa-boxes-packing",dropDown:[
  //     { title: "Defect list", icon: "fa-chart-simple", src: "/defect-list", isActive: activeIndex === '/defect-list' ? true : false },
  //   ],
  // },

  {
    title: "Wholesale", icon: "fa-boxes-packing",dropDown:[
      { title: "Wholesale Billing", icon: "fa-wallet", src: "/whole-sale-billing", isActive: activeIndex === '/whole-sale-billing' ? true : false },
      { title: "Wholesale list", icon: "fa-chart-simple", src: "/wholesale-list", isActive: wholeSalePaths.includes(activeIndex) || isViewWholesalePath(activeIndex) ? true : false },
      { title: "Hold Wholesale Invoice", icon: "fa-chart-simple", src: "/hold-wholesale-invoice-list", isActive: activeIndex === '/hold-wholesale-invoice-list' ? true : false },
      { title: "Wholesaler list", icon: "fa-user", src: "/wholesaller-list", isActive: activeIndex === '/wholesaller-list' ? true : false },
    ],
  },
  {
    title: "Exporter", icon: "fa-regular fa-file-export",dropDown:[
      { title: "Exporter", icon: "fa-regular fa-file-export", src: "/exporter", isActive: activeIndex === '/exporter' ? true : false },
      { title: "Exporter user", icon: "fa-regular fa-user", src: "/exporter-list", isActive: activeIndex === '/exporter-list' ? true : false },
      { title: "Carrier", icon: "fa-user", src: "/carrier-list", isActive: activeIndex === '/carrier-list' ? true : false },
      { title: "Exporter Product", icon: "fa-regular fa-file-export", src: "/exporter-product-list", isActive: activeIndex === '/exporter-product-list' ? true : false },
      { title: "Exporter Stock", icon: "fa-regular fa-boxes-stacked", src: "/exporter-stock", isActive: activeIndex === '/exporter-stock' ? true : false }
    ],
  },



{ title: "Finance", icon: "fa-boxes-packing",dropDown:[
  { title: "Chart of Account", icon: "fa-user", src: "/chart-of-account", isActive: activeIndex === '/chart-of-account' ? true : false },
  { title: "Party list", icon: "fa-user", src: "/expense-category", isActive: activeIndex === '/expense-category' ? true : false },
  // { title: "Party name list", icon: "fa-user", src: "/party-name-list", isActive: activeIndex === '/party-name-list' ? true : false },
  { title: "Fund Transfer", icon: "fa-user", src: "/fund-transfer", isActive: activeIndex === '/fund-transfer' ? true : false },
  { title: "Journal list", icon: "fa-user", src: "/journal-list", isActive: activeIndex === '/journal-list' ? true : false },
  { title: "Party history", icon: "fa-user", src: "/expense-list", isActive: activeIndex === '/expense-list' ? true : false },
 ],
 },
    { title: "Analytics", icon: "fa-arrows-left-right-to-line", src: "/analytics", isActive: activeIndex === '/analytics' ? true : false },
    { title: "Settings", icon: "fa-gear", src: "/settings/shop-settings", gap: true, isActive: settingPaths.includes(activeIndex) ? true : false },
    { title: "Profile", icon: "fa-user", src: "/settings/shop-settings", isActive: activeIndex === '/settings/shop-settings' ? true : false },
    { title: "Sign Out", icon: "fa-right-to-bracket", isActive: activeIndex === 'signout' ? true : false }
  ];
 const MenuCollapsed = [
  { title: "Dashboard", icon: "fa-house-chimney", src: "/dashboard", isActive: activeIndex === '/dashboard' ? true : false },
    { title: "Add Product", icon: "fa-chart-simple", src: "/addproducts", isActive: activeIndex === "/addproducts" ? true : false },
    { title: "Product List", icon: "fa-chart-simple", src: "/products", isActive: activeIndex === "/products" ? true : false },
    { title: "Recently Purchased", icon: "fa-chart-simple", src: "/recently-added", isActive: activeIndex === "/recently-added" ? true : false },
  { title: "Sale Billing", icon: "fa-wallet", src: "/billing",  isActive: activeIndex === '/billing' ? true : false },
  { title: "Sale Return", icon: "fa-boxes-packing", src: "/sales-return", isActive: activeIndex === '/sales-return' ? true : false },
  { title: "Advance Booking", icon: "fa-wallet", src: "/advance-booking", isActive: activeIndex === '/advance-booking' ? true : false },
  { title: "Customer list", icon: "fa-boxes-packing", src: "/customer-list", isActive: activeIndex === '/customer-list' ? true : false },
  { title: "Booking list", icon: "fa-chart-simple", src: "/advance-bookings", isActive: advanceBookingsPaths.includes(activeIndex) || isViewAdvanceBookingPath(activeIndex) ? true : false },
  { title: "Sale Invoice", icon: "fa-chart-simple", src: "/invoice", isActive: activeIndex === '/invoice' ? true : false },
  { title: "Hold Invoice list", icon: "fa-chart-simple", src: "/hold-invoice-list", isActive: activeIndex === '/hold-invoice-list' ? true : false },
{ title: "Purchase Billing", icon: "fa-wallet", src: "/purchase-billing", isActive: activeIndex === '/purchase-billing' ? true : false },
{ title: "Purchase Return", icon: "fa-boxes-packing", src: "/purchase-return", isActive: activeIndex === '/purchase-return' ? true : false },
{ title: "Vendor list", icon: "fa-user", src: "/vendor-list", isActive: activeIndex === '/vendor-list' ? true : false },
{ title: "Purchase Invoice", icon: "fa-chart-simple", src: "/purchase-invoice", isActive: activeIndex === '/purhcase-invoice' ? true : false },
//     { title: "Defect list", icon: "fa-chart-simple", src: "/defect-list", isActive: activeIndex === '/defect-list' ? true : false },
   { title: "Wholesale Billing", icon: "fa-wallet", src: "/whole-sale-billing", isActive: activeIndex === '/whole-sale-billing' ? true : false },
   { title: "Wholesale list", icon: "fa-chart-simple", src: "/wholesale-list", isActive: wholeSalePaths.includes(activeIndex) || isViewWholesalePath(activeIndex) ? true : false },
   { title: "Hold Wholesale Invoice", icon: "fa-chart-simple", src: "/hold-wholesale-invoice-list", isActive: activeIndex === '/hold-wholesale-invoice-list' ? true : false },
   { title: "Wholesaler list", icon: "fa-user", src: "/wholesaller-list", isActive: activeIndex === '/wholesaller-list' ? true : false },
  { title: "Exporter", icon: "fa-regular fa-file-export", src: "/exporter", isActive: activeIndex === '/exporter' ? true : false },
  { title: "Exporter user", icon: "fa-regular fa-user", src: "/exporter-list", isActive: activeIndex === '/exporter-list' ? true : false },
  { title: "Carrier", icon: "fa-user", src: "/carrier-list", isActive: activeIndex === '/carrier-list' ? true : false },

   { title: "Chart of Account", icon: "fa-user", src: "/chart-of-account", isActive: activeIndex === '/chart-of-account' ? true : false },
   { title: "Journal list", icon: "fa-user", src: "/journal-list", isActive: activeIndex === '/journal-list' ? true : false },
   { title: "Analytics", icon: "fa-arrows-left-right-to-line", src: "/analytics", isActive: activeIndex === '/analytics' ? true : false },
  { title: "Settings", icon: "fa-gear", src: "/settings/shop-settings", gap: true, isActive: settingPaths.includes(activeIndex) ? true : false },
  { title: "Profile", icon: "fa-user", src: "/settings/shop-settings", isActive: activeIndex === '/settings/shop-settings' ? true : false },
  { title: "Sign Out", icon: "fa-right-to-bracket", isActive: activeIndex === 'signout' ? true : false }
];

  const [Menus, setMenus] = useState(Menu);

useEffect(()=>{
  if(open){
    setMenus(Menu)
  }
  else{
    setMenus(MenuCollapsed)
  }
},[open])
  const navigate = useNavigate();

  const SignUps = () => {
    localStorage.removeItem('credentials');
    navigate('/signup');

  };

  const {
    state,
    updateActiveMenu
} = useContext(AppContext);

const {activeMenu} = state;
  return (
    // <div className={`sidebar relative text-center shrink ${open ? " p-10" : "w-5 p-5 "}`}>
    <div className={`sidebar relative text-center flex-shrink-0 pt-2 ${open ? " px-2" : "w-[100px] "}`}>

     <div className={`w-[52px] h-[52px] top-[100px] transform duration-300 leftRight absolute z-10 cursor-pointer right-0 translate-x-[50%]  flex justify-center items-center bg-[#F8F9FA] rounded-full`}>
   {/* add shadow later
   shadow-[0_0_5px_rgba(0,0,0,0.1)]
    */}
     <div
        className={`absolute  z-10  transform duration-300 w-10 h-10 bg-white rounded-full`}
        // className={`leftRight absolute z-10 cursor-pointer -right-5 top-[104px] overflow-hidden shadow-[0_0_5px_rgba(0,0,0,0.1)]  transform duration-300 ${!open && "w-7 h-7"}  w-10 h-10 bg-white rounded-full`}
        onClick={() => setOpen(!open)}>
  <div className='moon -rotate-[128deg] w-[36px] h-[26px] absolute top-[-18px] z-[-20] right-[2px]'>

   </div>
        <div className='w-full h-full flex justify-center items-center shadow-[inset_0_0_5px_rgba(0,0,0,0.1)] bg-white  rounded-full'>
        <img src={arrow} className={`${!open && "rotate-180"}`} alt='set-open' />
        </div>
        <div className='moon-2 -rotate-[52deg] w-[36px] h-[26px] absolute top-[32px] z-[-20] right-[2px]'>
       </div>
      </div>
     </div>

      <div className="top">
        <Link className='logo' to='/dashboard' >
          <img className={`w-[90%] mx-auto ${!open && "absolute logoImg"}`} src={`${open ? BASE_URL + "/layoutlogo.svg" : BASE_URL + "/smallLogo.png"}`} alt='logo' />
        </Link>
      </div>

      {/* <div className={`center ${!open && "absolute left-1 top-20 menuCollapse"}`}> */}
      <div className={`center pt-8 pb-7 ${!open && "menuCollapse"}`}>
        <ul>
          {open? Menus.map((Menu, index) => (
            <NavItem
            key={index}
            index={index}
            open={open}
            Menu={Menu}
            onLogout={onLogout}
           />
          )): MenuCollapsed.map((Menu, index)=>(
            <NavItem
            key={index}
            index={index}
            open={open}
            Menu={Menu}
            onLogout={onLogout}
           />
          ))}
        </ul>

      </div>

      {/* <div className={`middle ${!open && "hidden"}`}>
        <div className='middle_card'>
          <div className='needicon'>
            <img src={`${BASE_URL}/needicon.svg`} alt='icon' />
          </div>
          <div>
            <h1 className='card_text'>Need help?</h1>
            <p>Please check our docs</p>
            <button className={`card_button ${!open ? " text-sm mr-3 " : "pl-10 pr-10 text-[12px]"}`}>DOCUMENTATION</button>
          </div>

        </div>
      </div> */}

      {/* <div className="center">
                <ul>
                    <li>
                        <i className="fi fi-rr-interrogation icon"></i>
                        <span>Help</span>
                    </li>

                    <li>
                        <i className="fi fi-rr-exit icon"></i>
                        <span>Logout</span>
                    </li>
                </ul>
            </div> */}
    </div>
  );
}



export default Sidebar;

{/* <Link
to={`${nestedMenu.src}`}
 key={nestedMenu.title}
 className='block px-[0px] text-[#202530] !hover:bg-white xl:px-[20px] parentLink'
 style={{ textAlign: 'center', marginLeft: 'auto' }}
 onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
  <li
    key={index}
    // remove position sticky after adding space and line between menu items and logo top.
    className={`!flex justify-center sticky z-10 items-center gap-[12px] ${!open && 'mt-4'} ${index === 0 && 'bg-light-white'
      } ${nestedMenu.isActive && 'active'}`} 
  >
    <i className={`fa-solid ${nestedMenu.icon} icon w-[30px] h-[30px] flex items-center justify-center`}  onClick={toggleDropdown}></i>
    <a
      href={nestedMenu.src || ""}
      className={`flex-1 ${!open && "hidden"} duration-200`}  onClick={toggleDropdown}
    >
 <span className={`flex justify-between items-center `}>
 {nestedMenu.title}
 </span>
    </a>
  </li>
</Link> */}