import { useEffect, useState } from "react";
import SubNav from "../../SubNav/SubNav";
import axios from "axios";
import { get, post } from "../../../services/apiService";
import SelectBox from '../../../forms/Select';
import {
  uploadFile,
  uploadFileMultipleInput,
  uploadFileMultipleInputVariants,
  validateData
} from '../../../utils/Common';
import { useParams, useNavigate } from 'react-router-dom';
import { useFlashMessage } from '../../../context/FlashMessageContext';
import ProductEditForm from '../../../forms/ProductEditForm';
import Resizer from "react-image-file-resizer";
import { set } from 'react-hook-form';
import crose from "../../../../src/assets/images/crose.svg";
import ImageUploader2 from "../../../forms/ImageUploader2";

const AddProducts = () => {
  const { id } = useParams(); // Get the id parameter from the URL
  const isEditMode = !!id; // Check if in edit mode
  const navigate = useNavigate();
  const { getFlashMessageStyle, setSuccessFlashMessage,flashMessage,setErrorFlashMessage } = useFlashMessage(); // Retrieve the dispatch function from your state management library

  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [filteredSubCategory, setFilteredSubCategory] = useState([]);

  const [Units, setUnits] = useState([]);
  const [brands, setBrands] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [warningMessage, setWarningMessage] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [file,setFile] = useState(null);
  const [categoryId, setCategoryId] = useState(0);
  const [subCategoryId, setSubCategoryId] = useState(0);
  //const [Units, setUnitsId] = useState([]);
  // const [brands, setBrands] = useState([]);
  // const [currency, setCurrency] = useState([]);

  const [inputErors, setInputErrors] = useState({});
  const resetFormError = () => {
    setInputErrors({
      name: "",
      categoryId: 0
    });
  };
  const validateForm = (inputName = "Brand") => {
    let valid = true;
    const newInputErrors = { ...inputErors };
    if (validateData(name, inputName)) {
      newInputErrors.name = validateData(name, inputName);
      valid = false;
    }
    if (inputName === "Sub Category") {
      if (categoryId === "" || categoryId === 0) {
        newInputErrors.categoryId = `Category is required`;
        valid = false;
      }
    }

    setInputErrors(newInputErrors);
    return valid;
  };

  const handleSelectChange = (name, option) => {

    const { value, label } = option;
    const selectedValue = value;

    if (name === 'category_id') {
        setCategoryId(selectedValue);
        setInputErrors({ ...inputErors, categoryId: '' });
    } else {
        setCategoryId('');
        setInputErrors({ ...inputErors, categoryId: '' });
    }
    // Check if the selected value is the default (disabled) option
    if (selectedValue === '') {
        //setError('Please select a '+name+'.');
        setCategoryId('');
    } else {
        //setError('');
    }
  };


  const [selectedImage, setSelectedImage] = useState(null);

  const token = localStorage.getItem("token");
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
    setLoading(loading);
  };
  const [isValid, setIsValid] = useState(true);

  const discountType = ["Fixed", "Percentage"];

  const [formData, setFormData] = useState({
    name: "",
    category_id: 0,
    regular_price: 0,
    sub_category_id: 0,
    brand_id: 0,
    unit_id: 0,
    currency_id: 27,
    image_path: null, // ?
    image_path1: null, // ?
    image_path2: null, // ?
    purchase_price: 0.0,
    wholesale_price: 0.0,
    retails_price: 0.0,
    discount_type: 0,
    discount: 0.0,
    description: "",
    warrenty: "",
    warranties_count: 0,
    quantity: "",
    others: "",
    code: "",
    expiry_date: "",
    manufactory_date: "",
    serial:'',
    have_variant:false
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch product data based on the ID and populate the form fields
        if (isEditMode) {
          await fetchProductData(id);
        }

        // Fetch other data (category, subcategory, brand, currency, units)
        const categoryPromise = getCategory();
        //const subCategoryPromise = getSubCategory();
        const brandPromise = getBrand();
        //const currencyPromise = getCurrency();
        const unitsPromise = getUnits();

        // Wait for all promises to resolve
        await Promise.all([
          categoryPromise,
          //subCategoryPromise,
          brandPromise,
          //currencyPromise,
          unitsPromise,
        ]);

        // Rest of your code after all data is fetched
      } catch (error) {
        // Handle errors if needed
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [isEditMode, id]);



  const [selectedCategory, setSelectedCategory] = useState('');
  useEffect(() => {
   if(formData.category_id){
    //getSubCategory();
   }
  }, [formData.category_id])

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
  });

  const fetchProductData = (productId) => {
    // Make an API request to fetch product data by ID
    // Once data is retrieved, update the form fields with the data
    get(`product-details/${productId}`, token)
      .then((response) => {
        const productData = response.data; // Replace with your API response data
        setFormData({
          productId: productId,
          serial: productData.serial,
          name: productData.name,
          category_id: productData.category_id,
          regular_price: productData.regular_price,
          sub_category_id: productData.sub_category_id,
          brand_id: productData.brand_id,
          unit_id: productData.unit_id,
          currency_id: productData.currency_id ? productData.currency_id : 27,
          image_path: productData.image_path,
          image_path1: productData.image_path1,
          image_path2: productData.image_path2,
          purchase_price: productData.purchase_price,
          wholesale_price: productData.wholesale_price,
          retails_price: productData.retails_price,
          discount_type: productData.discount_type,
          discount: productData.discount,
          description: productData.description,
          warrenty: productData.warrenty,
          warranties_count: productData.warranties_count,
          quantity: productData.quantity,
          have_variant: productData?.have_variant && productData?.have_variant !='' ? true : false,
          // warranties_count: productData?.warranties_count ? productData.warranties_count : 0,
        });
        if (productData.category_id !='') {
          setSelectedCategory(productData.category_id);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
      });
  };

  function handleSubmitCategory(e) {
    e.preventDefault();
    if (validateForm("Category")) {
      loadingCallback(true);
      axios
      .post(
          `${process.env.REACT_APP_API_URL}save-category`,
          { name: e.target.name.value, description: e.target.description.value },
          {
              headers: {
                  authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
              },
          }
      )
      .then((response) => {
          loadingCallback(false);
          //console.log(response.data.success);
          if(response.data.success)
          {
              setName("");
              setDescription("");
              //navigate('/settings/category');
              setShowModalCategory(false);
              // window.location.reload();
              getCategory();
          }
          else
          {
              setName("");
              setDescription("");
          }
      })
      .catch((error) => {
          console.log(error);
          loadingCallback(false);
          if (error?.response?.status === 401) {
              localStorage.clear();
              localStorage.setItem('token', false);
              // Clear session and local storage
              localStorage.setItem('isPinVerify', false);

              navigate('/login');
          }
      });
    }
  }

  function handleSubmitSubcategory(e) {
        e.preventDefault();
        // Validate the category selection
        // if (categoryId === '') {
        //     setErrorFlashMessage('Please select a category.');
        //     //setError('Product update failed');
        //     return; // Do not proceed with the form submission if there's an error
        // }
        if (validateForm("Sub Category")) {
          loadingCallback(true);
          axios
          .post(
              `${process.env.REACT_APP_API_URL}save-sub-category`,
              { category_id: categoryId, name: name, description: description },
              {
                  headers: {
                      authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                  },
              }
          )
          .then((response) => {
              //console.log(response.data.success);
              loadingCallback(false);
              if(response.data.success)
              {
                  setName("");
                  setDescription("");
                  setShowModalSubCategory(false);
                  // window.location.reload();
                  //navigate('/settings/category');
                  //getSubCategory();
                  getCategory();
              }
              else
              {
                  setName("");
                  setDescription("");
              }
          })
          .catch((error) => {
              console.log(error);
              loadingCallback(false);
              if (error?.response?.status === 401) {
                localStorage.clear();
                localStorage.setItem('token', false);
                // Clear session and local storage
                localStorage.setItem('isPinVerify', false);

                navigate('/login');
              }
          });
        }

    }
  const [uploadedFiles, setUploadedFiles] = useState({});
  function handleSubmitBrand(e) {
    e.preventDefault();
    if (validateForm("Brand")) {
      loadingCallback(true);
      uploadFile(
          uploadedFiles,
          (brandLogo) => {
              // Success callback
              brandDataSave(brandLogo);

          },
          (error) => {
              // Error callback
              console.log(error);
              loadingCallback(false);
          },
          (progressDetails) => {
              // Progress callback, update uploadDetails here
              setUploadDetails(progressDetails);
          }
      );
    } else {
      console.log('Form validation failed.');
    }
  }

  const brandDataSave = (brandLogo) => {
        axios
        .post(
            `${process.env.REACT_APP_API_URL}save-brand`,
            { image_path: brandLogo.length > 0 ? brandLogo[0] : '', name: name, description: description },
            {
                headers: {
                    authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        )
        .then((response) => {
            //console.log(response.data.success);
            loadingCallback(false);
            if(response.data.success)
            {
                setName("");
                setDescription("");
                setFile("");
                setShowModalBrand(false);
                // window.location.reload();
                //navigate('/settings/category');
                getBrand();
            }
            else
            {
                loadingCallback(false);
                setName("");
                setDescription("");
                setFile("");
            }
        })
        .catch((error) => {
            console.log(error);
            loadingCallback(false);
            if (error?.response?.status === 401) {
              localStorage.clear();
              localStorage.setItem('token', false);
              // Clear session and local storage
              localStorage.setItem('isPinVerify', false);

              navigate('/login');
            }
        });
    }
  const handleShowModalCategory = () => {
    setShowModalCategory(!showModalCategory);
    if (showModalCategory) { // Check if modal is being opened
      resetFormError();
    }
  };
  const handleShowModalSubCategory = () => {
    setShowModalSubCategory(!showModalSubCategory)
    if (showModalSubCategory) { // Check if modal is being opened
      resetFormError();
    }
  };
  const handleShowModalBrand = () => {
    setShowModalBrand(!showModalBrand)
    if (showModalBrand) { // Check if modal is being opened
      resetFormError();
    }
  };




  const getCategory = () => {
    loadingCallback(true)
    //get("category?page=1&limit=10", token)
    get("get-all-category", token)
      .then((response) => {
        loadingCallback(false)
        if (response.success) {
          setCategory(response.data);
        }
      })
      .catch((error) => {
        loadingCallback(false)
        console.log(error);
      });
  };


  const getBrand = () => {
    get("brands?page=1&limit=100", token)
      .then((response) => {
        console.log(response);

        if (response.success) {
          setLoader(false);
          setBrands(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUnits = () => {
    get("units?page=1&limit=200", token)
      .then((response) => {
        console.log(response.data.data);

        if (response.success) {
          setLoader(false);
          setUnits(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCurrency = () => {
    get("currency", token)
      .then((response) => {
        console.log(response.data);

        if (response.success) {
          setLoader(false);
          setCurrency(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmitProducts = (e) => {
    //e.preventDefault();
    formData.have_variant = formData?.have_variant && formData?.have_variant!='' ? 1 : 0;
    // console.log("============");
    // console.log(formData);
    //if(isValid){
      loadingCallback(true);
      uploadFileMultipleInput(
        [],
        (brandLogo) => {
          const updatedFormData = { ...formData };
          brandLogo.forEach((item) => {
            updatedFormData[item.input_name] = item.path;
          });
          if (
            !Number(updatedFormData.retails_price)
          ) {
            updatedFormData.retails_price = 0;
          }
          if (updatedFormData.retails_price) {
            updatedFormData.regular_price = updatedFormData.retails_price;
          }
          if (
            !Number(updatedFormData.category_id)
          ) {
            updatedFormData.category_id = 0;
          }
          if (
            !Number(updatedFormData.brand_id)
          ) {
            updatedFormData.brand_id = 0;
          }
          if (
            !Number(updatedFormData.sub_category_id)
          ) {
            updatedFormData.sub_category_id = 0;
          }
          if (
            !Number(updatedFormData.unit_id)
          ) {
            updatedFormData.unit_id = 0;
          }
          if (
            !Number(updatedFormData.quantity)
          ) {
            updatedFormData.quantity = 0;
          }
          if (
            !Number(updatedFormData.discount)
          ) {
            updatedFormData.discount = 0;
          }
          if (
            !Number(updatedFormData.wholesale_price)
          ) {
            updatedFormData.wholesale_price = 0;
          }
          if (
            !Number(updatedFormData.purchase_price)
          ) {
            updatedFormData.purchase_price = 0;
          }

          if (updatedFormData.discount_type == 'Select Discount Type') {
            updatedFormData.discount_type = '';
          }
          if (isEditMode) {
            // Edit mode: Send a PUT request to update the existing product
            updateProduct(updatedFormData);
          } else {
            // Add mode: Send a POST request to create a new product
            createProduct(updatedFormData);
          }
          //productDataSave(updatedFormData);
        },
        (error) => {
          // Error callback
          console.log(error);
          loadingCallback(false);
        },
        (progressDetails) => {
          // Progress callback, update uploadDetails here
          setUploadDetails(progressDetails);
        }
      );
    // } else {
    //   setWarningMessage(true);
    //   //setErrorFlashMessage('Please fill all the required fields');
    //   const timer = setTimeout(() => {
    //     setWarningMessage(false);
    //   }, 3000);
    //   return () => clearTimeout(timer);
    // }



  };

  const createProduct = (formData) => {
      // setFlashMessage('Product saved successfully');
      // navigate('/products');
      // Use your API service functions to make API requests here
      //if(isValid){
        console.log("Form Data",formData);
        post("save-product", formData, token)
          .then((response) => {
            loadingCallback(false);
            if (response.success) {
              setSuccessFlashMessage('Product saved successfully');
              navigate('/products');
            } else {
              console.log("Product insertion failed");

                setWarningMessage(true);
                const timer = setTimeout(() => {
                  setWarningMessage(false);
                }, 3000);
                return () => clearTimeout(timer);
            }
          })
          .catch((error) => {
            loadingCallback(false);
            if (error?.response?.status === 401) {
              localStorage.clear();
              localStorage.setItem('token', false);
              // Clear session and local storage
              localStorage.setItem('isPinVerify', false);

              navigate('/login');
            }
            console.log(error);
          });
      //}
    }
  const updateProduct = (formData) => {
    // Use your API service functions to make API requests here
    console.log("============");
    console.log(isValid);
    //if(isValid){
      post("update-product", formData, token)
        .then((response) => {
          loadingCallback(false);
          if (response.success) {
            setSuccessFlashMessage('Product updated successfully');
            navigate('/products')
          } else {
            console.log("Product update failed");
            setErrorFlashMessage('Product update failed');

              setWarningMessage(true);
              const timer = setTimeout(() => {
                setWarningMessage(false);
              }, 3000);
              return () => clearTimeout(timer);
          }
        })
        .catch((error) => {
          loadingCallback(false);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem('token', false);
            // Clear session and local storage
            localStorage.setItem('isPinVerify', false);

            navigate('/login');
          }
          console.log(error);
        });
    //}
  }

  // Function to handle changes in the input fields

  // date
  const handleDate = (data)=>{
    console.log(data)
  }
  //date

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(formData);
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === 'category_id') {
      // If the selected category changes, filter and update the subcategories
      if (value != 'Select Category' && formData.category_id != value) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          sub_category_id: '', // Reset subcategory when category changes
        }));
        const filteredSubcategories = subCategory.filter(
          (subCat) => subCat.category_id === Number(value)
        );
        setFilteredSubCategory(filteredSubcategories);
      }
      if (value == 'Select Category') {
        setFormData((prevFormData) => ({
          ...prevFormData,
          sub_category_id: '', // Reset subcategory when category changes
        }));

      }

    }
  };

  // Function to handle changes in the input fields
  const handleChangeSelect = (name, option) => {

   const { value, label } = option;

    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === 'category_id') {
      // If the selected category changes, filter and update the subcategories
      if (value != 'Select Category') {
        setFormData((prevFormData) => ({
          ...prevFormData,
          sub_category_id: '', // Reset subcategory when category changes
        }));
        setSelectedCategory(value);
        // const filteredSubcategories = subCategory.filter(
        //   (subCat) => subCat.category_id === Number(value)
        // );
        // setFilteredSubCategory(filteredSubcategories);
      }
      if (value == 'Select Category') {
        setFormData((prevFormData) => ({
          ...prevFormData,
          sub_category_id: '', // Reset subcategory when category changes
        }));

      }

    }
  };
  useEffect(() => {
    // Filter sub-categories based on the selected category
    const selectedCategoryObject = category.find(
      (category) => category.id === selectedCategory
    );

    if (selectedCategoryObject) {
      setFilteredSubCategory(selectedCategoryObject.sub_category || []);
    } else {
      setFilteredSubCategory([]);
    }
  }, [selectedCategory, category]);
  const [uploadDetails, setUploadDetails] = useState([]);

  const base64ToBlob = (base64String, type) => {
  const byteCharacters = window.atob(base64String);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type });
  return blob;
};



  const handleImageChange = async (files, name, id=null) => {
    let tempUploadDetails = uploadDetails;
    const allowedImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    for (let i = 0; i < files.length; i++) {

      // Validate file type
      if (!allowedImageTypes.includes(files[i].type)) {
        setErrorFlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        console.error(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        // Handle invalid file type (display a message, set an error state, etc.)
        setIsValid(false);
        continue; // Skip the current iteration for invalid file types
      }

      let size = files[i].size / 1024;
      let sizeName = '';

      if (size > 1024) {
        size = (size / 1024).toFixed(2);
        sizeName = size + 'MB';
      } else {
        sizeName = size.toFixed(2) + 'KB';
      }
      const resizedImage = await resizeFile(files[i]);
      // Extract base64 data
      const base64Data = resizedImage.split(',')[1];

      // Convert base64 to Blob
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" }); // Adjust the MIME type accordingly

      // Create a File from Blob
      const file = new File([blob],files[i].name, { type: "image/png" }); // Adjust the file name and MIME type accordingly

      const existingIndex = tempUploadDetails.findIndex(
          (variant) => variant.input_name === name
        );
        if (existingIndex !== -1) {
        loadingCallback(true);
          // If a variant with the same name exists, update it
          tempUploadDetails[existingIndex] = {
            name: files[i].name,
            size: sizeName,
            percentage: 0,
            file: file,
            input_name: name,
            image_path: URL.createObjectURL(files[i]),
          };
          const brandLogo = await uploadFileMultipleInputVariants(
            tempUploadDetails,
            name
          );
          if (brandLogo) {
            setFormData({
              ...formData,
              [name]: brandLogo.path,
            });
            loadingCallback(false);
          }
        } else {
          loadingCallback(true);
          tempUploadDetails.push({
            name: files[i].name,
            size: sizeName,
            percentage: 0,
            file: file,
            input_name: name,
            image_path: URL.createObjectURL(files[i]),
          });

          const brandLogo = await uploadFileMultipleInputVariants(
            tempUploadDetails,
            name
          );
          // console.log("============");
          // console.log(brandLogo);

          if (brandLogo) {
            loadingCallback(false);
            setFormData({
              ...formData,
              [name]: brandLogo.path,
            });
          }
        }
    }


    setUploadDetails(tempUploadDetails);
  };
  const handleImageChangeBrand = async (files, name) => {
    let tempUploadDetails = [...uploadDetails];
    const allowedImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    for (let i = 0; i < files.length; i++) {

      // Validate file type
      if (!allowedImageTypes.includes(files[i].type)) {
        setErrorFlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        console.error(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        // Handle invalid file type (display a message, set an error state, etc.)
        setIsValid(false);
        continue; // Skip the current iteration for invalid file types
      }

      let size = files[i].size / 1024;
      let sizeName = '';

      if (size > 1024) {
        size = (size / 1024).toFixed(2);
        sizeName = size + 'MB';
      } else {
        sizeName = size.toFixed(2) + 'KB';
      }
      const resizedImage = await resizeFile(files[i]);
      // Extract base64 data
      const base64Data = resizedImage.split(',')[1];

      // Convert base64 to Blob
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" }); // Adjust the MIME type accordingly

      // Create a File from Blob
      const file = new File([blob],files[i].name, { type: "image/png" }); // Adjust the file name and MIME type accordingly

      tempUploadDetails.push({
        name: files[i].name,
        size: sizeName,
        percentage: 0,
        file: file,
        input_name: name,
      });
    }
    //console.log("============");
    //console.log(tempUploadDetails);
    setFile(URL.createObjectURL(files[0]));
    setUploadedFiles(files);
    setUploadDetails(tempUploadDetails);
  };
  const [showModalCategory, setShowModalCategory] = useState(false);
  const [showModalSubCategory, setShowModalSubCategory] = useState(false);
  const [showModalBrand, setShowModalBrand] = useState(false);

  // image
  // const [file, setFile] = useState();

  return (
    <div>
      <SubNav
        pageName={isEditMode ? 'Product Details' : 'Add New Product'}
        Breadcrumb="Products"
        page="/Products"
      ></SubNav>
      {warningMessage && (
        <div className="warning-message">
          something wrong!
        </div>
      )}
      {flashMessage && (
        <div className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(flashMessage.type)}`}>
          {flashMessage.text}
        </div>
      )}

      {loading && (

        <div className="custom-loader-logo">
          {/* <BeatLoader color={"#123abc"} size={20} /> */}
          <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
        </div>
      )}
      <ProductEditForm
        formData={formData}
        handleChange={handleChange}
        handleChangeSelect={handleChangeSelect}
        handleSubmitProducts={handleSubmitProducts}
        category={category}
        subCategory={subCategory}
        filteredSubCategory={filteredSubCategory}
        Units={Units}
        brands={brands}
        handleDate={handleDate}
        //currency={currency}
        discountType={discountType}
        handleImageChange={handleImageChange}
        loading={loading}
        loader={loader}
        isEditMode={isEditMode}
        setErrorFlashMessage={setErrorFlashMessage}
        setIsValid={setIsValid}
        isValid={isValid}
        uploadDetails={uploadDetails}
        openCategoryModal={handleShowModalCategory}
        openSubCategoryModal={handleShowModalSubCategory}
        openBrandModal={handleShowModalBrand}
      />

      {/* Modal One  */}
      {showModalCategory ? (
        <>
          <div>
            {/* The button to open modal */}
            {/* Put this part before </body> tag */}
            <input
              type="checkbox"
              id="my_modal_category"
              className="modal-toggle"
            />
            <div className="modal">
              <div className="modal-box w-[390px] p-0 bg-white">
                <div className="flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]">
                  <h4 className="font-nunito font-semibold text-lg ">
                    Add New Category
                  </h4>
                  <div className="modal-action bg-red-600 text-white rounded-md">
                    <label
                      htmlFor="my_modal_category"
                      className="cursor-pointer"
                      onClick={handleShowModalCategory}
                    >
                      <img src={crose} width={23} height={20} alt="" />
                    </label>
                  </div>
                </div>
                <div className='px-5 pt-5 pb-[22px]'>
                  <form
                      method="get"
                      className="col-sm-12"
                      data-autosubmit="false"
                      autoComplete="off"
                      onSubmit={handleSubmitCategory}
                  >
                      <input
                          type='text'
                          name="name"
                          onChange={(e) => {
                              const val = e.target.value;
                              setName(val);
                              setInputErrors({ ...inputErors, name: null });
                          }}
                          placeholder='Category Name'
                          className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
                      />
                      {inputErors.name
                      ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                      : null}
                      <textarea
                          name="description"
                          onChange={(e) => {
                              const val = e.target.value;
                              setDescription(val);
                          }}
                          className='textarea textarea-bordered w-full mb-[15px] resize-none rounded-2xl bg-white'
                          placeholder='Category Description...'
                      ></textarea>
                      <div className="button-disabled">
                          <button
                              htmlFor='my_modal_category'
                              type="submit"
                              className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-nunito font-semibold text-lg"
                          >
                              {""}
                              Save{""}
                          </button>
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}

      {showModalSubCategory ? (
        <>
          <div className=''>
            {/* The button to open modal */}

            {/* Put this part before </body> tag */}
            <input type='checkbox' id='my_modal_sub_category' className='modal-toggle' />

            <div className='modal'>
                <div className='modal-box w-[390px] p-0 bg-white'>
                    <div className='flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]'>
                        <h4 className='font-nunito font-semibold text-lg '>
                            Add New Subcategory
                        </h4>
                        <div className='modal-action'>
                            <label htmlFor='my_modal_6' className='cursor-pointer bg-red-600 text-white rounded-md' onClick={handleShowModalSubCategory}>
                                <img src={crose} width={23} height={20} alt="" />
                            </label>
                        </div>
                    </div>
                    <div className='px-5 pt-5 pb-[22px]'>
                    <form
                        method="get"
                        className="col-sm-12"
                        data-autosubmit="false"
                        autoComplete="off"
                        onSubmit={handleSubmitSubcategory}
                    >
                        <SelectBox
                          name="category_id"
                          options={category}
                          value={categoryId}
                          //onChange={(e) => handleSelectChange('category', 'Category', e)}
                          onChange={handleSelectChange.bind(this, "category_id")}
                          //style={{ maxHeight: '300px', overflowY: 'scroll' }}
                          // error={formErrors.category_id}
                          // setFormErrors={setFormErrors}
                          placeholder="Select Category"
                          className="px-[0] select w-full rounded-2xl mb-[15px] bg-white"
                        />
                        {/* <select
                          name="category_id"
                          onChange={e => setCategoryId(e.target.value)}
                          className='select select-bordered w-full rounded-2xl mb-[15px] bg-white'>
                            <option disabled selected>
                                Select Category
                            </option>
                            {category.map((item,index)=><option key={index} value={item.id}>{item.name}</option>) }
                        </select>
                        {categoryId=='' && (
                            <div className="warning-message">
                                This field will not be empty.
                            </div>
                        )} */}
                        {inputErors.categoryId
                        ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.categoryId}</p>
                        : null}
                        <input
                            type='text'
                            name="name"
                            onChange={(e) => {
                                const val = e.target.value;
                                setName(val);
                                setInputErrors({ ...inputErors, name: null });
                            }}
                            placeholder='Subcategory Name'
                            className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
                        />
                        {inputErors.name
                        ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                        : null}


                        <textarea
                            name="description"
                            value={description}
                            onChange={(e) => {
                                const val = e.target.value;
                                setDescription(val);
                            }}
                            className='textarea textarea-bordered w-full mb-[15px] resize-none rounded-2xl bg-white'
                            placeholder='Subcategory Description...'
                        ></textarea>
                        <div className="button-disabled">
                            <button
                                htmlFor='my_modal_sub_category'
                                type="submit"
                                className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-nunito font-semibold text-lg"
                            >
                                {""}
                                Save{""}
                            </button>
                        </div>
                      </form>

                    </div>
                </div>
            </div>
          </div>
        </>
      ) : null}




      {showModalBrand ? (
          <>
              <div>
                  {/* The button to open modal */}

                  {/* Put this part before </body> tag */}
                  <input type='checkbox' id='my_modal_brand' className='modal-toggle' />

                  <div className='modal'>
                      <div className='modal-box w-[390px] p-0 bg-white'>
                          <div className='flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]'>
                              <h4 className='font-nunito font-semibold text-lg '>Add New Brand</h4>
                              <div className='modal-action'>
                                  <label htmlFor='my_modal_brand' className='cursor-pointer bg-red-600 text-white rounded-md' onClick={handleShowModalBrand}>
                                    <img src={crose} width={23} height={20} alt="" />
                                  </label>
                              </div>
                          </div>
                          <div className='px-5 pt-5 pb-[22px]'>
                          <form
                              method="post"
                              className="col-sm-12"
                              data-autosubmit="false"
                              autoComplete="off"
                              onSubmit={handleSubmitBrand}
                              encType="multipart/form-data"
                          >
                              <input
                                  type='text'
                                  name="name"
                                  value={name}
                                  onChange={(e) => {
                                      const val = e.target.value;
                                      setName(val);
                                      setInputErrors({ ...inputErors, name: null });
                                  }}
                                  placeholder='Brand Name'
                                  className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
                              />
                              {inputErors.name
                              ? <p className="error-message text-red-500 mb-3 pl-1">{inputErors.name}</p>
                              : null}
                                {/* brand image*/}
                        <div className="flex justify-between flex-col border font-normal placeholder:font-normal border-opacity-[0.2px] w-full mb-[15px] rounded-2xl">
                      <h4 className="pl-4 py-3">Brand's Logo</h4>
                      <ImageUploader2
                        name={"file-input"}
                        isBig={true}
                        //onChange={(files) => handleImageChange("image_path", files)}
                        onChange={handleImageChangeBrand}
                        image={file}
                      />
                      {/* <input
                    type="file"
                    onChange={handleChange}
                    id="file-input"
                    name="file-input"
                    className="hidden"

                  />
                  <label
                    className="w-[147px] rounded-r-2xl px-3 py-3 bg-[#F5F8FF]"
                    htmlFor="file-input"
                  >
                    Browse Files
                  </label>
                  <img src={file ? file : image} /> */}
                    </div>
                              <textarea
                                  name="description"
                                  value={description}
                                  onChange={(e) => {
                                      const val = e.target.value;
                                      setDescription(val);
                                  }}
                                  className='textarea textarea-bordered w-full mb-[15px] resize-none rounded-2xl bg-white'
                                  placeholder='Brand Description...'
                              ></textarea>

                              <div className="button-disabled">
                                  <button
                                      htmlFor='my_modal_brand'
                                      type="submit"
                                      className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-nunito font-semibold text-lg"
                                  >
                                      {""}
                                      Save{""}
                                  </button>
                              </div>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </>
      ) : null}
    </div>
  );
};

export default AddProducts;
