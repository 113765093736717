import React, { useContext, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import CommonUploader from "./CommonUploader";
import Input from "./Input";
import DatePicker from "react-datepicker";
import axios from "axios";
import { FlashMessage } from "../utils/FlashMessage";
import CustomCheckbox from "./CustomCheckbox";

const RolePermissionAddForm = (props) => {

  const{
    formData,
    setFormData,
    handleSubmit,
    handleChange,
    handleCheckboxChange,
    initialFormData,
    handleOptionCheckboxChange

  }= props

  return(
    <div className="">
      <ToastContainer />
      <form
      method="post"
      data-autosubmit="false"
      autoComplete="off"
      onSubmit={handleSubmit}
      >
        <div className="w-full pt-5">
          <div className="w-full relative">
            <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
              Name
            </label>
            <input
              type="text"
              name="role_name"
              placeholder="Name"
              value={formData.role_name}
              onChange={handleChange}
              className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
            />
          </div>
          <div className="w-full relative mt-2">
            <label className="absolute font-nunito text-xs text-[#102048]  -top-[8px] left-[12px] bg-white px-1 font-semibold">
              Description
            </label>
            <textarea
              type="text"
              name="role_description"
              value={formData.role_description}
              onChange={handleChange}
              placeholder="Text"
              className="input border-[#C1CFEF] border-[1px] focus:outline-none w-full  h-20 pt-2 rounded-xl bg-transparent resize-none"
            />
          </div>
          <div className="w-full relative mt-5">
            <div className="flex flex-col md:flex-row justify-between">
              {/* First Section */}
              <div className="w-full md:w-1/2 pr-2 mb-4">
                <h3 className="text-[#102048] font-semibold mb-3">Manage Permission</h3>

                <div className="border-[#C1CFEF] border-[1px] rounded-xl p-4 mb-2 bg-white">
                  {/* <div className="grid grid-cols-2 gap-y-2"> */}
                  <div className="grid grid-cols-2 gap-y-4">

                    {formData.features.map((feature, featureIndex) => (
                      <React.Fragment key={feature.name}>
                        {/* Feature Column */}
                        <div className="flex items-center justify-start border-b border-gray-300 pb-2">
                          <CustomCheckbox
                            name={`features[${featureIndex}].status`}
                            checked={feature.status}
                            onChange={(e) => handleCheckboxChange(e, featureIndex)}
                          />
                          <span className="">{feature.name}</span>
                        </div>

                        {/* Feature Options Column */}
                        <div className=" border-b border-gray-300 pb-2 grid grid-cols-3">
                          {feature.feature_options.map((option, optionIndex) => (
                            <div key={option.name} className="flex items-center ">
                              <CustomCheckbox
                                name={`features[${featureIndex}].feature_options[${optionIndex}].status`}
                                checked={option.status}
                                onChange={(e) => handleOptionCheckboxChange(e, featureIndex, optionIndex)}
                              />
                              <span className="">{option.name}</span>
                            </div>
                          ))}
                        </div>
                      </React.Fragment>
                    ))}

                    {/* {formData.features.map((feature, featureIndex) => (
                      <div key={feature.name} className="border-[#C1CFEF] border-[1px] rounded-xl p-4 mb-2 bg-white">
                        <div className="flex justify-between items-center">
                          <CustomCheckbox
                            name={`features[${featureIndex}].status`}
                            checked={feature.status}
                            onChange={(e) => handleCheckboxChange(e, featureIndex)}
                          />
                          <span>{feature.name}</span>
                        </div> */}

                        {/* Feature Options */}
                        {/* <div className="ml-4 mt-2">
                          {feature.feature_options.map((option, optionIndex) => (
                            <div key={option.name} className="flex items-center mb-2">
                              <CustomCheckbox
                                name={`features[${featureIndex}].feature_options[${optionIndex}].status`}
                                checked={option.status}
                                onChange={(e) => handleOptionCheckboxChange(e, featureIndex, optionIndex)}
                              />
                              <span>{option.name}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))} */}
                    {/* Row 1 */}
                    {/* <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                      <CustomCheckbox
                        label="Add Product"
                        name="add_product"
                        // checked={formData.features.some(f => f.name === 'Add Product')}
                        checked={formData.features.some(feature => feature.name === 'Add Product')}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-y-2">
                    <div className="flex justify-end items-center border-b border-gray-300 pb-2"></div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="Create"
                            name="create_product"
                            // checked={formData.features.some(f => f.name === "Add Product" && f.feature_options.some(opt => opt.name === "Create"))}
                            checked={formData.features.some(
                              feature => feature.name === "Add Product" &&
                              feature.feature_options.some(opt => opt.name === "Create" && opt.status)
                            )}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="Update"
                            name="update_product"
                            // checked={formData.features.some(f => f.name === "Add Product" && f.feature_options.some(opt => opt.name === "Update"))}
                            checked={formData.features.some(
                              feature => feature.name === "Add Product" &&
                              feature.feature_options.some(opt => opt.name === "Update" && opt.status)
                            )}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                    </div> */}

                    {/* Row 2 */}
                    {/* <div className="flex justify-left items-center border-b border-gray-300 pb-2">
                      <CustomCheckbox
                        label="Product Purchase Price"
                        name="product_purchase_price"
                        checked={formData.features.some(f => f.name === 'Product Purchase Price')}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-y-2">
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2"></div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                          label="Visible"
                          name="visible_purchase_price"
                          checked={formData.features.some(f => f.name === "Product Purchase Price" && f.feature_options.some(opt => opt.name === "Visible" && opt.status))}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="Hidden"
                            name="hidden_purchase_price"
                            checked={formData.features.some(f => f.name === "Product Purchase Price" && f.feature_options.some(opt => opt.name === "Hidden" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                    </div> */}

                    {/* Row 3 */}
                    {/* <div className="flex justify-left items-center border-b border-gray-300 pb-2">
                      <CustomCheckbox
                        label="Sale Billing"
                        name="sale_billing"
                        checked={formData.features.some(f => f.name === 'Sale Billing')}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-y-2">
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                          label="Add"
                          name="add_sale_billing"
                          checked={formData.features.some(f => f.name === "Sale Billing" && f.feature_options.some(opt => opt.name === "Add" && opt.status))}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="Edit"
                            name="edit_sale_billing"
                            checked={formData.features.some(f => f.name === "Sale Billing" && f.feature_options.some(opt => opt.name === "Edit" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="View"
                            name="view_sale_billing"
                            checked={formData.features.some(f => f.name === "Sale Billing" && f.feature_options.some(opt => opt.name === "View" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                    </div> */}

                    {/* Row 4 */}
                    {/* <div className="flex justify-left items-center border-b border-gray-300 pb-2">
                      <CustomCheckbox
                        label="Sale Return"
                        name="sale_return"
                        checked={formData.features.some(f => f.name === 'Sale Return')}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-y-2">
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                          label="Create"
                          name="create_sale_return"
                          checked={formData.features.some(f => f.name === "Sale Return" && f.feature_options.some(opt => opt.name === "Create" && opt.status))}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="View"
                            name="view_sale_return"
                            checked={formData.features.some(f => f.name === "Sale Return" && f.feature_options.some(opt => opt.name === "View" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="Delete"
                            name="delete_sale_return"
                            checked={formData.features.some(f => f.name === "Sale Return" && f.feature_options.some(opt => opt.name === "Delete" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                    </div> */}

                    {/* Row 5 */}
                    {/* <div className="flex justify-left items-center border-b border-gray-300 pb-2">
                      <CustomCheckbox
                        label="Advanced Booking"
                        name="advanced_booking"
                        checked={formData.features.some(f => f.name === 'Advanced Booking')}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-y-2">
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                          label="Create"
                          name="create_advanced_booking"
                          checked={formData.features.some(f => f.name === "Advanced Booking" && f.feature_options.some(opt => opt.name === "Create" && opt.status))}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="View"
                            name="view_advanced_booking"
                            checked={formData.features.some(f => f.name === "Advanced Booking" && f.feature_options.some(opt => opt.name === "View" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="Delete"
                            name="delete_advanced_booking"
                            checked={formData.features.some(f => f.name === "Advanced Booking" && f.feature_options.some(opt => opt.name === "Delete" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                    </div> */}

                    {/* Row 6 */}
                    {/* <div className="flex justify-left items-center border-b border-gray-300 pb-2">
                      <CustomCheckbox
                        label="Customer"
                        name="customer"
                        checked={formData.features.some(f => f.name === 'Customer')}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-y-2">
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                          label="Add"
                          name="add_customer"
                          checked={formData.features.some(f => f.name === "Customer" && f.feature_options.some(opt => opt.name === "Add" && opt.status))}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="Edit"
                            name="edit_customer"
                            checked={formData.features.some(f => f.name === "Customer" && f.feature_options.some(opt => opt.name === "Edit" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="Delete"
                            name="delete_customer"
                            checked={formData.features.some(f => f.name === "Customer" && f.feature_options.some(opt => opt.name === "Delete" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                    </div> */}

                    {/* Row 7 */}
                    {/* <div className="flex justify-left items-center border-b border-gray-300 pb-2">
                      <CustomCheckbox
                        label="Purchase Billing"
                        name="purchase_billing"
                        checked={formData.features.some(f => f.name === 'Purchase Billing')}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-y-2">
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                          label="Add"
                          name="add_purchase_billing"
                          checked={formData.features.some(f => f.name === "Purchase Billing" && f.feature_options.some(opt => opt.name === "Add" && opt.status))}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="Edit"
                            name="edit_purchase_billing"
                            checked={formData.features.some(f => f.name === "Purchase Billing" && f.feature_options.some(opt => opt.name === "Edit" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="Delete"
                            name="delete_purchase_billing"
                            checked={formData.features.some(f => f.name === "Purchase Billing" && f.feature_options.some(opt => opt.name === "Delete" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                    </div> */}

                    {/* Row 8 */}
                    {/* <div className="flex justify-left items-center border-b border-gray-300 pb-2">
                      <CustomCheckbox
                        label="Purchase Return"
                        name="purchase_return"
                        checked={formData.features.some(f => f.name === 'Purchase Return')}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-y-2">
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                          label="Add"
                          name="add_purchase_return"
                          checked={formData.features.some(f => f.name === "Purchase Return" && f.feature_options.some(opt => opt.name === "Add" && opt.status))}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="Edit"
                            name="edit_purchase_return"
                            checked={formData.features.some(f => f.name === "Purchase Return" && f.feature_options.some(opt => opt.name === "Edit" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="Delete"
                            name="delete_purchase_return"
                            checked={formData.features.some(f => f.name === "Purchase Return" && f.feature_options.some(opt => opt.name === "Delete" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                    </div> */}

                    {/* Row 9 */}
                    {/* <div className="flex justify-left items-center border-b border-gray-300 pb-2">
                      <CustomCheckbox
                        label="Vendor List"
                        name="vendor_list"
                        checked={formData.features.some(f => f.name === 'Vendor List')}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-y-2">
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                          label="Add"
                          name="add_vendor_list"
                          checked={formData.features.some(f => f.name === "Vendor List" && f.feature_options.some(opt => opt.name === "Add" && opt.status))}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="Edit"
                            name="edit_vendor_list"
                            checked={formData.features.some(f => f.name === "Vendor List" && f.feature_options.some(opt => opt.name === "Edit" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="Delete"
                            name="delete_vendor_list"
                            checked={formData.features.some(f => f.name === "Vendor List" && f.feature_options.some(opt => opt.name === "Delete" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                    </div> */}

                    {/* Row 10 */}
                    {/* <div className="flex justify-left items-center border-b border-gray-300 pb-2">
                      <CustomCheckbox
                        label="Expense"
                        name="expense"
                        checked={formData.features.some(f => f.name === 'Expense')}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-y-2">
                    <div className="flex justify-start items-center border-b border-gray-300 pb-2"></div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                          label="Create"
                          name="create_expense"
                          checked={formData.features.some(f => f.name === "Expense" && f.feature_options.some(opt => opt.name === "Create" && opt.status))}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="View"
                            name="view_expense"
                            checked={formData.features.some(f => f.name === "Expense" && f.feature_options.some(opt => opt.name === "View" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                    </div> */}

                    {/* Row 11 */}
                    {/* <div className="flex justify-left items-center border-b border-gray-300 pb-2">
                      <CustomCheckbox
                        label="HRM"
                        name="hrm"
                        checked={formData.features.some(f => f.name === 'HRM')}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-y-2">
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                          label="Create"
                          name="add_hrm"
                          checked={formData.features.some(f => f.name === "HRM" && f.feature_options.some(opt => opt.name === "Create" && opt.status))}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="Edit"
                            name="edit_hrm"
                            checked={formData.features.some(f => f.name === "HRM" && f.feature_options.some(opt => opt.name === "Edit" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="View"
                            name="view_hrm"
                            checked={formData.features.some(f => f.name === "HRM" && f.feature_options.some(opt => opt.name === "View" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                    </div> */}

                    {/* Row 12 */}
                    {/* <div className="flex justify-left items-center border-b border-gray-300 pb-2">
                      <CustomCheckbox
                        label="Wholeseller"
                        name="wholeseller"
                        checked={formData.features.some(f => f.name === 'Wholeseller')}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-y-2">
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                          label="Create"
                          name="add_wholeseller"
                          checked={formData.features.some(f => f.name === "Wholeseller" && f.feature_options.some(opt => opt.name === "Create" && opt.status))}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="Edit"
                            name="edit_wholeseller"
                            checked={formData.features.some(f => f.name === "Wholeseller" && f.feature_options.some(opt => opt.name === "Edit" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="View"
                            name="view_wholeseller"
                            checked={formData.features.some(f => f.name === "Wholeseller" && f.feature_options.some(opt => opt.name === "View" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                    </div> */}

                    {/* Row 13 */}
                    {/* <div className="flex justify-left items-center border-b border-gray-300 pb-2">
                      <CustomCheckbox
                        label="Exporter"
                        name="exporter"
                        checked={formData.features.some(f => f.name === 'Exporter')}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-y-2">
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2"></div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                          label="Visible"
                          name="exporter_visible"
                          checked={formData.features.some(f => f.name === "Exporter" && f.feature_options.some(opt => opt.name === "Visible" && opt.status))}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="Hidden"
                            name="exporter_hidden"
                            checked={formData.features.some(f => f.name === "Exporter" && f.feature_options.some(opt => opt.name === "Hidden" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                    </div> */}

                    {/* Row 14 */}
                    {/* <div className="flex justify-left items-center border-b border-gray-300 pb-2">
                      <CustomCheckbox
                        label="Finance"
                        name="finance"
                        checked={formData.features.some(f => f.name === 'Finance')}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-y-2">
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2"></div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                          label="Create"
                          name="create_finance"
                          checked={formData.features.some(f => f.name === "Finance" && f.feature_options.some(opt => opt.name === "Create" && opt.status))}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="View"
                            name="view_finance"
                            checked={formData.features.some(f => f.name === "Finance" && f.feature_options.some(opt => opt.name === "View" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                    </div> */}

                    {/* Row 15 */}
                    {/* <div className="flex justify-left items-center border-b border-gray-300 pb-2">
                      <CustomCheckbox
                        label="Analytics"
                        name="analytics"
                        checked={formData.features.some(f => f.name === 'Analytics')}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-y-2">
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2"></div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                          label="Visible"
                          name="analytics_visible"
                          checked={formData.features.some(f => f.name === "Analytics" && f.feature_options.some(opt => opt.name === "Visible" && opt.status))}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="Hidden"
                            name="analytics_hidden"
                            checked={formData.features.some(f => f.name === "Analytics" && f.feature_options.some(opt => opt.name === "Hidden" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                    </div> */}

                    {/* Row 16 */}
                    {/* <div className="flex justify-left items-center border-b border-gray-300 pb-2">
                      <CustomCheckbox
                        label="Shop Settings"
                        name="shop_settings"
                        checked={formData.features.some(f => f.name === 'Shop Settings')}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-y-2">
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2"></div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                          label="Visible"
                          name="shop_settings_visible"
                          checked={formData.features.some(f => f.name === "Shop Settings" && f.feature_options.some(opt => opt.name === "Visible" && opt.status))}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="flex justify-start items-center border-b border-gray-300 pb-2">
                        <CustomCheckbox
                            label="Hidden"
                            name="shop_settings_hidden"
                            checked={formData.features.some(f => f.name === "Shop Settings" && f.feature_options.some(opt => opt.name === "Hidden" && opt.status))}
                            onChange={handleCheckboxChange}
                        />
                      </div>
                    </div> */}

                  </div>
                </div>
                <button onClick={() => setFormData(initialFormData)} className="border-[1px] border-solid border-[#407BFF] text-[#407BFF] rounded-xl px-[6px] py-3 w-full mt-2">
                  Discard Changes
                </button>
              </div>

              {/* Second Section */}
              <div className="w-full md:w-1/2 pl-2 mb-4">
                <h3 className="text-[#102048] font-semibold mb-3">Manage Permission</h3>
                <div className="border-[#C1CFEF] border-[1px] rounded-xl p-4 mb-2 " style={{ backgroundColor: '#f5f5f5', height: '813px' }}>
                  {formData.features.length === 0 || formData.features.every(feature => !feature.status && !feature.feature_options.some(option => option.status)) ? (
                      <p>No features selected</p>
                    ) : (
                      formData.features
                        .filter(feature => feature.status)  // Only show features with status: true
                        .map((feature, index) => (
                          <div key={index}>
                            <span>{feature.name}</span>
                            {feature.feature_options.length > 0 && feature.feature_options.some(option => option.status) && (
                              <span>
                                {" "}({feature.feature_options
                                  .filter(option => option.status)  // Only show options with status: true
                                  .map(option => option.name)
                                  .join(', ')})
                              </span>
                            )}
                          </div>
                        ))
                    )}
                </div>
                <button type="submit" className="border-[1px] border-solid bg-[#407BFF] border-[#407BFF] text-white rounded-xl px-[7px] py-3 w-full mt-2">
                  Save Changes
                </button>
              </div>
            </div>

          </div>
          {/* ---------------------- */}
          {/* <div className="w-full relative mt-5">
            <h3 className="text-[#102048] font-semibold mb-3">Manage Permission</h3>
            {formData.features.map((feature, featureIndex) => (
              <div key={feature.name} className="border-[#C1CFEF] border-[1px] rounded-xl p-4 mb-2 bg-white">
                <div className="flex justify-between items-center">
                  <CustomCheckbox
                    name={`features[${featureIndex}].status`}
                    checked={feature.status}
                    onChange={(e) => handleCheckboxChange(e, featureIndex)}
                  />
                  <span>{feature.name}</span>
                </div>
                <div className="ml-4 mt-2">
                  {feature.feature_options.map((option, optionIndex) => (
                    <div key={option.name} className="flex items-center mb-2">
                      <CustomCheckbox
                        name={`features[${featureIndex}].feature_options[${optionIndex}].status`}
                        checked={option.status}
                        onChange={(e) => handleOptionCheckboxChange(e, featureIndex, optionIndex)}
                      />
                      <span>{option.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div> */}

        </div>

      </form>
    </div>
  );
}

export default RolePermissionAddForm;