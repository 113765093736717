import axios from 'axios';
import React, { useState } from 'react';

const WareHouseListForm = (props) => {
  const {loadingCallback,refetch,setWarningMessage,type,formData,setFormData} = props;
  const token = localStorage.getItem("token");

  const handleSubmit = async() => {
    const payload = {...formData,status : 'active'}
    loadingCallback(true);
      try{
        const {data} = await  axios.post(`${process.env.REACT_APP_API_URL}warehouses`, payload, {
          headers:{
            authorization: `Bearer ${token}`,
            "content-type":"application/json"
          }
        });
        if(data){
          loadingCallback(false);
          refetch();
          setFormData({
            name : '',
            email : '',
            phone : '',
            address : ''
          })
        }
      }catch(error){
        loadingCallback(false);
        console.log(error);
      }
  }

  return (
    <div>
            <div className="w-full relative">
                  <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    //value={formData.catogory_name || ''}
                    onChange={(e) => setFormData({
                      ...formData,
                      name: e.target.value
                    })}
                    className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
                  />
                </div>
                <div className="w-full relative mt-2">
                  <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    placeholder="Email"
                    //value={formData.transaction_date || ''}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        email: e.target.value
                      });
                    }}
                    className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
                  />
                </div>

                <div className="w-full relative">
                  <label className="absolute font-nunito text-xs text-[#102048] -top-[10px] left-[12px] bg-white px-1 font-semibold">
                    Phone
                  </label>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone Number"
                    onChange={(e) => setFormData({
                      ...formData,
                      phone: e.target.value
                    })}
                    className="input input-bordered border-[#C1CFEF] border-[1px] w-full mb-[10px] focus:outline-none rounded-xl bg-white"
                  />
                </div>
                <div className="w-full relative mt-2">
                  <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                    Address
                  </label>
                  <textarea
                    type="text"
                    name="address"
                    //value={formData.description || ''}
                    onChange={(e) => setFormData({
                      ...formData,
                      address: e.target.value
                    })}
                    placeholder="Text"
                    className="input input-bordered border-[#C1CFEF] border-[1px] focus:outline-none  w-full  h-20 pt-2 rounded-xl bg-white resize-none"
                  />
                   <button onClick={() => handleSubmit()} className="cursor-pointer w-full  bg-[#3559b3] hover:bg-[#034AFF] py-[17px] text-center rounded-2xl mt-4 text-[#fff] font-lato font-semibold text-lg">Done</button>
                </div>
    </div>
  );
};

export default WareHouseListForm;