import axios from 'axios';
import React, { useContext, useState } from 'react';
import { BiSave } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import TextArea from '../components/products/productsadd/TextArea';
import ImageUploader from './ImageUploader';
import ImageUploader2 from './ImageUploader2';
import Input from './Input';
import SelectBox from './Select';

import { AppContext } from '../context/context';
import QRCode from 'react-qr-code';
import { DatePicker } from 'antd';

const ProductEditForm = props => {
  const {
    handleSubmitProducts,
    handleChange,
    handleChangeSelect,
    handleImageChange,
    formData,
    category,
    filteredSubCategory,
    brands,
    Units,
    discountType,
    isEditMode,
    setErrorFlashMessage,
    setIsValid,
    isValid,
    uploadDetails,
    isQuick,
    loadingCallback,
    setBrands,
    setCategory,
    openCategoryModal,
    openBrandModal,
    openSubCategoryModal,
    setShowNestedModal,
    setNestedModalData,
    saleType,
    handleDate
  } = props;
  const {
    state,
    isLoading,
    updateCategoryList,
    updateSubCategoryList,
    updateBrandList,
    updateUnitList,
    updateInvoiceSetting
  } = useContext(AppContext);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [productForm, setProductForm] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const handleValidation = () => {
    const errors = {};
    if (!isQuick && (!formData.category_id || formData.category_id === '' || formData.category_id === 0)) {
      errors.category_id = 'Category is required.';
      setIsValid(false);
    }
    if (formData.name === '') {
      errors.name = 'Product name is required.';
      setIsValid(false);
    }

    if (
      (formData.purchase_price != '' && formData.purchase_price !== 0) &&
      (!/^\d*\.?\d+$/.test(formData.purchase_price) || parseFloat(formData.purchase_price) < 0)
    ) {
      errors.purchase_price = 'Invalid purchase price.';
      setIsValid(false);
    }

    if (
      (formData.wholesale_price != '' && formData.wholesale_price !== 0) &&
      (!/^\d*\.?\d+$/.test(formData.wholesale_price) || parseFloat(formData.wholesale_price) < 0)
    ) {
      errors.wholesale_price = 'Invalid wholesale price.';
      setIsValid(false);
    }

    if (
      (formData.retails_price != '' && formData.retails_price !== 0) &&
      (!/^\d*\.?\d+$/.test(formData.retails_price) || parseFloat(formData.retails_price) < 0)
    ) {
      errors.retails_price = 'Invalid retails price.';
      setIsValid(false);
    }

    // if (
    //   (formData.quantity != '' && formData.quantity !== 0) &&
    //   (!/^\d*\.?\d+$/.test(formData.quantity) || parseFloat(formData.quantity) < 0)
    // ) {
    //   errors.quantity = 'Invalid quantity.';
    //   setIsValid(false);
    // }
    // if (formData.warrenty &&
    //   (formData.warrenty != '' && formData.warrenty !== 0) &&
    //   (!/^\d*\.?\d+$/.test(formData.warrenty) || parseFloat(formData.warrenty) < 0)
    // )
    // // if( formData.warrenty=='')
    // {
    //   errors.warrenty = 'Invalid warrenty.';
    //   setIsValid(false);
    // }


    if (
      (formData.discount != '' && formData.discount !== 0) &&
      (!/^\d*\.?\d+$/.test(formData.discount) || parseFloat(formData.discount) < 0)
    ) {
      errors.discount = 'Invalid discount.';
      setIsValid(false);
    }
    if ((formData.discount_type === 'Percentage' || formData.discount_type === '2') && parseFloat(formData.discount) > 100) {
      errors.discount = 'discount should be less than 100.';
      setIsValid(false);
    }
    // if((formData.discount_type !== 'Percentage' || formData.discount_type !== '2') && parseFloat(formData.discount) > parseFloat(formData.purchase_price)){
    //   errors.discount = 'discount should be less than purchase price.';
    //   setIsValid(false);
    // }
    if ((formData.discount_type !== 'Percentage' || formData.discount_type !== '2') && parseFloat(formData.discount) > parseFloat(formData.retails_price)) {
      errors.discount = 'discount should be less than Retails price.';
      setIsValid(false);
    }


    // if (!formData.category_id || formData.category_id === 'Select Category*') {
    //   errors.category_id = 'Category is required.';
    //   setIsValid(false);
    // }

    // if (!isQuick && (!formData.sub_category_id || formData.sub_category_id === 'Select Sub Category*')) {
    //   errors.sub_category_id = 'Subcategory is required.';
    //   setIsValid(false);
    // }

    // if (!isQuick && (!formData.brand_id || formData.brand_id === 'Select Brand*')) {
    //   errors.brand_id = 'Brand is required.';
    //   setIsValid(false);
    // }
    // Check if the "image_path" field has at least one image for new uploads in non-edit mode
    // if (!isQuick && !isEditMode && !uploadDetails.some((detail) => detail.input_name === 'image_path')) {
    //   errors.image_path = 'Image is required.';
    //   setIsValid(false);
    // }
    // if (!formData.images || formData.images.length === 0) {
    //   errors.images = 'At least one product image is required.';
    //   isValid = false;
    // }

    setFormErrors(errors);


    //if (errors.name || errors.category_id || errors.sub_category_id || errors.brand_id || errors.purchase_price || errors.retails_price ) {
    if (errors.name ||
      errors.discount ||
      errors.purchase_price ||
      errors.wholesale_price ||
      errors.quantity ||
      // errors.warrenty ||
      // errors.warranties_count ||
      errors.retails_price ||
      errors.category_id) {
      setErrorFlashMessage('Please fill all the required fields.');
      setIsValid(false);
      return false;
    } else {
      setIsValid(true);
      return true;

    }

  };


  const handleSubmit = (event) => {
    event.preventDefault();

    if (handleValidation()) {
      setIsValid(true);
      handleSubmitProducts();
    } else {
      console.error('Form validation failed.');
    }
  };

  const [showAddBrandModal, setShowAddBrandModal] = useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);

  const handleShowAddBrandModal = () => {
    setNestedModalData({
      title: 'Add New Brand',
      type: 'brand',

    });
    setShowNestedModal(true);
  }
  const handleShowAddCategoryModal = () => {
    // setShowAddCategoryModal(true);
    // setProductForm(false);
    setNestedModalData({
      title: 'Add New Category',
      type: 'category'
    });
    setShowNestedModal(true);
  }
  function handleSubmitCategory(e) {
    e.preventDefault();
    if (!e.target.name.value) {
      return
    }
    loadingCallback(true);
    //setProductForm(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}save-category`,
        { name: e.target.name.value, description: '' },
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadingCallback(false);
        //console.log(response.data.success);
        if (response.data.success) {
          setShowAddCategoryModal(true);
          setProductForm(true);
          getCategory();
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
      });

  }

  function handleSubmitBrand(e) {
    e.preventDefault();
    if (!e.target.name.value) {
      return
    }
    loadingCallback(true);
    //setProductForm(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}save-brand`,
        { name: e.target.name.value, description: '' },
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadingCallback(false);
        if (response.data.success) {
          setShowAddBrandModal(false);
          setProductForm(true);
          getBrand();
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
      });
  }


  const getCategory = () => {
    loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}get-all-category`, {
        headers: {
          authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        loadingCallback(false);
        if (response.data?.data?.data) {
          let cartProducts = [...response.data.data.data];
          setCategory(cartProducts);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
      });
  };

  const getBrand = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}brands?page=1&limit=200`, {
        headers: {
          authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        //console.log(response.data.data.data)
        if (response.data?.data?.data) {
          let cartProducts = [...response.data.data.data];
          //setBrands(cartProducts);
          // const newCartProducts = [...cartProducts];

          // // Find the index of the item with the specified ID
          // const itemIndex = newCartProducts.findIndex((item) => item.id === id);

          // // Remove the item at the index
          // if (itemIndex !== -1) {
          //   newCartProducts.splice(itemIndex, 1);
          // }

          // Update the state with the new array
          setBrands(cartProducts);
        }
        loadingCallback(false);
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
      });
  };
  return (
    <>

      {showAddCategoryModal && (
        <form
          method="get"
          className="col-sm-12"
          data-autosubmit="false"
          autoComplete="off"
          onSubmit={handleSubmitCategory}
        >
          <input
            type='text'
            name="name"
            onChange={(e) => {
              const val = e.target.value;
            }}
            placeholder='Category Name'
            className='input input-bordered w-full mb-[15px] rounded-2xl bg-white'
          />
          <div className="button-disabled">
            <button
              htmlFor='my_modal_2'
              type="submit"
              className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-nunito font-semibold text-lg"
            >
              {""}
              Save{""}
            </button>
          </div>
        </form>
      )}
      {productForm && (
        <form
          method="post"
          className={`${isQuick ? '' : 'col-sm-12'}`}
          data-autosubmit="false"
          autoComplete="off"
          onSubmit={handleSubmit}

        >
          <div className={` drop-shadow-[0_0_4px_0px_rgba(16, 32, 72, 0.20)] pb-[22px] pt-7 px-5 rounded-xl`}>


            {isQuick ? (
              <div className={`${!isQuick ? 'lg:grid-cols-2 xl:grid-cols-4' : ''} grid grid-cols-1 gap-4`}>
                <div className="relative">
                  <Input
                    label={`${!isQuick ? 'Name*' : ''}`}
                    placeholder={"Product Name"}
                    name="name"
                    value={formData.name} // Set the value from formData
                    onChange={handleChange}
                    error={formErrors.name} // Pass the error message for the 'name' field
                    setFormErrors={setFormErrors}
                  ></Input>
                </div>
                <div className='flex items-center justify-center gap-3'>
                  <SelectBox
                    name="brand_id"
                    options={brands}
                    value={formData.brand_id}
                    onChange={handleChangeSelect.bind(this, 'brand_id')}
                    // error={formErrors.brand_id}
                    // setFormErrors={setFormErrors}
                    placeholder="Select Brand"
                  />

                  {/* Add nested modal trigger */}
                  <label
                    htmlFor="nested_modal"
                    onClick={handleShowAddBrandModal}
                    className="cursor-pointer"
                  >
                    <i
                      className="fa-solid fa-plus font-semibold border border-red-50 text-2xl p-2 rounded-[10px] cursor-pointer"
                    // onClick={handleShowAddBrandModal}
                    ></i>
                  </label>


                </div>
                <div className='flex items-center justify-center gap-3'>
                  <SelectBox
                    name="category_id"
                    options={category}
                    value={formData.category_id}
                    onChange={handleChangeSelect.bind(this, 'category_id')}
                    style={{ maxHeight: '300px', overflowY: 'scroll' }}
                    // error={formErrors.category_id}
                    // setFormErrors={setFormErrors}
                    placeholder="Select Category"
                  />
                  {/* Add nested modal trigger */}
                  <label
                    htmlFor="nested_modal"
                    onClick={handleShowAddCategoryModal}
                    className="cursor-pointer"
                  >
                    <i
                      className="fa-solid fa-plus font-semibold border border-red-50 text-2xl p-2 rounded-[10px] cursor-pointer"
                    // onClick={handleShowAddBrandModal}
                    ></i>
                  </label>
                </div>
                <div className='flex gap-2'>
                  <Input
                    name="purchase_price"
                    placeholder={"Purchase Price"}
                    value={formData.purchase_price}
                    onChange={handleChange}
                    error={formErrors.purchase_price} // Pass the error message for the 'name' field
                    setFormErrors={setFormErrors}
                  ></Input>
                  {saleType === 'wholesale' ?
                    <Input
                      name="wholesale_price"
                      placeholder={"Wholesale Price"}
                      value={formData.wholesale_price}
                      onChange={handleChange}
                      error={formErrors.wholesale_price} // Pass the error message for the 'name' field
                      setFormErrors={setFormErrors}
                    ></Input>
                  :
                    <Input
                      name="retails_price"
                      placeholder={"Retail Price"}
                      value={formData.retails_price}
                      onChange={handleChange}
                      error={formErrors.retails_price} // Pass the error message for the 'name' field
                      setFormErrors={setFormErrors}
                    ></Input>
                  }

                </div>
                {/* {!isEditMode && (
                  <Input
                    name={"quantity"}
                    value={formData.quantity}
                    placeholder={"Quantity"}
                    onChange={handleChange}
                    error={formErrors.quantity} // Pass the error message for the 'name' field
                    setFormErrors={setFormErrors}
                  ></Input>
                )} */}
                <div className="w-full relative">
                  <select
                    name="discount_type"
                    value={formData.discount_type}
                    onChange={handleChange}
                    className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                    style={{ maxHeight: '300px', overflowY: 'scroll' }}
                  >
                    <option disabled=""> Select Discount Type</option>
                    {discountType.map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <Input
                  name={"discount"}
                  placeholder={"Discount"}
                  onChange={handleChange}
                  value={formData.discount}
                  error={formErrors.discount} // Pass the error message for the 'name' field
                  setFormErrors={setFormErrors}
                ></Input>
              </div>
            ) : (
              <div>

                <div className='flex flex-col xl:flex-row gap-3  mb-10'>
                  <div className='w-full xl:w-[55%] flex flex-col justify-between space-y-7 xl:space-y-0'>
                    <div className="relative  mb-4">
                      <Input
                        label="Name*"
                        placeholder={"Product Name"}
                        name="name"
                        value={formData.name} // Set the value from formData
                        onChange={handleChange}
                        error={formErrors.name} // Pass the error message for the 'name' field
                        setFormErrors={setFormErrors}
                      ></Input>
                    </div>

                    <div className='flex items-center justify-center py-3 gap-3 '>
                      <SelectBox
                        name="category_id"
                        options={category}
                        value={formData.category_id}
                        //onChange={handleChangeSelect}
                        onChange={handleChangeSelect.bind(this, 'category_id')}
                        error={formErrors.category_id}
                        setFormErrors={setFormErrors}
                        label={"Category*"}
                        className=""
                        placeholder="Select Category"
                        style={{ maxHeight: '300px', overflowY: 'scroll' }}
                      />
                      <label
                        htmlFor='my_modal_category' onClick={openCategoryModal}
                      >
                        <i className="fa-solid fa-plus font-semibold border border-red-50 text-2xl px-2 py-1 rounded-[10px] cursor-pointer" ></i>
                      </label>


                    </div>

                    <div className='flex items-center justify-center gap-3 py-3 '>
                      <SelectBox
                        name="sub_category_id"
                        options={filteredSubCategory}
                        value={formData.sub_category_id}
                        //onChange={handleChangeSelect}
                        onChange={handleChangeSelect.bind(this, 'sub_category_id')}
                        // error={formErrors.sub_category_id}
                        // setFormErrors={setFormErrors}
                        label={"Sub Category"}
                        className=""
                        placeholder="Select Sub Category"
                        style={{ maxHeight: '300px', overflowY: 'scroll' }}
                      />
                      <label
                        htmlFor='my_modal_sub_category' onClick={openSubCategoryModal}
                      >
                        <i className="fa-solid fa-plus font-semibold border border-red-50 text-2xl px-2 py-1 rounded-[10px] cursor-pointer" ></i>
                      </label>
                    </div>

                    <div className='flex items-center justify-center gap-3 py-3 '>
                      <SelectBox
                        name="brand_id"
                        options={brands}
                        value={formData.brand_id}
                        //onChange={handleChangeSelect}
                        onChange={handleChangeSelect.bind(this, 'brand_id')}
                        // error={formErrors.brand_id}
                        // setFormErrors={setFormErrors}
                        label={"Brand"}
                        className=""
                        placeholder="Select Brand"
                        style={{ maxHeight: '300px', overflowY: 'scroll' }}
                      />
                      <label
                        htmlFor='my_modal_brand' onClick={openBrandModal}
                      >
                        <i className="fa-solid fa-plus font-semibold border border-red-50 text-2xl px-2 py-1 rounded-[10px] cursor-pointer" ></i>
                      </label>
                    </div>
                    <div className='flex items-center gap-3'>
                      <div className="flex-1">
                        <Input
                          label={"Serial /IMEI"}
                          placeholder={"Serial /IMEI"}
                          name="serial"
                          value={formData?.serial} // Set the value from formData
                          onChange={handleChange}
                          className={`${isQuick ? 'hidden' : ''}`}
                        ></Input>
                      </div>
                      <div className="w-[50px]">
                        <QRCode className="w-full object-contain" value='' size={100} />
                      </div>
                    </div>
                  </div> {/* End right w-[50%] */}

                  <div className='w-full xl:w-[45%] flex flex-col sm:flex-row gap-2'>
                    <div className='w-full sm:w-[65%]'>
                      {/* {formErrors && formErrors?.image_path ? <p className="error-message text-red-500">{formErrors?.image_path}</p> : null} */}
                      <ImageUploader
                        name={"image_path"}
                        isBig={true}
                        //onChange={(files) => handleImageChange("image_path", files)}
                        onChange={handleImageChange}
                        image={isEditMode ? formData.image_path : null}
                      ></ImageUploader>
                    </div>
                    <div className='w-full sm:w-[35%] felx flex-col'>
                      <div className='mb-2 h-[49%]'>
                        <ImageUploader2
                          name={"image_path1"}
                          isBig={true}
                          onChange={handleImageChange}
                          //onChange={(files) => handleImageChange("image_path1", files)}
                          image={isEditMode ? formData.image_path1 : null}
                        ></ImageUploader2>
                      </div>
                      <div className='h-[49%]'>
                        <ImageUploader2
                          name={"image_path2"}
                          isBig={true}
                          onChange={handleImageChange}
                          image={isEditMode ? formData.image_path2 : null}
                        ></ImageUploader2>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='flex gap-2'>
                  <input type="checkbox" name="product" id="product" value="" className="rounded-[6px]" />
                  <p className='text-[#91919F]'>Product Variations</p>
                </div> */}
                <div className='flex gap-2'>
                  <input
                    checked={formData.have_variant ? true : false} // Check the checkbox if formData.have_variant has a value
                    type="checkbox"
                    id="product"
                    value="1"
                    className="rounded-[6px]"
                    name="have_variant"
                    onChange={handleChange}
                  />
                  <p className='text-[#91919F]'>Has Product Variations</p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-7">
                  <Input
                    label={"Purchase Price"}
                    name="purchase_price"
                    placeholder={"0.00"}
                    value={formData.purchase_price}
                    onChange={handleChange}
                    error={formErrors.purchase_price}
                    setFormErrors={setFormErrors}
                  ></Input>
                  <Input
                    label={"WholeSale Price"}
                    name="wholesale_price"
                    placeholder={"0.00"}
                    value={formData.wholesale_price}
                    onChange={handleChange}
                    error={formErrors.wholesale_price}
                    setFormErrors={setFormErrors}
                  ></Input>
                  <Input
                    label={"Retail Price"}
                    name="retails_price"
                    placeholder={"0.00"}
                    value={formData.retails_price}
                    onChange={handleChange}
                    error={formErrors.retails_price}
                    setFormErrors={setFormErrors}
                    // error={formErrors.retails_price}
                    // setFormErrors={setFormErrors}
                    className={`${isQuick ? 'hidden' : ''}`}
                  ></Input>
                </div>

                <div className={`grid grid-cols-1  ${isEditMode?'md:grid-cols-2' :'md:grid-cols-3'} gap-4 mt-7`}>

                  <div className={`${isQuick ? 'hidden' : 'w-full relative'}`}>
                    <select
                      name="unit_id"
                      onChange={handleChange}
                      value={formData.unit_id}
                      className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                      style={{ maxHeight: '300px', overflowY: 'scroll' }}
                    >
                      <option disabled=""> Select Unit</option>
                      {Units.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                      Unit
                    </label>
                  </div>
                  {/* {!isEditMode && (
                    <Input
                      name={"quantity"}
                      value={formData.quantity}
                      label="Quantity"
                      placeholder={"10000"}
                      onChange={handleChange}
                      error={formErrors.quantity} // Pass the error message for the 'name' field
                      setFormErrors={setFormErrors}
                    ></Input>
                  )} */}

                  <Input
                    label={"Warranty Day"}
                    placeholder={"Warranty Day"}
                    name="warranties_count"
                    value={formData.warranties_count} // Set the value from formData
                    onChange={handleChange}
                    error={formErrors.quantity}
                    className={`${isQuick ? 'hidden' : ''}`}
                  ></Input>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-7">
                  <div className="w-full relative">
                    <select
                      name="discount_type"
                      value={formData.discount_type}
                      onChange={handleChange}
                      className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                      style={{ maxHeight: '300px', overflowY: 'scroll' }}
                    >
                      <option disabled=""> Select Discount Type</option>
                      {discountType.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                      Discount Type
                    </label>
                  </div>
                  <Input
                    name={"discount"}
                    label="Discount"
                    placeholder={"0.00"}
                    onChange={handleChange}
                    value={formData.discount}
                    error={formErrors.discount} // Pass the error message for the 'name' field
                    setFormErrors={setFormErrors}
                  ></Input>



                  <Input
                    name={"warrenty"}
                    value={formData.warrenty}
                    label="Warranty Condition"
                    placeholder={"Warranty Condition"}
                    onChange={handleChange}
                    // error={formErrors.warrenty} // Pass the error message for the 'name' field
                    setFormErrors={setFormErrors}
                  ></Input>

                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-7">
                  <Input
                    name={"manufactory_date"}
                    label="Manufactory Date"
                    placeholder={"dd/mm/yyyy"}
                    onChange={handleChange}
                    value={formData.manufactory_date}
                  ></Input>

              {/* <div className="relative border-[#C1CFEF] py-2  border w-full flex-1 rounded-xl">
              <DatePicker
              bordered={false}
                selected={formData?.manufactory_date}
                onChange={(date)=>handleDate(date)}
                dateFormat="dd/MM/yyyy"
                name={"manufactory_date"}
                className="w-full px-2 focus:outline-none"
              />
              <span className="bg-white absolute font-nunito left-2 px-1 font-semibold text-xs top-0 text-[#102048] translate-y-[-50%]">
                Manufactory Date
              </span>
            </div> */}

                  <Input
                    name={"expiry_date"}
                    label="Expiry Date"
                    placeholder={"dd/mm/yyyy"}
                    onChange={handleChange}
                    value={formData.expiry_date}
                  ></Input>

         {/* <div className="relative border-[#C1CFEF] py-2  border w-full flex-1 rounded-xl">
              <DatePicker
              bordered={false}
              name={"expiry_date"}
                selected={formData.expiry_date}
                onChange={(date)=>handleDate(date)}
                dateFormat="dd/MM/yyyy"
                className="w-full px-2 focus:outline-none"
              />
              <span className="bg-white absolute font-nunito left-2 px-1 font-semibold text-xs top-0 text-[#102048] translate-y-[-50%]">
                Expiry Date
              </span>
          </div> */}

                  <Input
                    name={"code"}
                    label="Product Code"
                    placeholder={"#00000"}
                    onChange={handleChange}
                    value={formData.code}
                  ></Input>
                </div>
                <div className={`${isQuick ? 'md:flex-row hidden' : ''} flex flex-col items-center  gap-4 mt-6`}>
                  <TextArea
                    label={"Description & Features"}
                    name={"description"}
                    placeholder={"Product Description & Features..."}
                    onChange={handleChange}
                    value={formData.description}
                  ></TextArea>
                  {/* <TextArea
                    label={"Warranty Details"}
                    name={"warrenty"}
                    placeholder={"Product Warranty Details..."}
                    onChange={handleChange}
                    value={formData.warrenty}
                  ></TextArea> */}
                </div>
              </div>
            )}
            <div className="flex items-center justify-center mt-3">
              <button
                type="submit"
                className="bg-[#407BFF] rounded-[5px] text-[#FFF] text-sm font-nunito font-bold py-2.5 px-[67px] flex items-center gap-2"
              >
                <BiSave className="text-xl"></BiSave> <span>{isEditMode ? 'Update Product' : 'Add Product'}</span>
              </button>
            </div>
          </div>
        </form>
      )}
    </>

  );
};

ProductEditForm.defaultProps = {
  isQuick: false,
  productForm: true,
};

export default ProductEditForm;