import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';

const RoleFeatureItem = ({index, rolefeatureitem, employeeList}) => {

  const formattedIndex = String(index + 1).padStart(2, '0');

  // Extract all features and their status
  const features = rolefeatureitem.features || [];
  const activeFeatures = features.filter(feature => feature.status === 1);
  const allActive = activeFeatures.length === features.length && features.length > 0;

  // Get feature names or "All" if all are active
  const featureDisplay = allActive ? "All" : activeFeatures.map(feature => feature.name).join(', ');

  //count number of employees assigned to this role
  const employeeCount = employeeList.filter(employee => employee.role_id === String(rolefeatureitem.id)).length;

  //---------------
  const navigate = useNavigate();

  const GotoViewRolePermission = (id) => {
    navigate(`/role-view/${id}`);
  };

  const GotoEditRolePermission = (id) => {
    navigate(`/role-edit/${id}`);
  };

  return(
    <div>
      <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[8px] grid grid-cols-5 gap-2 items-center font-nunito font-[500] text-[14px] text-[#000232]">
        <div>
            <div className="flex flex-col sm:flex-row gap-2">
              <div className="font-nunito text-[14px]">
                <div className="font-[500] text-[#000232]">{formattedIndex}</div>
                {/* <div className="font-[700] text-[#87889E]">{expense?.category_name}</div> */}
              </div>
            </div>
          </div>
          <div className="text-center">{rolefeatureitem.name}</div>
          <div className="text-center">{featureDisplay || 'No active permissions'}</div>
          <div className="text-center">{employeeCount}</div>
          <div className="flex gap-2  text-xs justify-end">
            <label
              onClick={() => GotoViewRolePermission(rolefeatureitem.id)}
              className="curser-pointer border-[1px] border-solid border-[#407BFF] text-[#407BFF] rounded-[5px] px-[6px] py-1"
            >
            VIEW
            </label>
            <label
              onClick={() => GotoEditRolePermission(rolefeatureitem.id)}
              className="border-[1px] border-solid bg-[#407BFF] border-[#407BFF] text-white rounded-[5px] px-[7px] py-1"
            >
            EDIT
            </label>
          </div>
      </div>
    </div>
  );
}

export default RoleFeatureItem;