import React from 'react';
import { formatPhoneNumber, numberToWords, formatPrice } from '../../../utils/Common';
import Watermark from '../../../components/ReturnDetail/Watermark/Watermark';
import './PdfHtmlContent.css';



import QRCode from 'react-qr-code';

const PdfHtmlContent = props => {
  const { invoice, invoiceSetting,type } = props;
  const BASE_URL = process.env.REACT_APP_ROOT_URL;
  const invoiceDate = new Date(invoice.created_at);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
  const day = String(invoiceDate.getDate()).padStart(2, '0');
  const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
  const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
  const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';

  const shop_logo = invoiceSetting?.shop_logo ? invoiceSetting?.shop_logo : `${BASE_URL}/layoutlogo.svg`;;
  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
  let totalPrice = 0;

  const item = type === 'salesReturn' ? invoice?.sales_details : invoice?.purchase_details;
  const totalQty = Number(item?.return_qty);
  const subtotalPrice = totalQty * Number(item?.return_unit_price);

  return (
    <div id="pdfConentAreas" className="pdfConentAreas">
      <div id="invoice-detail-page" className="invoice-detail-page font-nunito">
        {/* <div className="invoice-info">
          <h1>Invoice</h1>
          <p>{invoice.invoice_id}</p>
          <p>{formattedDate}</p>
        </div> */}
        <div className="invoice-header">
          <div className="invoice-logo font-nunito font-semibold mx-auto w-[100px] h-[90px] md:w-[551px] md:h-[175px] text-center text-18">
            <img
              src={shop_logo}
              alt={invoice.invoice_id}
               className="mx-auto p-3" />
            <div className='my-2'>
            <p>{invoice?.user_info?.outlet_name} <br />
                {invoice?.user_info?.address}<br/>
                {formatPhoneNumber(invoice?.user_info?.phone, invoice?.user_info?.country_code)}
                </p>
            </div>
          </div>

          {/* <div>
            <h1 className='font-bold text-[20px] font-nunito '>{invoiceSetting.shop_name}</h1>
            <p className="mt-1 mb-1 text-13 font-semibold text-gray-700 font-nunito">{invoiceSetting.shop_address}</p>
            <p className="text-blue-500 font-nunito text-12 font-bold">Mobile: +880 1726594320</p>
          </div> */}
        </div>
        {/* <DashedLine /> */}

        <div className="invoice-customer flex justify-between m-3 items-center">
          <div className="text-black font-nunito text-24 leading-140">
            {type === 'salesReturn' ?
              <p className='mb-2'> <span className='font-semibold'>Customer Name</span>  :  {invoice?.sales?.customer_name} </p>
            :
              <p className='mb-2'> <span className='font-semibold'>Vendor Name</span>  :  {invoice?.purchase?.vendor_name} </p>
            }
            {type === 'salesReturn' ?
              <p className='mb-2'><span className='font-semibold'>Customer Phone</span> :  {invoice?.sales?.customers?.mobile_number ? invoice?.sales?.customers?.mobile_number : invoice?.sales?.customer_phone}</p>
            :
              <p className='mb-2'><span className='font-semibold'>Vendor Phone</span> :  {invoice?.purchase?.vendor?.country_code}{invoice?.sales?.vendor?.mobile_number}</p>
            }
            {type === 'salesReturn' ?
              <p className='mb-2'><span className='font-semibold'> Customer ID </span> : #{invoice.customer_id}</p>
            :
              <p className='mb-2'><span className='font-semibold'> Vendor ID </span> : #{invoice.vendor_id}</p>
            }
            <p className='mb-2'><span className='font-semibold'> Invoice Date </span>: {formattedDateTime} </p>
          </div>
          <div className="w-[100px]">
            {type === 'salesReturn' ?
              <QRCode className="w-full object-contain" value={BASE_URL + '/sales-return/' + invoice.return_id} size={100} />
            :
              <QRCode className="w-full object-contain" value={BASE_URL + '/purchase-return/' + invoice.return_id} size={100} />
            }
          </div>
        </div>

        {/* <div style={{ width: '100%', backgroundColor: '#3056FA', padding: '10px', textAlign: 'center', margin: '15px auto' }}>
          <p className="text-white font-nunito text-14 font-bold uppercase">Invoice No: {invoice.return_id}</p>
        </div> */}

        <div
          className="w-full bg-[#3056FA] px-[14px] pb-[17px] flex justify-between items-center text-xs md:text-base lg:text-lg"
        >
          <p className="text-white font-nunito font-bold uppercase">
            RETURN INFO
          </p>
          <p className="text-white font-nunito font-bold uppercase">
            #{invoice.return_id}
          </p>
        </div>

        <div className={`invoice-items`}>
          <table className={`table ${'h-[600px]' ? 'overflow-x-scroll' : 'overflow-x-hidden'}`}>
            <thead>
              <tr className="text-gray-900 font-nunito text-base font-semibold leading-normal tracking-wide relative">
                <th><span className="hidden md:inline-block">Return</span> Date</th>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Purchased Amount</th>
                <th className='text-right'>Return Amount</th>
              </tr>
            </thead>
            <tbody>
              <Watermark watermark_text={invoiceSetting?.watermark_text} watermark_type={invoiceSetting?.watermark_type}></Watermark>
              { item && (
                  <tr key={item.id} className="text-blue-gray-500 font-nunito text-[16px] relative">
                    <td>{formattedDate}</td>
                    <td>{item?.product_info?.name}</td>
                    <td>{item.return_qty}</td>
                    <td>{formatPrice(subtotalPrice)} {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}</td>
                    <td className='text-gray-900 font-nunito  font-bold text-right'>{formatPrice(item.return_amount)} {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}</td>
                  </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <DashedLine2 /> */}
        <br />
        <div className='flex justify-between gap-[10px] border-t-[1px] border-solid border-[#000] mt-[30px]'>
          <div className="terms-condition font-semibold text-[18px]">
            <p className="font-bold text-[20px]">Return Description:</p>
            <p className='font-[400] text-[20px]'>{invoice?.return_description}</p>
          </div>
          <div className="invoice-summary ">

            <p className='flex mt-2 justify-between text-navy-800 font-nunito font-semibold gap-3'>
              <span>TOTAL ITEMS</span>
              <span>{totalQty}</span>
            </p>

            <p className='flex mt-2 justify-between text-navy-800 font-nunito font-semibold gap-3'>
              <span>SUB TOTAL</span>
              <span>{formatPrice(subtotalPrice)}{invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}</span>
            </p>
            <p className='flex mt-2 justify-between text-navy-800 font-nunito font-semibold gap-3'>
              <span>Grand Total</span>
              <span>{formatPrice(subtotalPrice)}{invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}</span>
            </p>

            <p className="flex mt-2 justify-between text-navy-800 font-nunito font-semibold ">
              <span>Paid Amount</span>
              <span>
              {type === "salesReturn" ? formatPrice(invoice?.sales?.paid_amount) : formatPrice(invoice?.purchase?.paid_amount) }
              {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}
              </span>
            </p>

            <p className="flex mt-2 justify-between text-navy-800 font-nunito font-semibold ">
              <span>Return Amount</span>
              <span>
               {item?.return_amount}
               {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}
              </span>
            </p>

            <p className="flex mt-3 justify-between font-nunito font-[600] text-[#24px] border-t-[1px] border-solid border-[#E7EBF4]">
              <span>Due Amount</span>
              <span>{formatPrice(item?.return_amount-subtotalPrice)}
               {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}</span>
            </p>
          </div>
        </div>


        <p className="text-[#102048] mt-5 font-nunito text-sm md:text-base lg:text-lg font-[600] text-center mb-[25px]">
          <span className="text-[#7282A0]">In word:</span> {numberToWords(subtotalPrice)} taka only
        </p>

        <div className="w-full bg-[#3056FA] px-[10px] pb-[17px] mb-[40px] text-xs md:text-base lg:text-lg text-white text-center font-bold font-nunito">
          You have returned {formatPrice(item?.return_amount)} {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}
        </div>

        <div>
          {/* <div className='flex justify-between'>
            <p className="invisible">.</p>
            {invoiceSetting?.sign_authority && <div className='w-[120px] h-[120px] rounded-lg'>
              <img src={invoiceSetting.sign_authority} className='object-cover' />
            </div>}
          </div> */}
          <div className="flex justify-between">
            <p ><hr></hr> Customer Signature</p>
            <p ><hr></hr> Seller Signature </p>
          </div>
        </div>

        <div className="text-center font-nunito">
          <h2 className="text-[#FF6C02] text-xl font-nunito font-[600]">
            Thanks for purchase
          </h2>
          <p className="text-black mt-1 font-nunito text-xs ">
            A Product of SQUAD INNOVATORS{" "}
          </p>
        </div>

      </div>
    </div>
  );
};

export default PdfHtmlContent;
