import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { uploadFile, uploadFileMultipleInput } from "../../utils/Common";
import { AppContext } from "../../context/context";
import InvoiceSettingForm from "../../forms/InvoiceSettingForm";
import { get } from "../../services/apiService";
import Navbar from "../Navbar/Navbar";
import "./index.css";
import crose from "../../../src/assets/images/crose.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { Link } from "react-router-dom";

const BASE_URL = process.env.REACT_APP_ROOT_URL;

function TopHeader(props) {
  const { onLogout, isPublic } = props;
  const { state, updateInvoiceSetting } = useContext(AppContext);

//const { currentUser } = state;
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const shopName = localStorage.getItem("outletname");
  const ownerName = localStorage.getItem("ownername");


  const [currency, setCurrency] = useState([]);

  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };

  const currentPage = 1;
  const [open, setOpen] = useState(true);
  const [isVisible, setVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleOpen = () => setShowModal(!showModal);

  const invoiceSettingData = state.invoiceSetting;

  const invoiceSetting = invoiceSettingData
    ? invoiceSettingData
    : {
        outlet_name: "",
        owner_name: "",
        shop_name: "",
        shop_logo: "",
        shop_address: "",
        sign_authority: "",
        name_authority: "",
        currency_id: "",
        category_id: "",
        watermark_text: "",
        watermark_type: "",
      };

  const [logoFile, setLogoFile] = useState();

  const handleChange = ({ target: { files } }) => {
    let tempUploadDetails = [...uploadDetails];

    for (let i = 0; i < files.length; i++) {
      let size = files[i].size / 1024;
      let sizeName = "";

      if (size > 1024) {
        size = (size / 1024).toFixed(2);
        sizeName = size + "MB";
      } else {
        sizeName = size.toFixed(2) + "KB";
      }

      tempUploadDetails.push({
        name: files[i].name,
        size: sizeName,
        percentage: 0,
      });
    }

    setUploadDetails(tempUploadDetails);
    setUploadedFiles(files);

    setLogoFile(URL.createObjectURL(files[0]));
  };
  const toggleVisible = () => {
    if (!isPublic) {
      setVisible(!isVisible);
      setOpen(!open);
    }
  };

  const closeMenu = () => {
    setVisible(false);
    setOpen(false);
  };

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const isPinVerify = localStorage.getItem("isPinVerify");

  useEffect(() => {
    if (!isPublic) {
      if (!token) {
        navigate("/login");
      } else if (token == "false" || token == "") {
        navigate("/login");
      } else if (token != "false" && isPinVerify == "false") {
        navigate("/verifypin");
      }

      if (invoiceSetting.shop_logo) {
        setLogoFile(invoiceSetting.shop_logo);
      }
    }
  }, []);

  const [uploadedFiles, setUploadedFiles] = useState({});
  const [uploadedFilesWatermark, setUploadedFilesWatermark] = useState({});
  const [uploadDetails, setUploadDetails] = useState([]);

  const invoiceSettingDataSave = (values, invoiceSetting) => {
    const { ...data } = values;
    //data.shop_logo = brandLogo.length > 0 ? brandLogo[0] : invoiceSetting && invoiceSetting.shop_logo ? invoiceSetting.shop_logo : null;
    //data.shop_logo = brandLogo.length > 0 ? brandLogo[0] : invoiceSetting && invoiceSetting.shop_logo ? invoiceSetting.shop_logo : null;
    loadingCallback(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}save-invoice-settings`, data, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        loadingCallback(false);
        if (response.data.success) {
          getInvoiceSetting();
          setLogoFile(data.shop_logo);
          if (data.watermark_type == "Logo") {
            setUploadedFilesWatermark(data.watermark_text);
          }
          setShowModal(false);
        } else {
          setLogoFile("");
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };

  const getInvoiceSetting = () => {
    loadingCallback(true);
    get("get-invoice-settings", token)
      .then((response) => {
        if (response.success) {
          updateInvoiceSetting(response.data);
        }
        setLogoFile(response.data.shop_logo);
        loadingCallback(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };



  function handleSubmitInvoiceSetting(values, invoiceSetting) {
    let imageFileData = uploadDetails;
    if (values.watermark_type == "text") {
      imageFileData = uploadDetails.filter(
        (item) => item.input_name != "watermark_text"
      );
    }
    console.log(imageFileData);
    loadingCallback(true);

    uploadFileMultipleInput(
      imageFileData,
      (shopLogo) => {
        const updatedFormData = { ...values };
        shopLogo.forEach((item) => {
          updatedFormData[item.input_name] = item.path;
        });
        // Success callback
        invoiceSettingDataSave(updatedFormData, invoiceSetting);
      },
      (error) => {
        // Error callback
        console.log(error);
        loadingCallback(false);
      },
      (progressDetails) => {
        // Progress callback, update uploadDetails here
        setUploadDetails(progressDetails);
      }
    );
  }

  return (
    <>
    <div className="fixed md:static bg-[#EBF1FF] h-[7.3rem] pt-[30px] md:pt-0 top-0 w-full left-[50%] translate-x-[-50%] md:translate-x-0  z-[9999]">

      <div className="topheader">
        <div className="person">
          <div className="personimage w-[45px] h-[45px]">
            <img
              className="w-full h-full rounded-full"
              src={
                state.invoiceSetting?.shop_logo
                  ? state.invoiceSetting?.shop_logo
                  : `${BASE_URL}/assets/dashboard/personimage.svg`
              }
              alt=""
            />
          </div>
          <div className="person-name">
            <p>{currentUser?.invoice_settings?.users?.owner_name ? currentUser?.invoice_settings?.users?.owner_name : (currentUser?.owner_name ? currentUser?.owner_name : ownerName )}</p>
            <span>{currentUser?.invoice_settings?.users?.outlet_name ? currentUser?.invoice_settings?.users?.outlet_name : (currentUser?.outlet_name ? currentUser?.outlet_name : shopName) }</span>
            {/* <p>{currentUser?.invoice_settings?.users?.owner_name ? currentUser?.invoice_settings?.users?.owner_name : currentUser?.owner_name}</p>
            <span>{invoiceSetting?.users?.outlet_name ? invoiceSetting?.users?.outlet_name : invoiceSetting?.shop_name}</span> */}
          </div>
        </div>

        <div className="setting-notification">
        <Link to={`/billing`}>
            <div className="pos-icon">

            </div>
          </Link>
          <div className="notifications-icon">
            <img
              src={`${BASE_URL}/assets/dashboard/notifications.svg`}
              alt="notifications"
            />
            <i
              onClick={toggleVisible}
              className="fi fi-rr-chart-simple-horizontal md:hidden mr-3"
            ></i>
          </div>
          <Link to={`/settings/shop-settings`}>
            <div className="settings-icon">
              <img
                src={`${BASE_URL}/assets/dashboard/settings.svg`}
                alt="settings"
              />
            </div>
          </Link>



          {/* <div className="flex items-center cursor-pointer">
            <FontAwesomeIcon onClick={onLogout} icon={faRightFromBracket} className="p-3 text-[25px] ml-3  bg-[#EBF1FF] rounded-full" />
          </div> */}
        </div>
      </div>
      </div>
      {isVisible && (
        <div>
          <Navbar onLogout={onLogout} closeMenu={closeMenu}></Navbar>
        </div>
      )}
      {loading && (

        <div className="custom-loader-logo">
          {/* <BeatLoader color={"#123abc"} size={20} /> */}
          <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
        </div>
      )}

      {showModal ? (
        <>
          <div>
            {/* The button to open modal */}
            {/* Put this part before </body> tag */}
            <input
              type="checkbox"
              id="modal_Invoice"
              className="modal-toggle"
            />
            <div className="modal">
              <div className="modal-box w-[390px] p-0 bg-white">
                <div className="flex justify-between flex-col md:flex-row px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]">
                  <h4 className="font-nunito font-semibold text-lg ">
                    Invoice Settings
                  </h4>
                  <div className="modal-action">
                    <label
                      htmlFor="modal_Unit"
                      className="cursor-pointer bg-red-600 text-white rounded-md"
                      onClick={handleOpen}
                    >
                      <img src={crose} width={23} height={20} alt="" />
                    </label>
                  </div>
                </div>
                <div className="px-5 pt-5 pb-[22px]">
                  <InvoiceSettingForm
                    initialValues={{ invoiceSetting }}
                    onSubmit={(values) => {
                      //const { ...updateValues } = values;
                      handleSubmitInvoiceSetting(values, invoiceSetting);
                    }}
                    handleChange={handleChange}
                    file={logoFile}
                    uploadedFilesWatermark={uploadedFilesWatermark}
                    currency={currency}
                    setUploadedFiles={setUploadedFiles}
                    setUploadedFilesWatermark={setUploadedFilesWatermark}
                    uploadDetails={uploadDetails}
                    setUploadDetails={setUploadDetails}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default TopHeader;
