
import React, { useContext, useEffect, useState } from 'react';
import { FaUsers } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../../context/context.js";
import axios from 'axios'
import ContentLayout from '../../layout/ContentLayout';
import TransferBox from './TransferBox.js';
import AddBalance from './AddBalance.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FlashMessage } from '../../utils/FlashMessage.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FundTransfer = () => {
  const {
    state,
    updatePaymentAccountList
  } = useContext(AppContext);

  const [dropDown, setDropDown] = useState(true);
  const [activeTab, setActiveTab] = useState("daily");
  const { currentUser, invoiceSetting } = state;
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false)

  const { paymentAccountList } = state;

  const [amount, setAmount] = useState(0);
  const [showError, setShowError] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectOption, setSelectOption] = useState({
    account_from: 0,
    account_to: 0,
    amount: 0
  })
  const [selectAddAmountOption, setSelectAddAmountOption] = useState({
    account_id: 0,
    amount: 0
  })


  const loadingCallback = (loading) => {
    setLoading(loading);
  };
  const navigate = useNavigate();

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const [fundTransfer, setFundTransfer] = useState([]);

  //category report//
  const getFundTransferReport = () => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}payment-type-category-list`,{
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadingCallback(false);
        if(response.data.success){
          setFundTransfer(response.data.data.data);
        }
        console.log("expene", response);
      })
      .catch((error) => {
        loadingCallback(false);
      });
  };
  useEffect(() => {
    getFundTransferReport();
    getPaymentAccountList();
  }, [activeTab]);

  const getPaymentAccountList = (page) => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}payment-type-category-list`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response.data.success){
        loadingCallback(false)
        updatePaymentAccountList(response.data.data);
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };

  const handleFundTransfer = () =>{
    console.log("value", fundTransfer, paymentAccountList.data);
    const findSelect = fundTransfer.findIndex((item)=> item.id==selectOption.account_to);
    console.log(Number(fundTransfer[findSelect].paymentcategory_sum_payment_amount));
    if(findSelect && Number(fundTransfer[findSelect].paymentcategory_sum_payment_amount)<selectOption.amount){
      FlashMessage("Empty Account Balance !", 'error')
      return;
    }
    loadingCallback(true)
    axios.post(
      `${process.env.REACT_APP_API_URL}save-fund-transfer`, selectOption ,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      loadingCallback(false)
      if(response.data.success){
        getFundTransferReport()
        FlashMessage("Balance transfer succesful!", 'success')
        setSelectOption({
          account_from: 0,
          account_to: 0,
          amount: 0
        })
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  }
  const handleAddFund = () =>{
    loadingCallback(true)
    axios.post(
      `${process.env.REACT_APP_API_URL}save-balance`, selectAddAmountOption ,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      loadingCallback(false)
      if(response.data.success){
        FlashMessage("Balance added succesful!", 'success')
        getFundTransferReport()
        setSelectAddAmountOption({
          account_id: 0,
          amount: 0
        })
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  }
  const color = ['#347AE2', "#FF7A00", "#9B54E1", "#29b170"];

  return (
    <>
      <ContentLayout>
      <ToastContainer />
        <div className="flex flex-row gap-[1%] pt-32  md:pt-0 pb-3 md:!pb-0 fixed md:static bg-[#EBF1FF] md:bg-transparent md:translate-x-0 top-0 left-0 pl-[30px] md:pl-0 w-full z-[9998]">
          <div
            onClick={() => handleTabClick("daily")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${activeTab === "daily"
              ? "border-[#407BFF] text-[#407BFF]"
              : "text-[#1C2F48] border-transparent"
              }`}
          >
            Daily
          </div>
          <div
            onClick={() => handleTabClick("weekly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${activeTab === "weekly"
              ? "border-[#407BFF] text-[#407BFF]"
              : "text-[#1C2F48] border-transparent"
              }`}
          >
            Weekly
          </div>
          <div
            onClick={() => handleTabClick("monthly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${activeTab === "monthly"
              ? "border-[#407BFF] text-[#407BFF]"
              : "text-[#1C2F48] border-transparent"
              }`}
          >
            Monthly
          </div>
          <div
            onClick={() => handleTabClick("yearly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${activeTab === "yearly"
              ? "border-[#407BFF] text-[#407BFF]"
              : "text-[#1C2F48] border-transparent"
              }`}
          >
            Yearly
          </div>
          <div
            onClick={() => handleTabClick("all")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${activeTab === "all"
              ? "border-[#407BFF] text-[#407BFF]"
              : "text-[#1C2F48] border-transparent"
              }`}
          >
            All
          </div>
        </div>
        <div className='mt-[100px] md:mt-10 font-lato'>
          <div className="rounded-[25px] bg-[#fff] p-5">
            {/* <div>
              <h1 className='text-2xl mb-5 mx-2 font-semibold'>Method Name</h1>
            </div> */}
             <div className='flex justify-between items-center cursor-pointer'
              onClick={() => setDropDown(!dropDown)}
             >
              <div className='text-[#000232] font-semibold '>
                <p className='text-[25px]'>Method Name</p>
              </div>
              <div  >
              <div className={`flex items-center justify-center w-[40px] h-[40px] transform duration-300 ${dropDown ? "rotate-180" : ""}`}>
                <FontAwesomeIcon icon={faAngleDown} className="text-[25px]" />
              </div>


              </div>
            </div>
            <div className="flex flex-col w-full">
            {dropDown && <div className='transform duration-500 pt-5'>
                <div className="category-card grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 lg:grid-cols-3 gap-3 rounded-2xl pb-4 col-p-full ">
                  {
                    fundTransfer.map((item, index)=>{
                      let debit = 0, credit = 0;
                      item?.paymentcategory?.map((paymentItem) => {
                        if (paymentItem?.status === 'Debit') {
                          debit += Number(paymentItem?.payment_amount);
                          return Number(paymentItem?.payment_amount);
                        }
                        return 0;
                      })
                      item?.paymentcategory?.map((paymentItem) => {
                        if (paymentItem?.status === 'Credit') {
                          credit += Number(paymentItem?.payment_amount);
                          return Number(paymentItem?.payment_amount);
                        }
                        return 0;
                      })
                      return (
                      // <div className={`shadow-[0_0_5px_rgba(0,0,0,0.1)] border-1 border-[ ${ color[index%3]}] text-white py-3  px-3 rounded-xl`}>
                      // <div className={`shadow-[0_0_5px_rgba(0,0,0,0.1)] border-1 border-[#347AE2] text-white py-3  px-3 rounded-xl`}>
                      <div className={`shadow-[0_0_5px_rgba(0,0,0,0.1)] border-1 border-[${color[ index%3]}] text-white py-3  px-3 rounded-xl`}>
                        <div className="space-y-1 ">
                          <p className={`text-[${color[ index%3]}] text-md`}>{item?.payment_category_name}</p>
                          <h4 className={`text-[${color[ index%3]}] text-lg`}>{item?.account_number}</h4>
                        </div>
                        <div className='my-2'>
                          <div className={`text-[${color[ index%3]}]`}>
                            <p className={`text-sm font-extralight text-[${color[ index%3]}]`}>Balance</p>
                            <h1 className='text-2xl font-semibold'>{item?.paymentcategory_sum_payment_amount ? Number.parseFloat(item?.paymentcategory_sum_payment_amount).toFixed(2) : 0}</h1>
                          </div>
                        <div className={`text-[${color[ index%3]}] flex flex-row gap-[10%] mt-2`}>
                          <div className=''>
                            <p className='text-sm'>Debit</p>
                            <p className='text-sm'>{Number.parseFloat(debit).toFixed(2)}</p>
                          </div>
                          <div>
                            <p className='text-sm'>Credit</p>
                            <p className='text-sm'>{Number.parseFloat(credit).toFixed(2)}</p>
                          </div>
                        </div>
                        </div>
                      </div>
                      )
                    })
                  }
                </div>
              </div>
            }
            </div>
          </div>

          <div className='my-5'>
            <TransferBox
              paymentAccountList={paymentAccountList}
              loadingCallback={loadingCallback}
              handleFundTransfer={handleFundTransfer}
              selectOption={selectOption}
              setSelectOption={setSelectOption}
            ></TransferBox>
          </div>

          <div className='my-5'>
            <AddBalance
              paymentAccountList={paymentAccountList}
              loadingCallback={loadingCallback}
              handleAddFund={handleAddFund}
              selectOption={selectAddAmountOption}
              setSelectOption={setSelectAddAmountOption}
            ></AddBalance>
          </div>

        </div>
        {loading && (
          <div className="custom-loader-logo">
            <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
          </div>
        )}

      </ContentLayout>
    </>
  );
};

export default FundTransfer;