
import './App.css';
import AppProvider from './context/context.js';
import FlashMessageProvider from './context/FlashMessageContext.js';
import routeConfiguration from './routeConfiguration';
import RouteComponent from './Routes';


function App() {
  return (
    <div className="App">
      <AppProvider>
        <FlashMessageProvider>
          <RouteComponent routes={routeConfiguration()} />
        </FlashMessageProvider>
      </AppProvider>
    </div>
  );
}

export default App;
