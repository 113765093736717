import React, { useContext, useEffect, useRef, useState } from 'react';
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { formatPrice, timeDiff,formatDate } from '../../utils/Common.js';
import ReturnListComponent from '../../components/Invoice/ReturnListComponent';
import { AppContext } from '../../context/context.js';
import ContentLayout from '../../layout/ContentLayout/index.jsx';
import { get, post } from '../../services/apiService.js';
import { useFlashMessage } from "../../context/FlashMessageContext";

import { useNavigate } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import Swal from 'sweetalert2';
import axios from "axios";

const BASE_URL = process.env.REACT_APP_ROOT_URL;
const SalesReturnListPage = () => {
    const { getFlashMessageStyle, setSuccessFlashMessage,flashMessage,setErrorFlashMessage } = useFlashMessage(); // Retrieve the dispatch function from your state management library
    const [warningMessage, setWarningMessage] = useState(false);
    const { state, isLoading, updateSalesReturnList } = useContext(AppContext);
    const { salesReturnList, invoiceSetting } = state;
    const token = localStorage.getItem('token');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [totalSaleReturnList, setTotalSaleReturnList] = useState([])
    const [checkboxes, setCheckboxes] = useState(salesReturnList); // Initialize the checkboxes state with an array


    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [statusUpdate, setStatusUpdate] = useState(false);
    const navigate = useNavigate();
    const perPage = 20;

    const [showNavMenu , setShowNavMenu] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);

    const [searchFilter, setSearchFilter] = useState({
        keyword: "",
        categoryId: false,
        subCategoryId: false,
        unitId: false,
        brandId: false,
        stockIn: false,
        stockOut: false,
        startDate: 0,
        endDate: new Date(),
    })

    useEffect(() => {
      getInvoicelist(currentPage,searchKeyword);
    }, [currentPage,searchKeyword,showModal,statusUpdate]);


    const getInvoicelist = (page,searchKeyword) => {
        if (searchKeyword !== "") {
            loadingCallback(true);
            const reqData = { keyword: searchKeyword };
            const data = {
                keyword: searchFilter.keyword,
                categoryId: searchFilter.categoryId,
                subCategoryId: searchFilter.subCategoryId,
                unitId: searchFilter.unitId,
                brandId: searchFilter.brandId,
                stockIn: searchFilter.stockIn,
                stockOut: searchFilter.stockOut,
            }
            get(`sales-return-search?search=${searchKeyword}&page=${page}&limit=${perPage}`, token)
            .then((response) => {
                loadingCallback(false);
                if(response.status === 200)
                {
                    const listData = response.data;


                    const transformedInvoiceArray = listData.map(item => ({
                        id: item.id,
                        icon: <RiMoneyDollarCircleLine/>,
                        name: item.invoice_id,
                        sname: item?.sales?.customer_name,
                        product: item.sales_details,
                        return_amount: invoiceSetting?.currency_info?.code ? formatPrice(Number(item.return_amount)) +' '+invoiceSetting.currency_info.code : formatPrice(Number(item.return_amount))+' BDT',
                        //due: invoiceSetting?.currency_info?.code ? (item.sub_total - item.paid_amount) +''+invoiceSetting.currency_info.code : (item.sub_total - item.paid_amount)+'BDT',
                        purchased_date: formatDate(new Date(item?.sales?.updated_at)),
                        time: formatDate(new Date(item.created_at)),
                        colors: "#00A3EE",
                        status: item.return_status,
                        return_id: item.return_id
                    }));
                    updateSalesReturnList(transformedInvoiceArray)
                    setCheckboxes(transformedInvoiceArray);
                    setStatusUpdate(false);
                }
            })
            .catch((error) => {
                console.log(error);
                loadingCallback(false);
                if (error?.response?.status === 401) {
                  localStorage.clear();
                  localStorage.setItem("token", false);
                  // Clear session and local storage
                  localStorage.setItem("isPinVerify", false);

                  navigate("/login");
                  // Navigate to the '/login' route
                }
            });
        } else {
            loadingCallback(true);
            get(`sales-return-list?page=${page}&limit=${perPage}`, token)
                .then((response) => {
                    loadingCallback(false);
                    console.log(response.data);
                    setTotalSaleReturnList(response.data);
                    if (response.data.data) {
                        const listData = response.data.data;


                        const transformedInvoiceArray = listData.map(item => ({
                            id: item.id,
                            icon: <RiMoneyDollarCircleLine/>,
                            name: item.invoice_id,
                            sname: item?.sales?.customer_name,
                            product: item.sales_details,
                            return_amount: invoiceSetting?.currency_info?.code ? formatPrice(Number(item.return_amount)) +' '+invoiceSetting.currency_info.code : formatPrice(Number(item.return_amount))+' BDT',
                            //due: invoiceSetting?.currency_info?.code ? (item.sub_total - item.paid_amount) +''+invoiceSetting.currency_info.code : (item.sub_total - item.paid_amount)+'BDT',
                            purchased_date: formatDate(new Date(item?.sales?.updated_at)),
                            time: formatDate(new Date(item.created_at)),
                            colors: "#00A3EE",
                            status: item.return_status,
                            return_id: item.return_id
                        }));
                        // console.log("============");
                        // console.log(listData);
                        updateSalesReturnList(transformedInvoiceArray)
                        setCheckboxes(transformedInvoiceArray);
                        setStatusUpdate(false);
                    }
                })
                .catch((error) => {
                  loadingCallback(false);
                  if (error?.response?.status === 401) {
                    localStorage.clear();
                    localStorage.setItem("token", false);
                    // Clear session and local storage
                    localStorage.setItem("isPinVerify", false);

                    navigate("/login");
                    // Navigate to the '/login' route
                  }
                });
        }
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const handleButtonClick = () => {
        console.log("Clicked");
        setShowNavMenu((prevShowNavMenu) => !prevShowNavMenu);
      };
      const handleClickOutside = (event) => {
        if (
          (menuRef.current && !menuRef.current.contains(event.target)) &&
          (buttonRef.current && !buttonRef.current.contains(event.target))
        ) {
          setShowNavMenu(false);
        }
      };

      useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
        document.removeEventListener("click", handleClickOutside);
        };
      }, []);

    const shouldRenderPagination = totalSaleReturnList?.total > perPage;




    const handleShowModal = () => setShowModal(!showModal);

    const [loading, setLoading] = useState(false);
    const loadingCallback = loading => {
        setLoading(loading);
    };

    const showModalBeforeDelete = (id, index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteData(id, index);
            }
        });
    }
    const showModalBeforeChange = async (item, checkboxId,index) => {
      const result = await Swal.fire({
        title: 'Are you sure you want to change receiving status?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes!'
      });

      const checkbox = document.getElementById(checkboxId);

      if (result.isConfirmed) {
        item.status='0'
        //invoice.status='0'
        //checkbox.checked = false;
        //checkbox.disabled = true;

        try {

          loadingCallback(true);

          const invoiceData = {
            return_id: checkboxId.replace(/^product_/i, '')
          }
          const response = await axios.post(`${process.env.REACT_APP_API_URL}change-sales-return-invoice-status`, invoiceData, {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });

          if (response.status === 200) {

            loadingCallback(false);
            const updatedCheckboxes = checkboxes.map((checkbox) => {
              if (checkbox.return_id === checkboxId.replace(/^product_/i, '')) {
                return { ...checkbox, checked: false, disabled: true };
              }
              return checkbox;
            });

            setCheckboxes(updatedCheckboxes);

            Swal.fire({
                icon: 'success',
                title: 'Receiving status changed!',
                showConfirmButton: false,
                timer: 1500 // Adjust the timer as needed
            });
          }

        } catch (error) {
          console.error('An error occurred while making the API call', error);
          loadingCallback(false);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'An unexpected error occurred'
          });

          item.status='1'
          //checkbox.checked = true;
          const updatedCheckboxes = checkboxes.map((checkbox) => {
            if (checkbox.return_id === checkboxId.replace(/^product_/i, '')) {
              return { ...checkbox, checked: true, disabled: false };
            }
            return checkbox;
          });

          setCheckboxes(updatedCheckboxes);
        }
      }  else {
        item.status = '1';
        //setStatusUpdate(true);
        //checkbox.checked = true;
        const updatedCheckboxes = checkboxes.map((checkbox) => {
          if (checkbox.return_id === checkboxId.replace(/^product_/i, '')) {
            return { ...checkbox, checked: true, disabled: false };
          }
          return checkbox;
        });

        setCheckboxes(updatedCheckboxes);
      }

    }
    const deleteData = (id, index) => {
        loadingCallback(true);
        const reqData = { invoice_id: id };
        // Use your API service functions to make API requests here
        get(`delete-sales-return/${id}`, token)
            .then((response) => {
                loadingCallback(false);
                if (response.success) {
                    console.log("invoice deleted");
                    //let cartProducts = [...salesReturnList];

                    //cartProducts.splice(index, 1);
                    //updateSalesReturnList(cartProducts);
                    setStatusUpdate(true);
                    //window.location.reload();

                } else {
                    console.log("booking delete failed");
                }
            })
            .catch((error) => {
                loadingCallback(false);
                if (error?.response?.status === 401) {
                    localStorage.clear();
                    localStorage.setItem('token', false);
                    // Clear session and local storage
                    localStorage.setItem('isPinVerify', false);

                    navigate('/login');
                }
                console.log(error);
            });
    }
    // console.log("============");
    // console.log(salesReturnList);
    //please call a component and pass the data as props and pass type as sales so i can use same component for purchase type
    return (
        <ContentLayout>
            <div className="main-content recentTableContent">
                {flashMessage && (
                    <div className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(flashMessage.type)}`}>
                    {flashMessage.text}
                    </div>
                )}
                {warningMessage && (
                    <div className="warning-message">
                    something wrong!
                    </div>
                )}
                <div className="flex flex-col w-full">

                    <ReturnListComponent
                        ReturnListData={checkboxes}
                        totalReturnList={totalSaleReturnList}
                        showModalBeforeDelete={showModalBeforeDelete}
                        showModalBeforeChange={showModalBeforeChange}
                        showModal={showModal}
                        setShowModal={setShowModal}
                        handleShowModal={handleShowModal}
                        loadingCallback={loadingCallback}
                        navigate={navigate}
                        setSearchKeyword={setSearchKeyword}
                        searchKeyword={searchKeyword}
                        shouldRenderPagination={shouldRenderPagination}
                        currentPage={currentPage}
                        onPageChange={onPageChange}
                        loading={loading}
                        invoiceSetting={invoiceSetting}
                        type="salesReturnList"
                        state={state}
                        setWarningMessage={setWarningMessage}
                        title="Sale Return List"
                        modalTitle="Add Sale Return"
                        handleButtonClick={handleButtonClick}
                        buttonRef={buttonRef}
                        showNavMenu={showNavMenu}
                        setSearchFilter={setSearchFilter}
                        searchFilter={searchFilter}
                        menuRef={menuRef}
                    />
                </div>
            </div>
            {loading && (

              <div className="custom-loader-logo">
                {/* <BeatLoader color={"#123abc"} size={20} /> */}
                <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
              </div>
            )}
        </ContentLayout>
    );
};

export default SalesReturnListPage;
