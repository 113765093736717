
import React, { useContext, useState, useRef, useEffect } from "react";
import Breadcrumb from '../Settings_components/Breadcrumb';

import { AppContext } from '../../../context/context.js';
import Pagination from '../../../utils/pagination';

import axios from "axios";
import PaymentTableItems from "../Settings_components/PaymentTableItems.js";
import { useNavigate } from "react-router-dom";
const PaymentSettings = () => {
  const {
      state,
      isLoading,
      updatePaymentList,
      updatePaymentAccountList
  } = useContext(AppContext);

  const { paymentList, paymentAccountList } = state;
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 20;

  useEffect(() => {
      getPayment(currentPage);
      getPaymentAccountList(0)
  }, [currentPage]);
  const onPageChange = (page) => {
      setCurrentPage(page);
  }

  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
      setLoading(loading);
  };

  const getPayment = (page) => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}payment-type-list`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response.data.success)
      {
        loadingCallback(false)
        //setBrands(response.data.data.data);
        console.log(response.data.data);
        updatePaymentList(response.data.data)
        console.log("============");
        console.log(response.data.data);
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };
  const getPaymentAccountList = (page) => {
    const token = localStorage.getItem("token");
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}payment-type-category-list`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response.data.success){
        loadingCallback(false)
        updatePaymentAccountList(response.data.data);
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };
  const shouldRenderPagination = state.paymentList?.total > perPage;

  return (
    <div className='rounded-[27px]'  style={{backgroundColor: 'rgba(0, 184, 148, 0.15)' }}>
      <Breadcrumb></Breadcrumb>

      <PaymentTableItems loading={loading} data={paymentList} accountData={paymentAccountList.data} getPayment={getPayment} getPaymentAccountList={getPaymentAccountList}></PaymentTableItems>
        {/* {shouldRenderPagination && (
          <Pagination
            currentPage={currentPage}
            lastPage={state.paymentList.last_page}
            onPageChange={onPageChange}
          />
        )} */}
        <div className="w-full relative px-10 md:px-[27px] overflow-x-auto sm:rounded-lg">
          {loading && (
            <div className="custom-loader"></div>
          )}
        </div>
    </div>
  );
};

export default PaymentSettings;