import React from 'react';
import { formatDate, formatPrice, timeDiff } from '../../utils/Common';

const BalanceHistoryPDFDetailss = (props) => {
  const {
    balanceData,
    paymentList,
    paymentAccountList,
    tabActive,
    index,
    setPos,
  } = props;

  // setPos(index+1);

  const paymentData = paymentList.data ? paymentList.data : paymentList;
  const paymentAccountData = paymentAccountList.data ? paymentAccountList.data : paymentAccountList;

  const createdDate = new Date(balanceData?.created_at);

  return (
    <>
      {tabActive &&
      <div className="border-b-[1px] text-center text-sm border-solid  border-[#E7EBF4] py-[13px] font-nunito grid grid-cols-10 items-start">

      <div className='text-center col-span-1 text-[#000] font-[600]'>
        {index+1}
      </div>

      <div className="text-center col-span-2">
        <h1 className=" text-[#000] font-[600]">{formatDate(balanceData?.created_at)}</h1>
        <p className=" text-[#87889E] font-[500] text-sm">{timeDiff(createdDate)}</p>
      </div>

      <div className="text-[#000] font-[500] text-center col-span-2">
      <p>{balanceData?.payment_type?.type_name}</p>
      <p>{balanceData?.payment_type_category?.payment_category_name}</p>
      <p>{balanceData?.payment_type_category?.account_number}</p>
      </div>


      <div className="text-[#000] font-semibold text-center col-span-1">
        <p className="text-[#000] font-[500] text-md">{balanceData?.status}</p>
      </div>


      <div className="text-[#000] font-[500] text-center col-span-1">
        <p className="text-[#000] font-[500] text-md">{balanceData?.type}</p>
      </div>

      <div className=" text-[#000] font-[500] text-center col-span-2">{balanceData?.invoice_id}</div>

      <div className=" text-[#000] font-[500]  text-center col-span-1">{Number.parseFloat(balanceData?.payment_amount).toFixed(2)}</div>
    </div>

      }

    </>
  );
};
BalanceHistoryPDFDetailss.defaultProps = { className: null };
export default BalanceHistoryPDFDetailss;