import React, { useEffect, useState } from "react";

import ContentLayout from "../../layout/ContentLayout";
import VendorInvoiceHistory from "../../components/VendorInvoiceHistory/index";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { useFlashMessage } from '../../context/FlashMessageContext';
import { FlashMessage } from "../../utils/FlashMessage";
import { ToastContainer, toast } from 'react-toastify';

const WholesalerInvoiceHistoryPage = () => {
  const [activeTab, setActiveTab] = useState("daily");
  const { getFlashMessageStyle, setSuccessFlashMessage, flashMessage, setErrorFlashMessage } = useFlashMessage(); // Retrieve the dispatch function from your state management library
  const [warningMessage, setWarningMessage] = useState(false);
  const [dueCollectionData, setDueCollectionData] = useState({});
  const [invoiceData, setInvoiceData] = useState({});
  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };

  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [vendordData, setVendordData] = useState({});
  const [fetchedData, setFetchedData] = useState({});

  const { id } = useParams();// vendor id
  const perPage = 1000;
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    getVendorProfileData(activeTab);
    getInvoiceData(activeTab, currentPage);
    getVendorProfileDueCollection(activeTab);
  }, [activeTab]);
  const getVendorProfileDueCollection = (activeTab) => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}whole-salller-due-collection/${id}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          const fetchedData = response.data.data;
          setDueCollectionData(fetchedData);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
          // Navigate to the '/login' route
        }
      });
  };
  const getInvoiceData = (activeTab, page) => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}whole-sellers-wise-invoice/${id}?interval=${activeTab}&page=${page}&limit=${perPage}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          const responseInvoiceData = response.data.data.data;
          setInvoiceData(responseInvoiceData);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
          // Navigate to the '/login' route
        }
      });
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const getVendorProfileData = (activeTab) => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}whole-sellers-profile/${id}?interval=${activeTab}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          const fetchedData = response.data.data;
          setFetchedData(fetchedData);
          setVendordData(fetchedData);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
          // Navigate to the '/login' route
        }
      });
  };
  const [hideModal, setHideModal] = useState(false);

  const handleSubmitPayment = (data) => {
    loadingCallback(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}due-collection`,
        data,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        loadingCallback(false);
        if (response.data.success) {
          //setInvoice(response.data.data);
          setHideModal(true);
          console.log(response.data.data);
          // setSuccessFlashMessage("Due payment submitted successfully.");
          FlashMessage("Due payment submitted successfully.", 'success');
          getVendorProfileData(activeTab);
          getInvoiceData(activeTab, currentPage);
          getVendorProfileDueCollection(activeTab);

        } else {
          if (response.data.message) {
            // setErrorFlashMessage('something went wrong!');
            FlashMessage('something went wrong!','error');
          }
          if (response.data.errors) {
            // setErrorFlashMessage(response.data.errors?.customer_id[0]);
            FlashMessage(response.data.errors?.customer_id[0], 'error');
          }
        }
      })
      .catch((error) => {
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem('token', false);
          // Clear session and local storage
          localStorage.setItem('isPinVerify', false);

          navigate('/login');
        }
      });
  }

  return (
    <>
      <ContentLayout>
      <ToastContainer />
        <div className="mb-[30px] flex flex-row gap-[1%] font-nunito pt-32 md:pt-0 pb-3 md:!pb-0 fixed md:static bg-[#EBF1FF] md:bg-transparent md:translate-x-0 top-0 left-0 pl-[30px] md:pl-0 w-full z-[9998]">
          <div
            onClick={() => handleTabClick("daily")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold ${
              activeTab === "daily"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Daily
          </div>
          <div
            onClick={() => handleTabClick("weekly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold ${
              activeTab === "weekly"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Weekly
          </div>
          <div
            onClick={() => handleTabClick("monthly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold ${
              activeTab === "monthly"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Monthly
          </div>
          <div
            onClick={() => handleTabClick("yearly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold ${
              activeTab === "yearly"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Yearly
          </div>
        </div>

        <div className="tab-content mt-24 md:mt-0">
          {warningMessage && (
            <div className="warning-message">
              something wrong!
            </div>
          )}
          {flashMessage && (
            <div className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(flashMessage.type)}`}>
              {flashMessage.text}
            </div>
          )}
          {activeTab && (
            <>
              <VendorInvoiceHistory
                activeTab={activeTab}
                loadingCallback={loadingCallback}
                vendordData={vendordData}
                fetchedData={fetchedData}
                vendorId={id}
                invoiceData={invoiceData}
                hideModal={hideModal}
                onSubmitPayment={handleSubmitPayment}
                type="wholesaler"
                dueCollectionData={dueCollectionData}
                setSuccessFlashMessage={setSuccessFlashMessage}
                setErrorFlashMessage={setErrorFlashMessage}
                setWarningMessage={setWarningMessage}
              />
            </>
          )}
        </div>

        {loading && (
          <div className="custom-loader-logo">
            <img
              className=""
              src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
              alt="logo"
            />
          </div>
        )}
      </ContentLayout>
    </>
  );
};

export default WholesalerInvoiceHistoryPage;
