
import React, { useEffect, useState, useRef } from "react";
import Select from 'react-select';

import './select.css';
import { logDOM } from "@testing-library/react";

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '10px',
    borderColor: '#C1CFEF',
    width: '100%',
    //boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#C1CFEF' : 'white',
    color: '#102048',
    '&:hover': {
      backgroundColor: '#C1CFEF',
    },
  }),
};

const ItemSelectBox = ({ onInputChangeData,label, placeholder, className, name, options, value, onChange, error, setFormErrors = false }) => {

  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef(null);

  const handleOptionClick = (option) => {
    setIsOpen(false);
  };

  const handleDelayedOptions = () => {
    setTimeout(() => {
      setIsOpen(true);
    }, 200);
  };

  useEffect(() => {
    console.log(options)
    if (options?.length > 10) {
      //handleDelayedOptions();
    }
  }, []);

  const errorClass = error ? " border-1 border-red-500" : " border ";

  let selectedOption = { label: placeholder, value: value };

  if (value && options?.length > 0) {
    selectedOption = options?.map((option) => ({ value: option.id, label: option.name }))
      .find((option) => option.value === value);
  }
  const placeholderOption = {id: value, name: placeholder };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
    if (!isOpen) {
      setTimeout(() => {
      }, 500);
    }
  };

  return (
    <div className={`${className} w-full relative`}>
      <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
        {label}
      </label>
      <Select
        name={name}
        onChange={(event) => {
          onChange(event);
          /* if (setFormErrors) {
            setFormErrors({ name: null });
          } */
        }}
        className={` select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none px-[0px]  text-md focus:outline-none ${
          setFormErrors ? " border-1 border-red-500" : ""
        }`}
        options={[
          placeholder ? { value: placeholderOption.id, label: placeholderOption.name } : { value: "", label: "Select" },
          ...(Array.isArray(options)
            ? options?.map((option) => (
              option?.sale_invoice_id
                ? { value: option?.sale_invoice_id, label: `${option?.sale_invoice_id} (Total Due: ${option?.total_due?.toFixed(2)})` }
                : option?.purchase_invoice_id
                ? { value: option?.purchase_invoice_id, label: `${option?.purchase_invoice_id} (Total Due: ${option?.total_due?.toFixed(2)})` }
                : option?.exporter_invoice_id
                ? { value: option?.exporter_invoice_id, label: `${option?.exporter_invoice_id} (Total Due: ${option?.total_due?.toFixed(2)})` }
                :  option?.carrier_invoice_id
                ? { value: option?.carrier_invoice_id, label: `${option?.carrier_invoice_id} (Total Due: ${option?.total_due?.toFixed(2)})` }
                : { value: option.id, label: option.type_name ? option.type_name : option.payment_category_name }
            ))
            : []),
          ...(isOpen && Array.isArray(options)
            ? options?.map((option) => (
              { value: option.id, label: option.type_name?option.type_name: option.payment_category_name }
              ))
            : []),
        ]}
        // value={selectedOption}
        styles={customStyles}
        placeholder={placeholder}
        isSearchable
        // onMenuOpen={() => handleDelayedOptions()}
        onMenuClose={() => setIsOpen(false)}
        onInputChange={(input) => {
          if (onInputChangeData) {
            onInputChangeData(input);
          }
        }}
      />
      {setFormErrors && <div className="text-red-500 text-sm mt-1">{error}</div>}
    </div>
  );
};
ItemSelectBox.defaultProps = {
  className: ''
};
export default ItemSelectBox;
