import React, { useContext, useEffect, useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { timeDiff, formatPrice } from "../../utils/Common";
import Pagination from "../../utils/pagination"; // Adjust the path accordingly

import DatePicker from "react-datepicker";
import ContentLayout from "../../layout/ContentLayout";

import { useNavigate } from "react-router-dom";
import { useFlashMessage } from "../../context/FlashMessageContext";
import { BiPlus } from "react-icons/bi";

import { Modal } from "../../utils/Modal.js";
import axios from "axios";
import ChartOfExpenseItem from "../../components/Invoice/ChartOfExpenseItem.js";
import { get, post } from "../../services/apiService.js";


const ExpenseCategory = () => {
  const { flashMessage, getFlashMessageStyle } = useFlashMessage();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };
  const token = localStorage.getItem("token");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;

  const [showNavMenu, setShowNavMenu] = useState(false);

  const [warningMessage, setWarningMessage] = useState("");
  const [showAddExpenseModal, setShowAddExpenseModal] = useState(false);
  const [allFetchedData,setAllFetchedData] = useState([]);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);


  const onPageChange = (page) => {
    setCurrentPage(page);
  };


  const handleButtonClick = () => {
    console.log("Clicked");
    setShowNavMenu((prevShowNavMenu) => !prevShowNavMenu);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setShowNavMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  //
  //
  //fetching data for transaction type
  const [refetch, setRefetch] = useState(false)
  const [expenses, setExpenses] = useState([]);

  useEffect(() => {
    getExpenseList(currentPage,searchKeyword);
  }, [refetch,currentPage,searchKeyword]);

  const getExpenseList = (page,searchKeyword) => {
    if(searchKeyword !==""){
      loadingCallback(true);
      const reqData = { keyword: searchKeyword };
      post(`search-expense-type?page=${page}`, reqData, token)
      .then((response) => {
        loadingCallback(false);
        if(response.status === 200)
        {
          setExpenses(response.data.data);
          setAllFetchedData(response.data);
          }
      })
      .catch((error) => {
        loadingCallback(false);
          console.log(error);
      });
    }

    else {
      loadingCallback(true);
    get(`expense-type-list?page=${page}`, token)
      .then((response) => {
        loadingCallback(false);
        if (response.success) {
          setExpenses(response.data.data);
          setAllFetchedData(response.data);
        }
      })
      .catch((error) => {
        loadingCallback(false);
        console.log(error);
        localStorage.clear();
        localStorage.setItem("token", false);
        // Clear session and local storage
        localStorage.setItem("isPinVerify", false);
        navigate("/login");
      });
  };
  }



  const [showViewModal, setShowViewModal] = useState(false);
  const [formData, setFormData] = useState({
    expense_name:"",
    expense_description:""
  });



  useEffect(() => {
    if (showViewModal) {
      const paymentModalLabel = document.getElementById("my_modal_payment");
      if (paymentModalLabel) {
        paymentModalLabel.click();
      }
    }
  }, [showViewModal]);

  const shouldRenderPagination = allFetchedData?.total > perPage;

  return (
    <>
      <ContentLayout>
        <div className="main-content recentTableContent">
          <div className="flex flex-col w-full">
            <div className="flex flex-col md:flex-row justify-between mb-4">
              <div>
                <h1 className="text-[#000232] text-[22px] font-[600] font-lato mb-2">
                  Party list
                </h1>
              </div>

              <div className="flex gap-2 justify-center">
                <div className="flex relative  w-[95%]">
                  <input
                    autoFocus
                    type="text"
                    className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[28px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                    placeholder="Search Expenses"
                    onChange={(e) => {
                      setSearchKeyword(e.target.value);
                    }}
                  />

                  <div className="absolute top-[37%] left-[10px]">
                    <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                  </div>

                  <div
                    ref={buttonRef}
                    onClick={handleButtonClick}
                    className="absolute top-[36%] right-[10px] cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M0.83268 12.6665C0.83268 12.3932 1.05935 12.1665 1.33268 12.1665L8.66602 12.1665C8.93935 12.1665 9.16602 12.3932 9.16602 12.6665C9.16602 12.9398 8.93935 13.1665 8.66602 13.1665L1.33268 13.1665C1.05935 13.1665 0.83268 12.9398 0.83268 12.6665Z"
                        fill="#102048"
                      />
                      <path
                        d="M10.8327 12.6665C10.8327 12.3932 11.0594 12.1665 11.3327 12.1665L14.666 12.1665C14.9393 12.1665 15.166 12.3932 15.166 12.6665C15.166 12.9398 14.9393 13.1665 14.666 13.1665L11.3327 13.1665C11.0594 13.1665 10.8327 12.9398 10.8327 12.6665Z"
                        fill="#102048"
                      />
                      <path
                        d="M0.832684 8C0.832684 7.72667 1.05935 7.5 1.33268 7.5L4.66602 7.5C4.93935 7.5 5.16602 7.72667 5.16602 8C5.16602 8.27333 4.93935 8.5 4.66602 8.5L1.33268 8.5C1.05935 8.5 0.832684 8.27333 0.832684 8Z"
                        fill="#102048"
                      />
                      <path
                        d="M6.83268 8C6.83268 7.72667 7.05935 7.5 7.33268 7.5L14.666 7.5C14.9393 7.5 15.166 7.72667 15.166 8C15.166 8.27333 14.9393 8.5 14.666 8.5L7.33268 8.5C7.05935 8.5 6.83268 8.27333 6.83268 8Z"
                        fill="#102048"
                      />
                      <path
                        d="M0.83268 3.3335C0.83268 3.06016 1.05935 2.8335 1.33268 2.8335L8.66602 2.8335C8.93935 2.8335 9.16602 3.06016 9.16602 3.3335C9.16602 3.60683 8.93935 3.8335 8.66602 3.8335L1.33268 3.8335C1.05935 3.8335 0.83268 3.60683 0.83268 3.3335Z"
                        fill="#102048"
                      />
                      <path
                        d="M10.8327 3.3335C10.8327 3.06016 11.0594 2.8335 11.3327 2.8335L14.666 2.8335C14.9393 2.8335 15.166 3.06016 15.166 3.3335C15.166 3.60683 14.9393 3.8335 14.666 3.8335L11.3327 3.8335C11.0594 3.8335 10.8327 3.60683 10.8327 3.3335Z"
                        fill="#102048"
                      />
                      <path
                        d="M8.16602 4.66667L8.16602 2C8.16602 1.72667 8.39268 1.5 8.66602 1.5C8.93935 1.5 9.16602 1.72667 9.16602 2L9.16602 4.66667C9.16602 4.94 8.93935 5.16667 8.66602 5.16667C8.39268 5.16667 8.16602 4.94 8.16602 4.66667Z"
                        fill="#102048"
                      />
                      <path
                        d="M8.16602 14.0002L8.16602 11.3335C8.16602 11.0602 8.39268 10.8335 8.66602 10.8335C8.93935 10.8335 9.16602 11.0602 9.16602 11.3335L9.16602 14.0002C9.16602 14.2735 8.93935 14.5002 8.66602 14.5002C8.39268 14.5002 8.16602 14.2735 8.16602 14.0002Z"
                        fill="#102048"
                      />
                      <path
                        d="M6.83398 9.33317L6.83398 6.6665C6.83398 6.39317 7.06065 6.1665 7.33398 6.1665C7.60732 6.1665 7.83398 6.39317 7.83398 6.6665L7.83398 9.33317C7.83398 9.6065 7.60732 9.83317 7.33398 9.83317C7.06065 9.83317 6.83398 9.6065 6.83398 9.33317Z"
                        fill="#102048"
                      />
                    </svg>
                  </div>

                </div>
                <label
                  htmlFor="add_Expense_Modal"
                  onClick={() => setShowAddExpenseModal((val) => !val)}
                  className="bg-[#407BFF] rounded-[5px] text-[#FFF] text-sm font-nunito font-bold py-2.5 pl-[22px] pr-[24px] flex items-center gap-2"
                >
                  <BiPlus className="text-xl "></BiPlus>
                </label>
              </div>
            </div>

            <div className="table-conatiner">
              {/* <div className='grid grid-cols-4 sm:grid-cols-5'> */}
              <div className="grid grid-cols-3 gap-2 items-center font-nunito font-[700] text-[14px] text-[#000232]">
                <div className="py-[8px]">Name</div>
                <div className="py-[8px] text-center">Cost Amount</div>
                <div className="py-[8px] text-right">Action</div>
              </div>
              {flashMessage && (
                <div
                  className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(
                    flashMessage.type
                  )}`}
                >
                  {flashMessage.text}
                </div>
              )}
              {loading && (
                <div className="custom-loader-logo">
                  {/* <BeatLoader color={"#123abc"} size={20} /> */}
                  <img
                    className=""
                    src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
                    alt="logo"
                  />
                </div>
              )}
              {expenses?.map((expenseType, index) => (
                    <ChartOfExpenseItem
                      key={index}
                      index={index}
                      loadingCallback={loadingCallback}
                      navigate={navigate}
                      loading={loading}
                      txnType={expenseType}
                      refetch={()=>setRefetch(val=>!val)}
                      // account={acc}
                    />
               )) }

              <div className="hrtable"></div>
              {/* Pagination component */}
              {shouldRenderPagination && (
                // {invoiceData.length > 2 && (
                <Pagination
                  // currentPage={invoiceList.current_page}
                  lastPage={allFetchedData?.last_page}
                  currentPage={currentPage}
                  //lastPage={10}
                  onPageChange={onPageChange}
                />
              )}
            </div>
          </div>
        </div>



        {/* expense category modal  */}
        {showAddExpenseModal ? (
          <Modal
            loadingCallback={loadingCallback}
            navigate={navigate}
            isOpen={showAddExpenseModal}
            setOpen={setShowAddExpenseModal}
            title="Create Party"
            formId="add_Expense_Modal"
            type="addExpenseType"
            selectType="expense"
            refetch={()=>setRefetch(val=>!val)}
            formData={formData}
            setFormData={setFormData}
            setWarningMessage={setWarningMessage}
          />
        ) : (
          ""
        )}
      </ContentLayout>
    </>
  );
};

export default ExpenseCategory;
