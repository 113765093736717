import React, { useContext, useEffect, useRef, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { timeDiff,formatPrice } from '../../utils/Common';
import Pagination from '../../utils/pagination'; // Adjust the path accordingly
import { AppContext } from '../../context/context.js';
import DatePicker from "react-datepicker";
import ContentLayout from '../../layout/ContentLayout';
import { get, post } from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import CustomerListItem from '../../components/Invoice/CustomerListItem.js';
import { useFlashMessage } from '../../context/FlashMessageContext';
import { BiPlus } from "react-icons/bi";
import { Modal } from "../../utils/Modal";
import DepartmentItem from "../../components/HRM/DepartmentItem.js";

const BASE_URL = process.env.REACT_APP_ROOT_URL;

const DepartmentPage = () => {
  const { flashMessage, getFlashMessageStyle } = useFlashMessage();
  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };
  const [refetch, setRefetch] = useState(false)
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;
  //--------
  const [searchFilter, setSearchFilter] = useState({
    keyword: "",
  })
  const { state, isLoading, updateDepartmentList } = useContext(AppContext);
  //------
  useEffect(()=>{
    getDepartmentList(currentPage)
  },[currentPage, searchFilter])

  const [departmentList, setDepartmentList] = useState([]);

  const getDepartmentList = (page, searchKeyword) => {
    if (searchFilter.keyword !== "") {
      const data = {
        keyword: searchFilter.keyword,
      }
      loadingCallback(true);
      post(`search-department?page=${page}&limit=${perPage}`, data, token)
        .then((response) => {
          loadingCallback(false);
          if (response.status === 200) {
            const listData = response.data.data;
            setDepartmentList(listData)
            console.log("department names after search", listData)

            const transformedEmployeeArray = listData.map(item => ({
              id: item.id,
              name: item.name,
            }));
            updateDepartmentList(transformedEmployeeArray)
          }
        })
        .catch((error) => {
          loadingCallback(false);
          console.log(error);
          localStorage.clear();
          localStorage.setItem("token", false);
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        });
    } else{
      loadingCallback(true);
      get(`department?page=${page}&limit=${perPage}`, token)
        .then((response) => {
          loadingCallback(false);
          if (response.success) {
            console.log("department list before search", response.data.data)
            const listData= response.data.data
            setDepartmentList(listData)
            const transformedEmployeeArray = listData.map(item => ({
              id: item.id,
              name: item.name,
            }));
            updateDepartmentList(transformedEmployeeArray)
          }
        })
        .catch((error) => {
          loadingCallback(false);
          console.log( error);
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        });
    }
  };
  //---------
  const [warningMessage, setWarningMessage] = useState(false);
  const [showAddDepartmentModal, setShowAddDepartmentModal] = useState(false);
  const [formData, setFormData] = useState({});
  return (
    <>
      <ContentLayout>
      <div className="main-content recentTableContent">
        <div className="flex flex-col w-full">
                <div className='flex flex-col md:flex-row justify-between mb-4'>
                  <div>
                    <h1 className='text-[#000232] text-[22px] font-[600] font-lato mb-2'>Department List</h1>
                  </div>
                  <div className='flex gap-2 justify-center'>
                    <div className="flex relative  w-[95%]">
                      <input
                        autoFocus
                        type="text"
                        className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[28px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                        placeholder="Search Department"
                        onChange={(e) => {
                          setSearchFilter({...searchFilter, keyword: e.target.value})
                        }}
                      />
                      <div className="absolute top-[37%] left-[10px]">
                        <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                      </div>
                    </div>
                    <button
                      htmlFor="add_department_modal"
                      onClick={() => setShowAddDepartmentModal((val) => !val)}
                      className="bg-[#407BFF] rounded-[5px] text-[#FFF] text-sm font-nunito font-bold py-2.5 pl-[22px] pr-[24px] flex items-center gap-2"
                    >
                      <BiPlus className="text-xl "></BiPlus>
                    </button>
                  </div>
                </div>
        </div>
        <div className="table-conatiner">
              <div className="grid grid-cols-3 gap-2 items-center font-nunito font-[700] text-[14px] text-[#000232]">
                <div className="py-[8px]">SL No</div>
                <div className="py-[8px] text-center">Name</div>
                <div className="py-[8px] text-right">Action</div>
              </div>
        </div>
        {flashMessage && (
                <div
                  className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(
                    flashMessage.type
                  )}`}
                >
                  {flashMessage.text}
                </div>
          )}
          {loading && (
                <div className="custom-loader-logo">
                  <img
                    className=""
                    src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
                    alt="logo"
                  />
                </div>
          )}
          <div className="max-h-[70vh] overflow-y-auto">
              {departmentList
                    ? departmentList.map((item, index) => (
                      <DepartmentItem
                        key={item.id}
                        index={index}
                        loadingCallback={loadingCallback}
                        navigate={navigate}
                        departmentitem={item}
                        loading={loading}
                      />
                      ))
                    : null}


                <div className="hrtable"></div>
            </div>
          </div>
          {showAddDepartmentModal ? (
          <Modal
          loadingCallback={loadingCallback}
          navigate={navigate}
          isOpen={showAddDepartmentModal}
          setOpen={setShowAddDepartmentModal}
          title="Add Department"
          formId="add_department_modal"
          type="addDepartmentModal"
          selectType="department"
          refetch={()=>setRefetch(val=>!val)}
          formData={formData}
          setFormData={setFormData}
          setWarningMessage={setWarningMessage}
          />
        ) : (
          ""
        )}

      </ContentLayout>
    </>
  );
};

export default DepartmentPage;
