import React, { useContext, useEffect, useState } from "react";
import ContentLayout from "../../layout/ContentLayout";
import "./index.css";
import { AppContext } from "../../context/context.js";

import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { BeatLoader } from 'react-spinners'
import PurchaseTable from '../../components/home/PurchaseTable/PurchaseTable'
import ChatPopup from '../../components/home/calculator/ChatPopup/ChatPopup'
import GraphChart from '../../components/home/graphchart/Index'
import RecentOrdersTable from '../../components/home/orderstable/Index'
import SellingCard from '../../components/home/sellingcards/Index'
import TotalCards from '../../components/home/totalcards/Index'

const pdata = [
  {
    name: "Page A",
    uv: 350,
    pv: 350,
    amt: 1400,
  },
  {
    name: "Page B",
    uv: 280,
    pv: 280,
    amt: 1210,
  },
  {
    name: "Page C",
    uv: 150,
    pv: 150,
    amt: 1290,
  },
  {
    name: "Page D",
    uv: 300,
    pv: 300,
    amt: 1000,
  },
  {
    name: "Page E",
    uv: 540,
    pv: 540,
    amt: 1181,
  },
  {
    name: "Page F",
    uv: 430,
    pv: 430,
    amt: 1500,
  },
  {
    name: "Page G",
    uv: 400,
    pv: 400,
    amt: 1100,
  },
  {
    name: "Page E",
    uv: 500,
    pv: 500,
    amt: 1110,
  },
  {
    name: "Page F",
    uv: 300,
    pv: 300,
    amt: 1120,
  },
  {
    name: "Page G",
    uv: 180,
    pv: 180,
    amt: 1180,
  },
];

const revenueDataState = {
  series: [
    {
      name: "series1",
      data: [390, 400, 280, 351, 412, 109, 100, 390, 400, 280, 351, 412],
    },
  ],
  options: {
    chart: {
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    xaxis: {
      labels: {
        format: "MMM",
      },
      type: "datetime",
      categories: [
        "2018-01-19T00:00:00.000Z",
        "2018-02-19T01:30:00.000Z",
        "2018-03-19T02:30:00.000Z",
        "2018-04-19T03:30:00.000Z",
        "2018-05-19T04:30:00.000Z",
        "2018-06-19T05:30:00.000Z",
        "2018-07-19T06:30:00.000Z",
        "2018-08-19T06:30:00.000Z",
        "2018-09-19T06:30:00.000Z",
        "2018-10-19T06:30:00.000Z",
        "2018-11-19T06:30:00.000Z",
        "2018-12-19T06:30:00.000Z",
      ],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  },
};

const Home = () => {
  const { state, setPaperSize, updateBrandList } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState("daily");
  const { currentUser, invoiceSetting } = state;
  const [cardsAnalyticalData,setCardsAnalyticalData]=useState([])
  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [totalcards, setTotalCard] = useState({
    sales: {
      total: 0,
      percentage: 0,
    },
    expense: {
      total: 0,
      percentage: 0,
    },
    orders: {
      total: 0,
      percentage: 0,
    },
    customers: {
      total: 0,
      percentage: 0,
    },
    stock: {
      total: 0,
      percentage: 0,
    },
  });
  const [mostSellingData, setMostSellingData] = useState([]);
  const [purchaseInvoice, setPurchaseInvoice] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const [expenseChartData, setExpenseChartData] = useState([]);
  const [revenueChartData, setRevenueChartData] = useState([]);
  const [allFetchedData, setAllFetchedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const loadingCallback = (loading) => {
    setLoading(loading);
  };

  const [expenseData, setExpenseData] = useState(pdata);
  const [revenueData, setRevenueData] = useState(revenueDataState);
//web dashboard data;
  const getTopSaleProductlist = (activeTab) => {
    loadingCallback(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}web-dashboard?interval=${activeTab}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log("==========");
        console.log(response);
        if (response.data.success) {
          loadingCallback(false);
          const fetchedData = response.data.data;
          setAllFetchedData(fetchedData);
          const cardData = {
            sales: {
              total: fetchedData?.sales,
              percentage: fetchedData?.sales_change
                ? fetchedData?.sales_change
                : "0%",
            },
            expense: {
              total: fetchedData?.expense,
              percentage: fetchedData?.expense_change
                ? fetchedData?.expense_change
                : "0%",
            },
            orders: {
              total: fetchedData?.order,
              percentage: fetchedData?.order_percentage
                ? fetchedData?.order_percentage
                : "0%",
            },
            customers: {
              total: fetchedData?.new_customer,
              percentage: fetchedData?.customer_percentage
                ? fetchedData?.customer_percentage
                : "0%",
            },
            stock: {
              total: fetchedData?.current_stock,
              percentage: '',
            },
          };
          setTotalCard(cardData);
          setMostSellingData(fetchedData?.most_selling);
          setPurchaseInvoice(fetchedData?.purchase_invoice);
          setInvoiceData(fetchedData?.sales_invoice);
          setExpenseChartData(fetchedData?.expense_chart);
          setRevenueChartData(fetchedData?.revenue_chart);
          //setFilterItem(response.data.data.data);
          //setFilteredProducts(response.data.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
          // Navigate to the '/login' route
        }
      });
  };

  useEffect(() => {
    getTopSaleProductlist(activeTab);
    //analytics web data
  }, [activeTab]);

  useEffect(() => {
    if (expenseChartData.length > 0) {
      const transformedExpenseArray = expenseChartData.map((item) => ({
        name: item.name,
        uv: item.amount,
        pv: item.amount,
        amt: item.amount,
      }));
      setExpenseData(transformedExpenseArray);
    }
  }, [expenseChartData]);

  useEffect(() => {
    if (revenueChartData.length > 0) {
      let xaxisCategories = [];
      let xaxisCategoriesAmount = [];

      let xaxisFormat = "dd"; // Default format
      let xaxisType = "category"; // Default type

      // Check the type and set format accordingly
      if (revenueChartData.length > 0) {
        const firstItem = revenueChartData[0];
        if (activeTab === "monthly") {
          // xaxisCategories = revenueChartData.map((item) => item.month_name);
          xaxisCategories = revenueChartData.map((item) => item.name);
          xaxisFormat = "MMM"; // Monthly format
          // xaxisCategoriesAmount = revenueChartData.map((item) => item.revenue);
          xaxisCategoriesAmount = revenueChartData.map((item) => item.amount);
        } else if (activeTab === "yearly") {
          // xaxisCategories = revenueChartData.map((item) => item.year);
          xaxisCategories = revenueChartData.map((item) => item.name);
          xaxisFormat = "yyyy"; // Yearly format
          // xaxisCategoriesAmount = revenueChartData.map((item) => item.revenue);
          xaxisCategoriesAmount = revenueChartData.map((item) => item.amount);
        } else if (activeTab === "weekly") {
          xaxisCategories = revenueChartData.map((item) => item.name);
          xaxisFormat = "dd"; // Default format for daily
          xaxisCategoriesAmount = revenueChartData.map((item) => item.amount);
        } else {
          xaxisCategories = revenueChartData.map((item) => item.name);
          xaxisCategoriesAmount = revenueChartData.map((item) => item.amount);
        }
        // Add more conditions as needed
      }

      const revenueDataState = {
        series: [
          {
            name: "series1",
            data: xaxisCategoriesAmount,
          },
        ],
        options: {
          chart: {
            type: "area",
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            labels: {
              format: xaxisFormat,
            },
            type: xaxisType,
            categories: xaxisCategories,
          },
          tooltip: {
            x: {
              format: "dd/MM/yy HH:mm",
            },
          },
        },
      };

      // Now, revenueDataState has the updated series.data and options.xaxis.categories
      console.log(revenueDataState);
      setRevenueData(revenueDataState);
    }
  }, [revenueChartData]);

  return (
    <>
      <ContentLayout>
        <div className="simple-tabs-container">
          <div className="flex flex-row gap-[1%] font-nunito pt-32 md:pt-0 pb-3 md:!pb-0 fixed md:static bg-[#EBF1FF] md:bg-transparent md:translate-x-0 top-0 left-0 pl-[30px] md:pl-0 w-full z-[9998]">
            <div
              onClick={() => handleTabClick("daily")}
              className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold ${
                activeTab === "daily"
                  ? "border-[#407BFF] text-[#407BFF]"
                  : "text-[#1C2F48] border-transparent"
              }`}
            >
              Daily
            </div>
            <div
              onClick={() => handleTabClick("weekly")}
              className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold ${
                activeTab === "weekly"
                  ? "border-[#407BFF] text-[#407BFF]"
                  : "text-[#1C2F48] border-transparent"
              }`}
            >
              Weekly
            </div>
            <div
              onClick={() => handleTabClick("monthly")}
              className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold ${
                activeTab === "monthly"
                  ? "border-[#407BFF] text-[#407BFF]"
                  : "text-[#1C2F48] border-transparent"
              }`}
            >
              Monthly
            </div>
            <div
              onClick={() => handleTabClick("yearly")}
              className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold ${
                activeTab === "yearly"
                  ? "border-[#407BFF] text-[#407BFF]"
                  : "text-[#1C2F48] border-transparent"
              }`}
            >
              Yearly
            </div>
          </div>
          <div className="tab-content mt-24 md:mt-0">
            {activeTab && (
              <>
                <div className="main-content homePage flex-shrink-0">
                  <TotalCards
                    invoiceSetting={invoiceSetting}
                    type={activeTab}
                    cardData={totalcards}
                    loadingCallback={loadingCallback}
                  />
                  <GraphChart
                    invoiceSetting={invoiceSetting}
                    allFetchedData={allFetchedData}
                    revenueData={revenueData}
                    expenseData={expenseData}
                    type={activeTab}
                    loadingCallback={loadingCallback}

                  />
                  <SellingCard
                    type={activeTab}
                    mostSellingData={mostSellingData}
                    loadingCallback={loadingCallback}
                  />
                </div>

                <div className="flex flex-col xl:flex-row gap-4 mt-10">
                  <div className="w-full xl:w-[50%] mt-0 table-calculator">
                    <RecentOrdersTable
                      type={activeTab}
                      invoiceData={invoiceData}
                      loadingCallback={loadingCallback}
                    />
                    <ChatPopup
                      type={activeTab}
                      loadingCallback={loadingCallback}
                    />
                  </div>
                  <div className="w-full mt-10 xl:w-[50%] xl:mt-0  RecentOrdersTable-container">
                    <PurchaseTable
                      type={activeTab}
                      purchaseInvoice={purchaseInvoice}
                      loadingCallback={loadingCallback}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        {loading && (

          <div className="custom-loader-logo">
            {/* <BeatLoader color={"#123abc"} size={20} /> */}
            <img className="" src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`} alt='logo' />
          </div>
        )}
      </ContentLayout>
    </>
  );
};

export default Home;
