import { Divider } from "antd";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Modal } from "../../utils/Modal";
import axios from "axios";

const DepartmentItem = ({departmentitem, loadingCallback, index}) => {

  const formattedIndex = String(index + 1).padStart(2, '0');

  const navigate = useNavigate();
  const [warningMessage, setWarningMessage] = useState(false);

  const [showDepartmentViewModal, setShowDepartmentViewModal] = useState(false);
  const [departmentData, setDepartmentData] = useState({});
  const [formData, setFormData] = useState({});

  const handleDepartmentViewOpen = (id) => () => {
    setShowDepartmentViewModal(true)
    setDepartmentData(id);
  };
  //-----
  const [showDepartmentEditModal, setShowDepartmentEditModal] = useState(false);

  const handleDepartmentEditOpen = (id) => () => {
    const token = localStorage.getItem("token");
    setShowDepartmentEditModal(true);
    setDepartmentData(id);
    axios.get(`${process.env.REACT_APP_API_URL}department/${id}`, {
        headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
    })
    .then((response) => {
      console.log("id wise Department data:", response);
        if (response.data.success) {
            const departmentData = response.data.data;
            setFormData({
              name: departmentData.name,
              description: departmentData.description,
            });
            console.log("editable data: ", departmentData)
        }
    })
    .catch((error) => {
      loadingCallback(false);
      console.log(error);
      localStorage.clear();
      localStorage.setItem("token", false);
      // Clear session and local storage
      localStorage.setItem("isPinVerify", false);

      navigate("/login");
    });
};

  return(
    <>
      <div>
        <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[8px] grid grid-cols-3 gap-2 items-center font-nunito font-[500] text-[14px] text-[#000232]">
        {/* <div className="border-t-[1px] border-solid border-[#E7EBF4] py-[8px] font-nunito font-[500] text-[#000232] grid grid-cols-7 items-start"> */}
          <div>
            <div className="flex flex-col sm:flex-row gap-2">
              <div className="font-nunito text-[14px]">
                <div className="font-[500] text-[#000232]">{formattedIndex}</div>
                {/* <div className="font-[700] text-[#87889E]">{expense?.category_name}</div> */}
              </div>
            </div>
          </div>
          <div className="text-center">{departmentitem.name}</div>
          <div className="flex gap-2  text-xs justify-end">
            <label
              htmlFor="view_department_modal"
              onClick={handleDepartmentViewOpen(departmentitem?.id)}
              // onClick={()=> Swal.fire("View option coming soon")}
              className="cursor-pointer border-[1px] border-solid border-[#407BFF] text-[#407BFF] rounded-[5px] px-[6px] py-1"
            >
            VIEW
            </label>
            <label
              htmlFor="edit_department_modal"
              onClick={handleDepartmentEditOpen(departmentitem?.id)}
              className="cursor-pointer border-[1px] border-solid bg-[#407BFF] border-[#407BFF] text-white rounded-[5px] px-[7px] py-1"
            >
            EDIT
            </label>
          </div>
        </div>
      </div>
      {showDepartmentViewModal ? (
        <Modal
          isOpen={showDepartmentViewModal}
          setOpen={setShowDepartmentViewModal}
          setWarningMessage={setWarningMessage}
          title="Details"
          formId="view_department_modal"
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="view_department_modal"
          departmentId={departmentData}
          formData={formData}
        />
      ) : null}
      {showDepartmentEditModal ? (
        <Modal
          isOpen={showDepartmentEditModal}
          setOpen={setShowDepartmentEditModal}
          setWarningMessage={setWarningMessage}
          title="Edit Department"
          formId="edit_department_modal"
          loadingCallback={loadingCallback}
          navigate={navigate}
          type="edit_department_modal"
          departmentData={departmentData}
          formData={formData}
          setFormData={setFormData}
          departmentId={departmentData}
          refetch={()=>setRefetch(val=>!val)}
        />
      ) : null}
    </>
  );
}

export default DepartmentItem;